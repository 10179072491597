<template>
  <f7-page class="list-filter-page">
    
    <div v-if="listIsFiltered">
      <f7-block-title style="margin-left:0.25em;">Current Filter Selections</f7-block-title>

      <div style="background: #fff;padding-left:0.25em;">
        <div v-if="appliedRegionFilters.length">
          <div style="border-bottom:1px solid black;height: 30px;line-height:30px;">Regions</div>
          <f7-list style="margin-left:1vw;">
            <f7-list-item swipeout v-for="(filter, index) in appliedRegionFilters" :key="index" :title="filter">
              <div slot="after-title" style="width: 6vw" @click="removeRegionFilter(filter)"><span class="fa fa-times" style="color: #912536"/></div>
              <f7-swipeout-actions left>
                <f7-swipeout-button overswipe class="swipeDelete" @click="removeRegionFilter(filter)">Delete</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </div>

        <div v-if="appliedSectorFilters.length">
          <div style="border-bottom:1px solid black;height: 30px;line-height:30px;">Sectors</div>
          <f7-list style="margin-left:1vw;">
            <f7-list-item swipeout v-for="(filter, index) in appliedSectorFilters" :key="index" :title="filter">
              <div slot="after-title" style="width: 6vw" @click="removeSectorFilter(filter)"><span class="fa fa-times" style="color: #912536"/></div>
              <f7-swipeout-actions left>
                <f7-swipeout-button overswipe class="swipeDelete" @click="removeSectorFilter(filter)">Delete</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </div>

        <div v-if="appliedIndustryFilters.length && industriesWithoutSelectedParentFilters.length">
          <div style="border-bottom:1px solid black;height: 30px;line-height:30px;">Industries</div>
          <f7-list style="margin-left:1vw;">
            <f7-list-item swipeout v-for="({ industry, sector }, index) in appliedIndustryFilters" :key="index" :title="industry" v-if="!appliedSectorFilters.includes(sector)">
              <div slot="after-title" style="width: 6vw" @click="removeIndustryFilter(industry, sector)"><span class="fa fa-times" style="color: #912536"/></div>
              <f7-swipeout-actions left>
                <f7-swipeout-button overswipe class="swipeDelete" @click="removeIndustryFilter(industry, sector)">Delete</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </div>

    <div>
      <f7-block-title style="margin-left:0.25em;">Filter Options</f7-block-title>
      <f7-list accordion-list>

        <f7-list-item accordion-item title="Agenda Filters" v-if="listIsAgenda">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item style="margin-left: 30px;" title="Buy" checkbox value="BUY" @change="applyAgendaFilter"/>
              <f7-list-item style="margin-left: 30px;" title="Sell" checkbox value="SELL" @change="applyAgendaFilter"/>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item accordion-item title="Region Filters" v-if="possibleRegionFilters.length > 1">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item style="margin-left: 30px;" v-for="(region, index) in possibleRegionFilters" :title="region" :key="index" checkbox :value="region" :checked="appliedRegionFilters.includes(region)" @change="applyRegionFilters"/>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item accordion-item title="Sector/Industry Filters">
          <f7-accordion-content>
            <f7-list accordion-list v-for="(industries, sector) in possibleIndustryFilters" :key="sector">
              <div style="display: flex;justify-content: flex-start;align-items: flex-start;margin-left:30px;">
                <f7-checkbox style="margin-top: 10px;" :value="sector" :checked="appliedSectorFilters.includes(sector) || allIndustriesSelected(industries, sector)" @change="applySectorFilters($event);applyAllIndustries(industries, $event)"/>
                <f7-list-item accordion-item :title="sector" style="width: 90%;">
                  <f7-accordion-content>
                    <f7-list>
                      <f7-list-item v-for="({ industry }, index) in industries" :title="industry" :key="index" checkbox :value="[sector, industry]" :divider="false" :checked="industryIsSelected(industry) || appliedSectorFilters.includes(sector)" @change="applyIndustryFilters"/>
                    </f7-list>
                  </f7-accordion-content>
                </f7-list-item>
              </div>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ListFilterMenu',
  computed: {
    ...mapState('lists', [
      'appliedAgendaFilters',
      'appliedIndustryFilters',
      'appliedRegionFilters',
      'appliedSectorFilters',
      'groupBy'
    ]),
    ...mapGetters('lists', [
      'possibleSectorFilters',
      'possibleIndustryFilters',
      'possibleRegionFilters',
      'listIsFiltered'
    ]),
    industriesWithoutSelectedParentFilters() {
      return this.appliedIndustryFilters
        .slice(0)
        .filter(({ sector }) => !this.appliedSectorFilters.includes(sector))
    },
    listIsAgenda() {
      return this.$f7route.url.indexOf('lists/agenda') > -1
    }
  },
  methods: {
    ...mapMutations('lists', [
      'setAgendaFilters',
      'setIndustryFilters',
      'setRegionFilters',
      'setSectorFilters',
      'setGroupBy'
    ]),
    industryIsSelected(industryOption) {
      return (
        this.appliedIndustryFilters.find(({ industry }) => industry === industryOption) !==
        undefined
      )
    },
    allIndustriesSelected(industries, sector) {
      const selectedIndustriesInSector = industries.filter(({ industry }) =>
        this.appliedIndustryFilters.find((filter) => filter.industry === industry)
      )
      const allSelected = industries.length === selectedIndustriesInSector.length
      if (allSelected) {
        const allSectorFilters = this.appliedSectorFilters.slice(0)
        allSectorFilters.push(sector)
        this.setSectorFilters(allSectorFilters)
      }
      return allSelected
    },
    applyIndustryFilters(event) {
      const allIndustryFilters = this.appliedIndustryFilters.slice(0)
      const [sector, industry] = event.target._value
      if (!event.target.checked) {
        this.removeIndustryFilter(industry, sector)
        return
      }
      allIndustryFilters.push({ sector, industry })
      this.setIndustryFilters(allIndustryFilters)
    },
    applyAllIndustries(industries, event) {
      const isChecked = event.target.checked
      if (isChecked) {
        const allIndustryFilters = this.appliedIndustryFilters.slice(0).concat(industries)
        this.setIndustryFilters(allIndustryFilters)
      }
    },
    applyRegionFilters(event) {
      const allRegionFilters = this.appliedRegionFilters.slice(0)
      const selectedValue = event.target._value
      if (!event.target.checked) {
        this.removeRegionFilter(selectedValue)
        return
      }
      allRegionFilters.push(event.target._value)
      this.setRegionFilters(allRegionFilters)
    },
    applySectorFilters(event) {
      const allSectorFilters = this.appliedSectorFilters.slice(0)
      const selectedValue = event.target._value
      if (!event.target.checked) {
        this.removeSectorFilter(selectedValue)
        return
      }
      allSectorFilters.push(selectedValue)
      this.setSectorFilters(allSectorFilters)
    },
    applyAgendaFilter(event) {
      const allAgendaFilters = this.appliedAgendaFilters.slice(0)
      const selectedValue = event.target._value
      if (!event.target.checked) {
        this.removeAgendaFilter(selectedValue)
        return
      }
      allAgendaFilters.push(selectedValue)
      this.setAgendaFilters(allAgendaFilters)
    },
    removeAgendaFilter(agendaType) {
      const filtersToApply = this.appliedAgendaFilters
        .slice(0)
        .filter((appliedFilter) => appliedFilter !== agendaType)
      this.setAgendaFilters(filtersToApply)
    },
    removeIndustryFilter(filter, sector) {
      const filtersToApply = this.appliedIndustryFilters
        .slice(0)
        .filter((appliedFilter) => appliedFilter.industry !== filter)
      this.setIndustryFilters(filtersToApply)
      const otherIndustriesSelectedInSector = this.appliedIndustryFilters.filter(
        (appliedFilter) => appliedFilter.sector === sector
      )
      if (otherIndustriesSelectedInSector && this.appliedSectorFilters.includes(sector)) {
        const allSectorFilters = this.appliedSectorFilters
          .slice(0)
          .filter((appliedFilter) => appliedFilter !== sector)
        this.setSectorFilters(allSectorFilters)
      }
    },
    removeIndustryFiltersBySector(sector) {
      const filtersToApply = this.appliedIndustryFilters
        .slice(0)
        .filter((appliedFilter) => appliedFilter.sector !== sector)
      this.setIndustryFilters(filtersToApply)
    },
    removeSectorFilter(sector) {
      const filtersToApply = this.appliedSectorFilters
        .slice(0)
        .filter((appliedFilter) => appliedFilter !== sector)
      this.setSectorFilters(filtersToApply)
      this.removeIndustryFiltersBySector(sector)
    },
    removeRegionFilter(filter) {
      const filtersToApply = this.appliedRegionFilters
        .slice(0)
        .filter((appliedFilter) => appliedFilter !== filter)
      this.setRegionFilters(filtersToApply)
    }
  }
}
</script>


<style>
.list-filter-page .icon-checkbox {
  margin-left: 5px;
}

.list-filter-page .swipeout-actions-left > a {
  background: #fff;
}

.list-filter-page .list .item-title {
  font-size: 0.8em;
}

.list-filter-page .ios li li:last-child .item-inner:after,
.ios li:last-child li .item-inner:after {
  background-color: white;
}
</style>
