/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

import {
  LogLevel,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser'

const url = location.host.toLowerCase()
const apiType = 'qa'
localStorage.setItem('localhostAPIType', apiType)

const TENANT = "7ebf3a40-0f86-495e-98d7-20b5bad7d2ac"

function getClientId() {
  // TODO: IMPLEMENT METHOD TO SET DEV/QA WHEN WORKING LOCALLY
  if (
    url.indexOf('dev') > -1 ||
    (url.indexOf('localhost') > -1 && apiType === 'dev')
  ) {
      // ClientId for WB SUMMIT  Mobile Web - IaaS - Dev
      return '912e5c27-1a44-496e-8c2e-e8a8981f8a74';
  }
  if (
      url.indexOf('qa') > -1 ||
      (url.indexOf('localhost') > -1 && apiType === 'qa')
  ) {
      // ClientId for WB SUMMIT Mobile Web - IaaS - QA
      return 'b79f8095-c16c-4c06-ad51-b23bc3f1d954';
  }
  // ClientId for WB SUMMIT  Mobile Web - IaaS - PROD
  return '33175918-7427-4683-9fb6-64aa8c679b9c';
}

export const msalConfig = {
  auth: {
    clientId: getClientId(),
    authority: `https://login.microsoftonline.com/${TENANT}`
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    //cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // containsPii is a flag that indicates the message contains personal information
      loggerCallback: (level, message, containsPii) => {
        switch (level) {
          case LogLevel.Error:
            //console.error(message);
            return
          case LogLevel.Info:
            //console.info(message);
            return
          case LogLevel.Verbose:
            //console.debug(message);
            return
          case LogLevel.Warning:
            //console.warn(message);
            return
        }
      }
    }
  }
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
  scopes: ['User.Read', 'Mail.Read'],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

export const summitRequest = {
  scopes: ["User.Read"]
};
