<template>
  <div>
    <div class="ratings">
      <div class="event-symbol">
        <span class="fa fa-lg fa-envira" />
      </div>
      <div>
        <div>
          <span v-if="ratingCode === 'OUTPERFORM'" class="event-indicator">O</span>
          <span v-if="entity.onLeadersList" class="fa fa-trophy trophy" />
          <span @click.stop="onClickCompany(entity)" class="lnk">{{ entity.name }}</span>
        </div>
        <div>
          <span class="title">
            {{ model.title }}
          </span>
          <hr v-if="model.esgTopics || model.esgSources" />
          <div
            style="display:flex;gap:22px;font-size:13px;"
            v-if="model.esgTopics || model.esgSources"
          >
            <div style="display:flex;flex-direction:column;" v-if="model.esgTopics">
              <span style="font-weight:bold;">ESG Topics:</span>
              <ul style="padding:0 0 0 13px;">
                <li v-for="topic in model.esgTopics">
                  {{ topic.name }}
                </li>
              </ul>
            </div>
            <div style="display:flex;flex-direction:column;" v-if="model.esgSources">
              <span style="font-weight:bold;">ESG Sources:</span>
              <ul style="padding: 0 0 0 13px;">
                <li v-for="source in model.esgSources">
                  {{ source.name }}
                </li>
              </ul>
            </div>
          </div>
          <hr v-if="model.esgTopics || model.esgSources" />
          <table>
            <thead>
              <th style="width:50%" />
              <th>CURRENT</th>
              <th v-if="showPreviousScore">PREVIOUS</th>
            </thead>
            <tbody>
              <tr v-if="model.isEnvUpdated">
                <td style="width:50%">ENVIRONMENTAL</td>
                <td v-if="model.isEnvUpdated">
                  {{ model.toEnvironmentalScore || '-' }}
                </td>
                <td v-if="showPreviousScore">{{ model.fromEnvironmentalScore || '-' }}</td>
              </tr>
              <tr v-if="model.isSocUpdated">
                <td style="width:50%">SOCIAL</td>
                <td v-if="model.isSocUpdated">
                  {{ model.toSocialScore || '-' }}
                </td>
                <td v-if="showPreviousScore">{{ model.fromSocialScore || '-' }}</td>
              </tr>
              <tr v-if="model.isGovUpdated">
                <td style="width:50%">GOVERNANCE</td>
                <td v-if="model.isGovUpdated">
                  {{ model.toGovernanceScore || '-' }}
                </td>
                <td v-if="showPreviousScore">{{ model.fromGovernanceScore || '-' }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="showEnvironmentalComment">
            <span class="text">
              <strong>Environmental Comment:</strong>
            </span>
            <span class="text" v-html="model.environmentalComment" />
          </div>
          <br v-if="showEnvironmentalComment" />
          <div v-if="showSocialComment">
            <span class="text">
              <strong>Social Comment:</strong>
            </span>
            <span class="text" v-html="model.socialComment" />
          </div>
          <br v-if="showEnvironmentalComment" />
          <div v-if="showGovernanceComment">
            <span class="text">
              <strong>Governance Comment:</strong>
            </span>
            <span class="text" v-html="model.governanceComment" />
          </div>
          <div v-if="model.body">
            <span class="text" v-html="model.body" />
          </div>
        </div>
      </div>
    </div>
    <component :info="popupInfo" :opened="popupOpened" :is="'event-popup'">
      <div class="rating-change-popup">
        <div>
          <span class="header">Title:</span>
          <span>
            <b>{{ model.title }}</b>
          </span>
        </div>
        <div>
          <div class="header">Tags:</div>
          <div class="indent" v-for="(evt, index) in model.entities" :key="index">
            <span v-if="evt.entityType === 'THEMATIC'" class="fa fa-stack-exchange" />
            <span v-if="evt.entityType === 'RMS_USER'" class="fa fa-user" />
            <span
              v-if="evt.entityType === 'REGION' || evt.entityType === 'COUNTRY'"
              class="fa fa-globe"
            />
            <span
              v-if="
                evt.entityType !== 'RMS_USER' &&
                  evt.entityType !== 'REGION' &&
                  evt.entityType !== 'COUNTRY' &&
                  evt.entityType !== 'THEMATIC'
              "
              class="fa fa-bar-chart-o"
            />
            {{ evt.name }}
          </div>
        </div>
        <hr v-if="model.esgTopics || model.esgSources" />
        <div
          style="display:flex;gap:22px;font-size:13px;"
          v-if="model.esgTopics || model.esgSources"
        >
          <div style="display:flex;flex-direction:column;" v-if="model.esgTopics">
            <span style="font-weight:bold;">ESG Topics:</span>
            <ul style="padding:0 0 0 13px;">
              <li v-for="topic in model.esgTopics">
                {{ topic.name }}
              </li>
            </ul>
          </div>
          <div style="display:flex;flex-direction:column;" v-if="model.esgSources">
            <span style="font-weight:bold;">ESG Sources:</span>
            <ul style="padding: 0 0 0 13px;">
              <li v-for="source in model.esgSources">
                {{ source.name }}
              </li>
            </ul>
          </div>
        </div>
        <hr v-if="model.esgTopics || model.esgSources" />
        <table>
          <thead>
            <th style="width:50%;text-align: left;" />
            <th style="text-align: left;">CURRENT</th>
            <th v-if="showPreviousScore" style="text-align: left;">PREVIOUS</th>
          </thead>
          <tbody>
            <tr v-if="model.isEnvUpdated">
              <td style="width:50%">ENVIRONMENTAL</td>
              <td v-if="model.isEnvUpdated">
                {{ model.toEnvironmentalScore || '-' }}
              </td>
              <td v-if="showPreviousScore">{{ model.fromEnvironmentalScore || '-' }}</td>
            </tr>
            <tr v-if="model.isSocUpdated">
              <td style="width:50%">SOCIAL</td>
              <td v-if="model.isSocUpdated">
                {{ model.toSocialScore || '-' }}
              </td>
              <td v-if="showPreviousScore">{{ model.fromSocialScore || '-' }}</td>
            </tr>
            <tr v-if="model.isGovUpdated">
              <td style="width:50%">GOVERNANCE</td>
              <td v-if="model.isGovUpdated">
                {{ model.toGovernanceScore || '-' }}
              </td>
              <td v-if="showPreviousScore">{{ model.fromGovernanceScore || '-' }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="showEnvironmentalComment" style="margin-top: 10px;">
          <span class="text" style="font-weight:bold;">
            Environmental Comment:
          </span>
          <span class="text" v-html="model.environmentalComment" />
        </div>
        <br v-if="showEnvironmentalComment" />
        <div v-if="showSocialComment">
          <span class="text" style="font-weight:bold;">
            Social Comment:
          </span>
          <span class="text" v-html="model.socialComment" />
        </div>
        <br v-if="showSocialComment" />
        <div v-if="showGovernanceComment">
          <span class="text" style="font-weight:bold;">
            Governance Comment:
          </span>
          <span class="text" v-html="model.governanceComment" />
        </div>
        <div v-if="model.body">
          <span class="text" v-html="model.body" />
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
  name: 'ESGRating',
  mixins: [eventPopupMixin],
  components: {
    EventPopup
  },
  data() {
    return {}
  },
  computed: {
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.formatFooter(this.model),
        date: this.model.eventDate
      }
    },
    entity() {
      if (!this.model || !this.model.entities || !this.model.entities[0]) {
        return {}
      }
      return this.model.entities[0]
    },
    ratingCode() {
      return this.entity.rating && this.entity.rating.code ? this.entity.rating.code : ''
    },
    showEnvironmentalComment() {
      if (this.model.hasOwnProperty('isEnvCommentUpdated')) {
        return this.model.isEnvCommentUpdated && this.model.environmentalComment
      }
      return this.model.isEnvUpdated && this.model.environmentalComment
    },
    showSocialComment() {
      if (this.model.hasOwnProperty('isSocCommentUpdated')) {
        return this.model.isSocCommentUpdated && this.model.socialComment
      }
      return this.model.isSocUpdated && this.model.socialComment
    },
    showGovernanceComment() {
      if (this.model.hasOwnProperty('isGovCommentUpdated')) {
        return this.model.isGovCommentUpdated && this.model.governanceComment
      }
      return this.model.isGovUpdated && this.model.governanceComment
    },
    showPreviousScore() {
      return (
        this.model &&
        this.model.subEventType &&
        this.model.subEventType.code === 'ESG_RATING_CHANGE'
      )
    }
  },
  methods: {
    formatFooter() {
      if (this.model.onBehalfOf) {
        return `${this.model.onBehalfOf.fullName} via ${this.model.createdBy.fullName}`
      }
      return `${this.model.createdBy.fullName}`
    },
    showCurrentScore(pillarChanged, pillarValue) {
      if (
        this.model &&
        this.model.subEventType &&
        this.model.subEventType.code === 'ESG_RATING_UPDATE'
      ) {
        return true
      }
      return this.model[pillarChanged] && this.model[pillarValue]
    }
  }
}
</script>

<style lang="scss" scoped>
.ratings {
  display: inline-flex;
}
.upward-change {
  color: #068064;
}
.downward-change {
  color: #912536;
}
.neutral-change {
  color: #d9c923;
}
.rating-change-popup > div {
  margin-bottom: 15px;
}
.rating-change-popup {
  .emphasis {
    font-weight: bold;
  }
  .header {
    font-weight: bold;
  }
}
</style>
