<template>
  <f7-app id="app" :params="f7Params" >
    <f7-statusbar/>
    <f7-view url="/events/0" main class="ios-edges"/>

    <f7-panel right cover>
      <f7-view url="/listFilter" name="filter-panel"/>
    </f7-panel>
  </f7-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import f7Routes from './router'
import ListFilterMenu from './components/lists/ListFilterMenu.vue'

export default {
  components: {
    ListFilterMenu
  },
  data() {
    return {
      f7Params: {
        name: 'Summit',
        id: 'summit.blairnet.com',
        theme: 'ios',
        routes: f7Routes,
        view: {
          //pushState: true
        }
      }
    }
  },
  methods: {
    ...mapActions('user', ['setCurrentUser']),
    ...mapActions('lists', ['getAllLists', 'getEligibilityList'])
  },
  computed: {
    ...mapState('user', ['currentUser']),
    ...mapGetters('lists', ['defaultList'])
  },
  beforeMount() {
    this.setCurrentUser().then(() => {
      this.getAllLists()
    })
  },
  mounted() {
    // Get data for the eligibility list on app mount so we can just load the data from state
    // instead of fetching the data on navigation
    this.getEligibilityList()
  }
}
</script>

<style>
#app {
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
