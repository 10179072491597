import Vue from 'vue'
import Framework7 from 'framework7'
import Framework7Vue from 'framework7-vue'
import f7CoreComponents from './f7CoreComponents'
import f7VueComponents from './f7VueComponents'
import 'framework7-icons/css/framework7-icons.css'
import App from './App.vue'
import store from './store/index'
import util from './util'
import { myMSALObj } from './assets/js/auth/authRedirect'

Vue.config.productionTip = false

Framework7.use(f7CoreComponents)
Framework7.use(Framework7Vue)

f7VueComponents.forEach((component) => {
  Vue.component(component.name, component)
})

Vue.prototype.$ShowErrorBanner = function(message, params) {
  const options = {
    position: 'center',
    closeTimeout: 3000,
    text: message,
    destroyOnClose: true,
    icon: '<i class="f7-icons">info_fill</i>',
    ...params
  }
  this.$f7.toast.show(options)
}

Vue.prototype.$toTitleCase = (text) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`
}
util.getEnvironment()
Vue.filter('number', function(value, precision) {
  if (!value) return ''
  if (Number.isNaN(value)) return value
  return value
    .toFixed(precision)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('toDateTime', function(date) {
  let [d, t] = new Date(date).toLocaleString('en-US').split(', ')
  return `${d} ${t}`
})

Vue.filter('toDate', function(date) {
  return new Date(date).toLocaleString('en-US').split(',')[0]
})

Vue.filter('lowerCase', function(text) {
  return text.toLowerCase()
})


async function initApp() {  
  await myMSALObj.initialize()
  if (myMSALObj.getActiveAccount()) {
    new Vue({ store, render: (h) => h(App) }).$mount('#app')  
  } else {
    setTimeout(() => {
      initApp()
    }, 1000)
  }
}
initApp()