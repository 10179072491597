// Store index and modules based on this example: https://github.com/vuejs/vuex/blob/dev/examples/shopping-cart/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import trade from './modules/trade'
import search from './modules/search'
import companyPage from './modules/companyPage'
import lists from './modules/lists'
import events from './modules/events'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    search,
    companyPage,
    trade,
    lists,
    events,
    user
  },
  state: {
    navTitle: 'Summit'
  },
  mutations: {
    setTitle: (state, title) => (state.navTitle = title)
  },
  actions: {},
  getters: {}
})
