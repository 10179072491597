<template>
  <div class="rec-report-section">
    <span class="rec-report-section-title">{{ title }}</span>
    <p class="rec-report-section-sub-title">
      {{ lastModified }}
    </p>
    <br>
    <table class="rec-report-view-dbf-scorecard">
      <thead class="rec-report-view-dbf-table-header">
        <th>
          <span>Key Components</span>
        </th>
        <th>Weightings</th>
        <th>
          <span>Score</span>
        </th>
        <th>Cmts</th>
      </thead>
      <tbody v-for="group in section.dbfScorecardGroups" :key="group.name">
        <tr class="group-row">
          <td colspan="4">{{ group.name }} - {{ group.weight | number(1) }}%</td>
        </tr>
        <tr v-for="groupSection in group.sections" :key="groupSection.name">
          <td colspan="4">
            <table class="section">
              <tbody>
                <tr>
                  <td>{{ mapSectionName(groupSection.name) }}</td>
                  <td class="key-component" style="text-align: center;">{{ groupSection.weight | number(1) }}%</td>
                  <td>{{ groupSection.componentWeightOrScore }}</td>
                  <td class="comment text-truncate">
                    <span v-if="groupSection.comment && groupSection.comment.trim().length>1" @click="showComment(mapSectionName(groupSection.name), groupSection.comment)" class="fa fa-comment-o"/>
                  </td>
                </tr>
                <tr class="subsection" v-for="subSection in groupSection.subSections" :key="subSection.name">
                  <td style="padding-left:10px;">{{ mapSectionName(subSection.name) }}</td>
                  <!--  -->
                  <td style="text-align:center;">{{ subSection.componentWeightOrScore | number(1) }}</td>
                  <td>&nbsp;</td>
                  <td class="comment text-truncate">
                    <span v-if="subSection.comment && subSection.comment.trim().length>1" @click="showComment(mapSectionName(subSection.name), subSection.comment)" class="fa fa-comment-o"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr class="dbf-footer">
          <td>Total DBF Score</td>
          <td>{{ this.totalWeighting }}%</td>
          <td>{{ section.totalDbfScore | number(2) }}</td>
          <td>&nbsp;</td>
        </tr>
        <tr class="dbf-footer">
          <td>Industry Profit Pool Growth</td>
          <td>&nbsp;</td>
          <td>{{ section.industryProfitPoolGrowth | number(0) }}</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <table class="dbf-scorecard-footnotes">
      <tbody>
        <tr v-for="(footNote, index) in footnotes" :key="index">
          <td colspan="4">{{ footNote }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    viewType: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    lastModified: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalWeighting: 0,
      footnotes: []
    }
  },
  mounted() {
    if (this.section && this.section.dbfScorecardGroups) {
      this.section.dbfScorecardGroups.forEach((group) => {
        group.sections.forEach((section) => {
          if (section.footnote) {
            this.footnotes.push(section.footnote)
          }
        })
      })

      this.section.dbfScorecardGroups.forEach((group) => {
        group.sections.forEach((section) => {
          this.totalWeighting += section.weight
        })
      })
    }
  },
  methods: {
    showComment(component, comment) {
      let c = encodeURIComponent(comment)
      this.$f7router.navigate(`/dbfscorecard/comment/${component}/${c}`)
    },
    mapSectionName(name) {
      if (name === 'Understanding/Vision for Future Value Creation') {
        return 'Vision for Future Value Creation'
      } else if (
        name ===
        'Durability of Competitive Position (Barriers to Entry, Enduring Leadership in Industry, Competitive Forces)'
      ) {
        return 'Durability of Competitive Position'
      } else if (
        name === 'Return on Invested Capital Profile (Level, Ability to Reinvest, Direction)'
      ) {
        return 'Return on Invested Capital Profile'
      } else if (name === 'Execution Ability/Record of Success') {
        return 'Execution Record of Success'
      } else {
        return name
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.text-truncate {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rec-report-section {
  padding: 0px 15px;
  margin-left: 0px;
}

.rec-report-view-dbf-scorecard,
.section {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0px;
  color: #222;
  thead {
    th {
      background: #f5f5f5;
      padding-top: 0px;
      border-bottom: 1px solid silver;
      font-size: 0.75rem;
    }
    th:nth-child(1) {
      width: 65%;
      text-align: left;
      padding-left: 8px;
    }
    th:nth-child(2) {
      width: 20%;
      text-align: center;
    }
    th:nth-child(3) {
      width: 15%;
      text-align: center;
    }
    th:nth-child(4) {
      width: 40px;
      padding-left: 8px;
    }
  }

  tbody {
    tr.group-row {
      height: 30px;
      background: #f7f7f7;
      border-top: 1px solid #555555;
      border-bottom: 1px solid black;
      border-left: 1px solid black;
      td {
        padding-left: 5px;
        font-weight: bold;
        font-size: 0.9rem;
        color: #222;
      }
    }
  }
}

table.section {
  margin-top: 0px;
  table-layout: fixed;

  tbody {
    tr {
      height: 35px;
      background: white;

      td {
        padding: 3px;
        color: #222;
      }
      td:nth-child(1) {
        width: 65%;
        padding-left: 8px;
      }
      td:nth-child(2) {
        width: 20%;
        text-align: center;
      }
      td:nth-child(3) {
        width: 15%;
        text-align: center;
      }
      td:nth-child(4) {
        width: 40px;
        padding-left: 8px;
        text-align: center;
      }
    }

    tr.subsection {
      background: white;
      td:nth-child(1) {
        padding-left: 20px !important;
      }
    }
  }
}

.dbf-footer {
  height: 40px;
  background: #f5f5f5;
  td:nth-child(1) {
    padding-left: 8px;
  }
  td:nth-child(2) {
    text-align: center;
  }
  td:nth-child(3) {
    text-align: center;
  }
}
</style>
