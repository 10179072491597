<template>
  <div>
    <div class="research-init">
      <div class="event-symbol">
        <span class="fa fa-lg fa-object-ungroup" />
      </div>
      <div>
        <div>
          <span @click.stop="onClickCompany(model.issuer)" class="lnk">{{
            model.entities[0].name
          }}</span>
        </div>
        <div>{{ modelType }} updated</div>
      </div>
    </div>
    <component :info="popupInfo" :opened="popupOpened" :is="'event-popup'">
      <div class="rating-change-popup">
        <span v-if="model.modelCode && model.modelCode === 'BUSINESS_MODEL'">
          <span><strong>What do they do?</strong></span>
          <span>{{ businessModelSelections }} </span>
          <br />

          <span><strong>How do they operate?</strong></span>
          <span>{{ operatingModelSelections }} </span>
          <br />

          <span><strong>What resources do they use?</strong></span>
          <span>{{ businessModelResourceSelections }} </span>
          <br />

          <span><strong>How are they positioned?</strong></span>
          <span>{{ businessModelPositionSelections }} </span>
          <br />

          <span><strong>Comment:</strong></span>
          <span
            style="white-space: pre-line;"
            v-html="model.issuerCategorizationItemsList[0].notes"
          >
          </span>
        </span>
        <span v-if="model.modelCode && model.modelCode === 'COMPETITIVE_ADVANTAGE_MODEL'">
          <span><strong>Competitive Advantages:&nbsp;</strong></span>
          <span>{{ competitiveAdvantageSelections }} </span>
          <br />
          <span style="white-space: pre-line;" v-html="competitiveAdvantagesComment"> </span>
          <br />
          <br />

          <span><strong>Drivers</strong></span>
          <br />
          <span
            v-for="(driver, index) in competitiveAdvantageDriverSelections"
            :key="index"
            style="display: block;margin-left: 10px;"
          >
            <span
              ><strong>{{ driver.childName }}:</strong><br /></span
            ><span style="white-space: pre-line;">{{ driver.notes }}</span
            ><br />
            <br />
          </span>

          <span><strong>Strength and Health:&nbsp;</strong></span
          ><span>{{ competitiveAdvantageStrengthAndHealthSelections }} </span>
          <br />
          <span><strong>Competitive Advantage Period:&nbsp;</strong></span
          ><span>{{ competitiveAdvantageCAPSelection }} </span>
          <br />
          <span style="white-space: pre-line;" v-html="strengthHealthCAPComment"></span>
        </span>
      </div>
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'
import get from 'lodash/get'

export default {
  name: 'CompanyClassification',
  mixins: [eventPopupMixin],
  components: {
    EventPopup
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['viewType']),
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.model.createdBy.fullName,
        onBehalfOf:
          this.model && this.model.onBehalfOf && this.model.onBehalfOf.fullName
            ? this.model.onBehalfOf.fullName
            : null,
        date: this.model.eventDate
      }
    },
    modelType() {
      const type = this.model.modelCode

      if (!type) {
        return ''
      }

      return type === 'BUSINESS_MODEL'
        ? 'Business Model Framework'
        : 'Competitive Advantage Framework'
    },
    businessModelSelections() {
      return this.model.issuerCategorizationItemsList
        .filter(({ parentCode = '' }) => parentCode === 'WHAT_DO_THEY_DO')
        .map(({ childName }) => childName)
        .join(', ')
    },
    operatingModelSelections() {
      return this.model.issuerCategorizationItemsList
        .filter(({ parentCode = '' }) => parentCode === 'HOW_DO_THEY_OPERATE')
        .map(({ childName }) => childName)
        .join(', ')
    },
    businessModelResourceSelections() {
      return this.model.issuerCategorizationItemsList
        .filter(({ parentCode = '' }) => parentCode === 'WHAT_RESOURCES')
        .map(({ childName }) => childName)
        .join(', ')
    },
    businessModelPositionSelections() {
      return this.model.issuerCategorizationItemsList
        .filter(({ parentCode = '' }) => parentCode === 'HOW_POSITIONED')
        .map(({ childName }) => childName)
        .join(', ')
    },
    competitiveAdvantageSelections() {
      return this.model.issuerCategorizationItemsList
        .filter(({ parentCode = '' }) => parentCode === 'COMPETITIVE_ADVANTAGE')
        .map(({ childName }) => childName)
        .join(', ')
    },
    competitiveAdvantageDriverSelections() {
      return this.model.issuerCategorizationItemsList.filter(
        ({ parentCode = '' }) => parentCode === 'DRIVERS'
      )
    },
    competitiveAdvantageStrengthAndHealthSelections() {
      const strength = this.model.issuerCategorizationItemsList.find(
        ({ parentCode = '' }) => parentCode === 'STRENGTH'
      ).childName

      const health = this.model.issuerCategorizationItemsList.find(
        ({ parentCode = '' }) => parentCode === 'HEALTH'
      ).childName

      return `${strength}, ${health}`
    },
    competitiveAdvantageCAPSelection() {
      return this.model.issuerCategorizationItemsList.find(
        ({ parentCode = '' }) => parentCode === 'ADVANTAGE_PERIOD'
      ).childName
    },
    competitiveAdvantagesComment() {
      const body = this.model.issuerCategorizationItemsList.find(
        ({ parentCode = '', childCode = '' }) =>
          parentCode === 'COMPETITIVE_ADVANTAGE_MODEL' && childCode === 'COMPETITIVE_ADVANTAGE'
      )

      return get(body, 'notes', '')
    },
    strengthHealthCAPComment() {
      const body = this.model.issuerCategorizationItemsList.find(
        ({ parentCode = '', childCode = '' }) =>
          parentCode === 'COMPETITIVE_ADVANTAGE_MODEL' && childCode === 'STRENGTH_HEALTH_CAP'
      )

      return get(body, 'notes', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.research-init {
  display: inline-flex;
}
.research-init-popup > div {
  margin-bottom: 15px;
}
.research-init-popup {
  .emphasis {
    font-weight: bold;
  }
  ul {
    padding-left: 30px;
    margin-top: 5px;
  }
  .header {
    font-weight: bold;
  }
}
</style>
