import $http from './apiClient'

export default {
  async getIssuerHeldStrategies(issuerId) {
    const { data } = await $http.get(`/tradeorders/${issuerId}/issuerswithcompanyinfo/lite`)
    return data
  },
  async postTradeOrder(payload) {
    const wrappedPayload = {
      tradeOrderDate: new Date().toISOString(),
      sendEmail: true,
      tradeOrders: payload
    }
    const { status } = await $http.post('/tradeorders', wrappedPayload)
    return status === 200
  }
}
