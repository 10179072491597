import $http from './apiClient'

export default {
  async getEvents(body) {
    const response = await $http.post(`/events/lite`, body)
    return response.data.events
  },

  async getEventsForIssuer(body) {
    const response = await $http.post(`/issuers/${body.issuerId}/events`, body)
    return response.data.events
  },

  async getPointInTime(eventId) {
    const response = await $http.get(`/events/${eventId}/pointintime`)
    return response.data
  },

  async getPriceTargetData(issuerId) {
    const { data } = await $http.get(`/value/issuers/${issuerId}/pricetarget/metricsandattachments `)
    return data
  },

  async getPriceTargetAnalystMetrics() {
    try {
      const { data } = await $http.get(`/value/analystmetrickeys`)
      return data
    } catch (e) {
      console.error('Error fetching Price Target analyst metrics data', e)
    }
  },

  async getPriceTargetAnalystRatioKeys() {
    try {
      const { data } = await $http.get(`/value/analystratiokeys`)
      return data
    } catch (e) {
      console.error('Error fetching Price Target analyst ratio key data', e)
    }
  },
}
