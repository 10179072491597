<template>
  <div>
    <div>
      <div>
        <span style="margin-right:10px;" class="fa fa-lg fa-exchange init-trade"/>
        <span>Trade Initiation</span>  
        <span v-if="model.tradeStatus==='TRADE_EDITED'">- AMENDED</span>
        <span v-if="model.tradeStatus==='TRADE_CANCELLED'">- CANCELLED</span>
      </div>
      <trade-instruction-list :model="model"/>
    </div>
  </div>
</template>

<script>
import TradeInstructionList from './TradeInstructionList.vue'

export default {
  name: 'TradeInstruction',
  components: {
    TradeInstructionList
  },
  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    categoryName: { type: String, required: true }
  }
}
</script>

<style lang="scss">
.ios .list {
  margin-bottom: 0;
}
.ios .list ul ul {
  padding-left: 0;
}
.init-trade {
  transform: rotate(-45deg);
}
</style>
