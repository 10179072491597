<template>
  <f7-popup class="demo-popup" :opened="opened" @popup:closed="onPageBeforeRemove">
    <f7-page>
      <f7-navbar :title="info.title">
        <f7-nav-right>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <slot/>
        <div class="footer">
          <span>{{ footer }}</span>
        </div> 
        <div class="sponsor-endorser" v-if="(info.endorser && info.endorser !== 'pending') || (info.sponsor && info.sponsor !== 'pending')">
          <span>Endorsed by</span><span class="s-e-name">{{ info.endorser }}</span>
          <br><br>
          <span>Sponsored by</span><span class="s-e-name">{{ info.sponsor }}</span>
        </div>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapMutations } from 'vuex'
import util from '../../util'

export default {
  name: 'EventPopup',
  props: {
    opened: { type: Boolean, default: false },
    info: {
      type: Object,
      default() {
        return { title: '', fullName: '', eventDate: '', sponsor: 'pending', endorser: 'pending' }
      }
    }
  },
  methods: {
    ...mapMutations('events', ['clearPopupFlag', 'setPriceTargetData']),
    onPageBeforeRemove() {
      this.setPriceTargetData({})
      this.clearPopupFlag()
      if (this.popup) this.popup.destroy()
    }
  },
  computed: {
    footer() {
      return this.info.onBehalfOf
        ? `By ${this.info.onBehalfOf} via ${this.info.fullName} on ${util.toDateTime(
            this.info.date
          )}`
        : `By ${this.info.fullName} on ${util.toDateTime(this.info.date)}`
    }
  }
}
</script>

<style lang="scss">
.ios .block {
  margin-top: 10px;
}
span {
  margin-right: 5px;
}
.footer {
  color: #007aff;
  margin-top: 15px;
}
.sponsor-endorser {
  margin-top: 40px;
  font-weight: 500;
  font-size: 1rem;
  span:nth-child(3) {
    margin-left: 25px;
  }
  span.s-e-name {
    font-weight: 600;
  }
}
</style>
