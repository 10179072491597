import { render, staticRenderFns } from "./RatingChange.vue?vue&type=template&id=00bd06d0&scoped=true"
import script from "./RatingChange.vue?vue&type=script&lang=js"
export * from "./RatingChange.vue?vue&type=script&lang=js"
import style0 from "./RatingChange.vue?vue&type=style&index=0&id=00bd06d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00bd06d0",
  null
  
)

export default component.exports