<template>
  <f7-popup style="background:white;">
    <f7-page style="background:white;">
      <f7-navbar back-link>
        <f7-nav-title class="comment-title">Comment for {{ component }}</f7-nav-title>
      </f7-navbar>
      <div>
        <h4 class="comment">{{ decodeURIComponent(comment) }}</h4>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
export default {
  props: {
    component: {
      type: [String],
      required: true
    },
    comment: {
      type: [String],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.comment {
  margin: 15px;
}
.comment-title {
  font-size: 0.9rem !important;
  word-wrap: break-word;
  white-space: normal;
}
</style>
