import $http from './apiClient'

export default {
  async getCurrentOrOnBehalfOfUser() {
    const { data = {} } = await $http.get(`/users/currentOnBehalfOfUser`)
    return data
  },

  async getCurrentUser() {
    const { data = {} } = await $http.get(`/users/currentUser`)
    return data
  },

  async getUser(id) {
    const { data = {} } = await $http.get(`/users/${id}`)
    return data
  }
}
