<template>
  <div>
    <div style="display:inline-flex">
      <div class="event-symbol" v-if="entity && entity.entityType === 'ISSUER'">
        <span class="fa fa-lg fa-comment comment" />
      </div>
      <div>
        <div v-if="entity && entity.entityType === 'ISSUER'">
          <span
            v-if="
              entity.entityType === 'ISSUER' && entity.rating && entity.rating.code === 'OUTPERFORM'
            "
            class="event-indicator"
            >O</span
          >
          <span
            v-if="entity.entityType === 'ISSUER' && entity.onLeadersList"
            class="fa fa-trophy trophy"
          />
          <span v-if="entity.entityType === 'ISSUER'" @click="openCompany(entity)" class="lnk">{{
            entity.name
          }}</span>
          <span v-if="entity.entityType !== 'ISSUER'">{{ entity.name }}</span>
        </div>
        <div style="display:flex;">
          <div class="event-symbol" v-if="!entity || entity.entityType !== 'ISSUER'">
            <span class="fa fa-lg fa-comment comment" />
          </div>
          <span
            class="text"
            :style="{ 'font-size': !entity || entity.entityType !== 'ISSUER' ? '17px' : '13px' }"
            >{{ model.title }}</span
          >
        </div>
        <div :style="{ 'margin-left': !entity || entity.entityType !== 'ISSUER' ? '34px' : '0' }">
          <span class="text">{{ model.type.name }}</span>
        </div>
      </div>
    </div>
    <component :info="popupInfo" :opened="popupOpened" :is="'event-popup'">
      <div class="comment-popup">
        <div>
          <span class="header">Title:</span>
          <span>
            <b>{{ model.title }}</b>
          </span>
        </div>
        <div>
          <div class="header">Tags:</div>
          <div class="indent" v-for="(evt, index) in model.entities" :key="index">
            <span v-if="evt.entityType === 'THEMATIC'" class="fa fa-stack-exchange" />
            <span v-if="evt.entityType === 'RMS_USER'" class="fa fa-user" />
            <span
              v-if="evt.entityType === 'REGION' || evt.entityType === 'COUNTRY'"
              class="fa fa-globe"
            />
            <span
              v-if="
                evt.entityType !== 'RMS_USER' &&
                  evt.entityType !== 'REGION' &&
                  evt.entityType !== 'COUNTRY' &&
                  evt.entityType !== 'THEMATIC'
              "
              class="fa fa-bar-chart-o"
            />
            {{ evt.name }}
          </div>
        </div>
        <div v-if="pointInTime && pointInTime.priceLocal">
          <div class="header">Market Data ({{ model.eventDate | toDate }}):</div>
          <div class="indent" v-if="pointInTime.priceLocal">
            Price ({{ pointInTime.tradingCurrency }}) {{ pointInTime.priceLocal.toFixed(2) }}
          </div>
        </div>
        <hr v-if="model.esgTopics || model.esgSources" />
        <div
          style="display:flex;gap:22px;font-size:13px;"
          v-if="model.esgTopics || model.esgSources"
        >
          <div style="display:flex;flex-direction:column;" v-if="model.esgTopics">
            <span style="font-weight:bold;">ESG Topics:</span>
            <ul style="padding:0 0 0 13px;">
              <li v-for="topic in model.esgTopics">
                {{ topic.name }}
              </li>
            </ul>
          </div>
          <div style="display:flex;flex-direction:column;" v-if="model.esgSources">
            <span style="font-weight:bold;">ESG Sources:</span>
            <ul style="padding: 0 0 0 13px;">
              <li v-for="source in model.esgSources">
                {{ source.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="body" v-if="!model.suggestedPosition || model.suggestedPosition.length === 0">
          <span v-if="model.body" v-html="model.body" />
        </div>

        <div
          v-if="model.suggestedPosition && model.suggestedPosition.length > 0"
          style="margin-top:20px;border-top:1px solid silver;"
        >
          <p
            class="event-label event-comment event-suggested-position-title"
            style="margin-bottom:5px;"
          >
            Suggested Positioning
          </p>
          <div
            class="event-suggested-position"
            v-for="(issuer, index) in model.suggestedPosition"
            :key="index"
          >
            <div>
              <p
                class="text-truncate"
                style="cursor:pointer;"
                v-if="model.suggestedPosition.length > 1"
              >
                {{ issuer.issuerName }}&nbsp;&nbsp;({{ issuer.issuerSymbol }})
              </p>
            </div>

            <div class="event-suggested-position-comment-container">
              <div style="width:100%;display">Comment:</div>
              <div>
                <span class="event-suggested-position-comment" v-html="issuer.comment" />
              </div>
            </div>
            <div class="event-suggestions event-suggestions-header">
              <span>Portfolio</span>
              <span>Cur Port %</span>
              <span>Suggested Positioning</span>
            </div>
            <div
              class="event-suggestions"
              v-for="(suggestion, index) in issuer.analystRecommendations"
              :key="index"
            >
              <span class="text-truncate">{{ suggestion.strategyName }}</span>
              <span>
                {{
                  suggestion.portfolioPercentage
                    ? (suggestion.portfolioPercentage * 100).toFixed(2)
                    : '-'
                }}
              </span>
              <span
                :class="{
                  'company-data-good': suggestion.analystRecommendationName === 'Increase',
                  'company-data-bad': suggestion.analystRecommendationName === 'Decrease'
                }"
                >{{ suggestion.analystRecommendationName }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
  name: 'Comment',
  mixins: [eventPopupMixin],
  components: {
    EventPopup
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('events', ['pointInTime']),
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.model.createdBy.fullName,
        onBehalfOf:
          this.model && this.model.onBehalfOf && this.model.onBehalfOf.fullName
            ? this.model.onBehalfOf.fullName
            : null,
        date: this.model.eventDate
      }
    },
    entity() {
      if (!this.model || !this.model.entities) {
        return {}
      }

      const issuer = this.model.entities.find(({ entityType = '' }) => entityType === 'ISSUER')
      return issuer || {}
    }
  },
  methods: {
    openCompany({ id }) {
      this.$f7router.navigate(`/company/${id}/info`)
    }
  }
}
</script>

<style lang="scss">
span {
  margin-right: 5px;
}
.text {
  font-size: 13px;
}
.comment-popup {
  .body {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .indent {
    padding-left: 15px;
  }
  .header {
    font-weight: bold;
  }
}
.comment-popup > div {
  margin-bottom: 15px;
}

.company-data-good {
  color: #50b432;
}

.company-data-bad {
  color: #ff0000;
}

.event-suggested-position-title {
  font-size: 1rem;
  font-weight: bold;
}

.event-suggested-position {
  margin-bottom: 40px;
  p:first-child {
    cursor: pointer !important;
    font-weight: bold;
  }

  .event-suggested-position-comment-container {
    padding-top: 5px;
    padding-bottom: 5px;

    .event-suggested-position-comment {
      display: block;
      color: #222 !important;
    }
  }

  .event-suggestions {
    span {
      font-size: 0.8rem;
      display: inline-block;
      text-align: left;
    }
    span:first-child {
      min-width: 46%;
      max-width: 46%;
    }
    span:nth-child(2) {
      text-align: center;
      min-width: 21%;
      max-width: 21%;
    }
    span:last-child {
      text-align: center;
      padding-right: 3px;
      min-width: 27%;
      max-width: 27%;
    }
  }
  .event-suggestions-header {
    margin-top: 5px;
    span {
      border-bottom: 1px solid #444;
      color: #333;
      font-size: 0.8rem;
    }
    span:nth-child(2) {
      text-align: center;
      width: 100px;
    }
    span:last-child {
      margin-left: -3px;
    }
  }
}

.event-suggestions-comment {
  span {
    font-size: 0.8rem !important;
  }
  span:nth-child(2) {
    text-align: center;
    width: 100px;
  }
}

.event-suggestions-comment-header {
  span {
    font-size: 0.8rem !important;
  }
}
</style>
