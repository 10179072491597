<template>
  <f7-page style="background:white">
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="goBack">
          <span class="co-back"><i class="icon icon-back"/></span>
        </f7-link>  
      </f7-nav-left>
      <f7-nav-title>{{ issuerName }}</f7-nav-title>
      <f7-nav-right> 
        <button class="button col button-round comp-info-trade-btn" v-if="canTrade && (viewType.isGlobal === false || companyInfo.status.isOnEligListInAnyEnv)" @click="goTrade()">Trade</button>
        <f7-link v-if="showEventsFilter" @click="toggleEventsFilter"><span class="fa fa-lg fa-filter co-events-filter"/></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-subnavbar style="border-bottom: 2px solid #E5E5E5;padding-right:15px;">
      <f7-toolbar>
        <f7-link tab-link route-tab-id="companyInfo" href="./info/" @click="showFilter(false)">Company Info</f7-link>
        <f7-link tab-link route-tab-id="companyRecReport" href="./recReport/" @click="showFilter(false)">Rec Report</f7-link>
        <f7-link tab-link route-tab-id="companyEvents" href="./events/" @click="showFilter(true)">Events</f7-link>
      </f7-toolbar>
    </f7-subnavbar>
    <f7-tabs routable>
      <f7-tab id="companyInfo"/>
      <f7-tab id="companyRecReport"/>
      <f7-tab id="companyEvents"/>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      issuerName: '',
      price: null,
      showEventsFilter: false
    }
  },
  props: {
    issuerId: { type: [String, Number], required: true }
  },
  async beforeMount() {
    this.setIssuerCompanyInfo(this.issuerId)
  },
  computed: {
    ...mapState('companyPage', [
      'companyInfo',
      'recReportTabActive',
      'recReportPdfBlob',
      'recReportPdfURL',
      'showRecReportPDF'
    ]),
    ...mapState('user', ['viewType']),
    ...mapGetters('user', ['canTrade'])
  },
  watch: {
    companyInfo(data) {
      this.issuerName = data.info.companyName
      this.price = data.marketData.price
    }
  },
  methods: {
    ...mapActions('companyPage', ['setIssuerCompanyInfo']),
    ...mapActions('trade', ['loadIssuer']),
    ...mapMutations(['setTitle', 'setSubTitle']),
    ...mapMutations('events', ['toggleCompanyEventsFilter']),
    ...mapMutations('companyPage', ['toggleRecReportPDFView']),
    goTolist(listType, listId, title) {
      this.$f7router.navigate(`/lists/${listType}/${listId}`)
      this.setTitle(title)
    },
    goTrade() {
      this.loadIssuer(this.issuerId).then(() => {
        this.$f7router.navigate(`/trade/${this.issuerId}`)
      })
    },
    goBack() {
      let history = this.$f7router.history
      let url = ''
      for (var i = history.length - 1; i >= 0; i--) {
        if (history[i] !== this.$f7router.currentRoute.path) {
          url = history[i]
          break
        }
        if (i === 0) url = '/events/0'
      }

      this.$f7router.navigate(url)
      let vm = this
      setTimeout(() => {
        vm.$f7router.refreshPage()
      })
    },
    toggleEventsFilter() {
      this.toggleCompanyEventsFilter()
    },
    showFilter(visible) {
      this.showEventsFilter = visible
    }
  }
}
</script>

<style>
.nav-price {
  margin-right: 5px;
  font-size: 0.8rem;
}
.company-page {
  background: white;
}
.tab-link {
  font-size: 0.75rem;
}
.tab-link:first-child {
  padding-left: 00px;
}
.tab-link:last-child {
  padding-right: 0px;
}
.tab-link-active {
  font-weight: bold;
  font-size: 0.8rem;
}
.co-events-filter {
  margin-left: 25px;
}
.co-back {
  margin-left: 10px;
}
</style>
