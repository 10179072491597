import { msalConfig, summitRequest } from './authConfig'
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
export const myMSALObj = new PublicClientApplication(msalConfig);

let username = null

/**
 * A promise handler needs to be registered for handling the
 * response returned from redirect flow. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */
myMSALObj.handleRedirectPromise().then((resp) => {  
  if (resp && resp.account) {
    myMSALObj.setActiveAccount(resp.account)
    username = resp.account.username
  } else {
    const accounts = myMSALObj.getAllAccounts()
    if (accounts.length === 0) {
      myMSALObj.loginRedirect();
    } else {
      try {
        myMSALObj.setActiveAccount(accounts[0])
        username = accounts[0].username
      }
      catch (error) {
        console.error("myMSALObj.setActiveAccount(accounts[0])....", error)
      }
    }
  }  
})

export function getTokenRedirect(request) {
  return myMSALObj.acquireTokenSilent(summitRequest).catch(error => {
    console.error(
      'silent token acquisition fails. acquiring token using redirect',
      error
    );
    if (error instanceof InteractionRequiredAuthError) {
      console.info("error instanceof msal.InteractionRequiredAuthError....")
      // fallback to interaction when silent call fails
      //return myMSALObj.acquireTokenRedirect(request);
      return myMSALObj.acquireTokenPopup(summitRequest);
    }
  });
}