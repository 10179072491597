<template>
  <div>
    <div style="display:inline-flex">
      <div class="event-symbol">
        <span
          class="fa fa-lg"
          :class="{
            'fa-plus-circle': model.type.code === 'ADD_TO_LIST',
            'fa-minus-circle': model.type.code === 'REMOVE_FROM_LIST'
          }"
        />
      </div>
      <div>
        <div>
          <span
            v-if="model.entities[0].rating && model.entities[0].rating.code === 'OUTPERFORM'"
            class="event-indicator"
            >O</span
          >
          <span v-if="model.entities[0].onLeadersList" class="fa fa-trophy trophy" />
          <span @click="onClickCompany(model.entities[0])" class="lnk">{{
            model.entities[0].name
          }}</span>
        </div>
        <div>
          <span class="title">{{ title }}</span>
        </div>
      </div>
    </div>
    <component :info="popupInfo" :opened="popupOpened" :is="'event-popup'">
      <div class="list-membership-popup">
        <div>
          <span class="header">Title:</span>
          <span>
            <b>{{ model.title }}</b>
          </span>
        </div>
        <div v-if="model.rationale">
          <span class="header">Rationale:</span>
          <span>{{ model.rationale.name }}</span>
        </div>
        <div>
          <div class="header">Tags:</div>
          <div class="indent" v-for="(evt, index) in model.entities" :key="index">
            <span v-if="evt.entityType === 'THEMATIC'" class="fa fa-stack-exchange" />
            <span v-if="evt.entityType === 'RMS_USER'" class="fa fa-user" />
            <span
              v-if="evt.entityType === 'REGION' || evt.entityType === 'COUNTRY'"
              class="fa fa-globe"
            />
            <span
              v-if="
                evt.entityType !== 'RMS_USER' &&
                  evt.entityType !== 'REGION' &&
                  evt.entityType !== 'COUNTRY' &&
                  evt.entityType !== 'THEMATIC'
              "
              class="fa fa-bar-chart-o"
            />
            {{ evt.name }}
          </div>
        </div>
        <div v-if="pointInTime">
          <div class="header">Market Data ({{ model.eventDate | toDate }}):</div>
          <div class="indent" v-if="pointInTime.priceLocal">
            Price ({{ pointInTime.tradingCurrency }}) {{ pointInTime.priceLocal.toFixed(2) }}
          </div>
        </div>
        <div class="body">
          <span v-if="model.body" v-html="model.body" />
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
  name: 'ListMembership',
  mixins: [eventPopupMixin],
  components: {
    EventPopup
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('events', ['pointInTime']),
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.model.createdBy.fullName,
        date: this.model.eventDate,
        endorser: this.model.hasOwnProperty('endorser') ? this.model.endorser.fullName : 'pending',
        sponsor: this.model.hasOwnProperty('sponsor') ? this.model.sponsor.fullName : 'pending'
      }
    },
    title() {
      let title = ''
      let viewType = this.$toTitleCase(this.model.viewType)
      if (this.model) {
        title = this.model.title || ''
      }

      return title
    }
  }
}
</script>

<style lang="scss" scoped>
.list-membership-popup {
  .body {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .indent {
    padding-left: 15px;
  }
  .header {
    font-weight: bold;
  }
}
.list-membership-popup > div {
  margin-bottom: 15px;
}
</style>
