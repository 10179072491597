<template>
  <f7-page>
    <f7-navbar title="Error" back-link/>
    <f7-block strong>
      <p>Sorry</p>
      <p>{{ message }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
export default {
  props: { message: { type: String, default: 'Your request can not be accomplished' } }
}
</script>
