<template>
  <div>
    <table>
      <thead>
        <th>STRAT</th>
        <th>SYM</th>
        <th>DIR</th>
        <th>TO<br />BPS</th>
        <th>FROM<br />BPS</th>
      </thead>
      <tbody>
        <tr v-for="(trade, index) in model.tradeInstructionList" :key="index">
          <td style="width:20%">
            {{ trade.strategy.displayName || trade.strategy.shortName || trade.strategy.code }}
          </td>
          <td @click="onClickCompany(trade.issuer)" class="lnk" style="width:30%">
            {{ trade.security.symbol }}
          </td>
          <td v-if="trade.fromBps == 0 && trade.toBps > 0">BUY</td>
          <td v-if="trade.fromBps > 0 && trade.toBps > trade.fromBps">ADD</td>
          <td v-if="trade.fromBps > 0 && trade.toBps < trade.fromBps && trade.toBps !== 0">TRIM</td>
          <td v-if="trade.fromBps > 0 && trade.toBps == 0">SELL</td>
          <td>{{ trade.toBps }}</td>
          <td>{{ trade.fromBps }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TradeInstructionList',
  props: {
    model: { type: Object, required: true }
  },
  methods: {
    onClickCompany({ id }) {
      this.$f7router.navigate(`/company/${id}/info`)
    }
  }
}
</script>

<style lang="scss">
table {
  width: 100%;
  td,
  th {
    font-size: 11px;
  }
  th {
    vertical-align: bottom;
  }
  td:nth-child(n + 3) {
    width: 15%;
  }
}
</style>
