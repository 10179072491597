<template>
  <div>
    <div class="research-init">
      <div class="event-symbol">
        <span class="fa fa-lg fa-book" />
      </div>
      <div>
        <div>
          <span @click.stop="onClickCompany(model.issuer)" class="lnk">{{
            model.entities[0].name
          }}</span>
        </div>
        <div>
          <span @click.stop="openRecReport(model.issuer, model)" class="lnk"
            >Recommendation Report</span
          ><span>updated</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
  name: 'ResearchInitiativeChange',
  mixins: [eventPopupMixin],
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['viewType']),
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.model.createdBy.fullName,
        date: this.model.eventDate
      }
    }
  },
  methods: {
    openRecReport(issuer, event) {
      // Uses the menu version of the openRecReport function to decide whether to show
      // Domestic or Global report
      this.$parent.$parent.$parent.$parent.$parent.$parent.openRecReport(issuer, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.research-init {
  display: inline-flex;
}
.research-init-popup > div {
  margin-bottom: 15px;
}
.research-init-popup {
  .emphasis {
    font-weight: bold;
  }
  ul {
    padding-left: 30px;
    margin-top: 5px;
  }
}
</style>
