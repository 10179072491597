import $http from './apiClient'

export default {
  async getCompanyRecReport(issuerId) {
    return await $http.get(`/issuers/${issuerId}/researchreports/v2/latest`)
  },
  async getCompanyRecReportPDF(issuerId) {
    return await $http.get(`/issuers/${issuerId}/researchreports/latest`, {
      responseType: 'blob'
    })
  }
}
