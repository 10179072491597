<template>
  <f7-popup>
    <f7-page class="trade-init-page" v-if="issuerData">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close @click="refreshPage">
            <span>Cancel</span>
          </f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ issuerData.issuerName }}</f7-nav-title>
        <f7-nav-right>
          <f7-link
            :class="{'disabled': submitTradeDisabled}"
            no-fast-click
            v-show="isGlobal ? canTradeGlobal : canTradeDomestic"
            @click="$refs.actionsConfirm.open()"
          >Submit</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- SECURITY -->
       <div v-if="!onEligList && isGlobal">
        <div class="warnings-wrapper">
          <span>This security cannot be traded.</span>
          <span>You are only allowed to Sell or Trim.</span>
          <span>This is Buy or Add.</span>
        </div>
       </div>
      <f7-list style="margin-bottom:0">
        <f7-list-item
          title="Security"
          ref="selSecurity"
          smart-select
          :smart-select-params="{openIn: 'sheet',routableModals:false}"
        >
          <select name="trade-securities" @change="changeSecurity">
            <option
              v-for="{id, symbol, primary} in securities"
              :key="id"
              :value="id"
              :selected="primary"
            >{{ symbol }}</option>
          </select>
        </f7-list-item>
      </f7-list>

      <f7-list inset>
        <f7-list-item title="Trade" group-title style="display:inline-flex;">
          <div style="display:inline-flex;justify-content:flex-start">
            <span class="info-data__strong">Trade</span>
          </div>
          <div
            style="padding-left:130px;display:inline-flex;justify-content:flex-end;align-items:baseline;"
          >
            <span class="info-data__strong">{{ selectedSecurity.price }}</span>
            <span class="info-data__secondary">{{ selectedSecurity.currency }}</span>&nbsp;&nbsp;&nbsp;
            <span
              class="info-data-asof"
            >As of {{ getDateStr(selectedSecurity.asOf) }}</span>
          </div>
        </f7-list-item>
      </f7-list>

      <f7-block strong style="margin:0; min-height: 100px">
        <f7-row style="align-items:center;">
          <f7-col width="20">
            <f7-label class="trades-header">Strategy</f7-label>
          </f7-col>
          <f7-col width="20">
            <f7-label class="trades-header" style="margin-left:-5px;">Bmk</f7-label>
          </f7-col>
          <f7-col width="20">
            <div>
              <f7-label class="trades-header">From</f7-label>
            </div>
          </f7-col>
          <f7-col width="20">
            <div>
              <f7-label class="trades-header">To</f7-label>
            </div>
          </f7-col>
          <f7-col width="20">
            <div>
              <f7-label class="trades-header">Change</f7-label>
            </div>
          </f7-col>
        </f7-row>

        <f7-row
          style="height:60px; align-items:center"
          v-for="(strategy, index) in strategies"
          :key="index"
        >
          <f7-col width="20">
            <f7-label
              class="trades-label"
              :class="{'trades-label-selected': strategy.code===selectedStrategy.code}"
            >{{ strategy.shortName }}</f7-label>
          </f7-col>
          <f7-col width="20">
            <f7-label class="trades-label">{{ strategy.benchmarkWeight*100 | number(2) }}</f7-label>
          </f7-col>
          <f7-col width="20">
            <div>
              <f7-input
                type="number"
                :value="strategy.fromBps || 0"
                disabled
                input-style="background-color:#ccc; text-align:center; border:1px solid #999; border-radius: 3px; height:30px; font-size:16px; width:65px"
              />
            </div>
          </f7-col>
          <f7-col width="20">
            <div>
              <f7-input
                type="number"
                :value="strategy.toBps"
                @focus="changeStrategy($event, strategy)"
                @input="changeToBps($event, strategy)"
                input-style="text-align:center; border:1px solid #999; border-radius:3px; height:30px; font-size:16px; width:65px"
              />
            </div>
          </f7-col>
          <f7-col width="20">
            <span 
              :style="{
                'color': strategy.fromBps > strategy.toBps ? 'red' : 'green'
              }
            ">
              {{ (strategy.toBps - strategy.fromBps) || "" }} 
            </span>
          </f7-col>
        </f7-row>
      </f7-block>

      <!--global trade form-->
      <div class="trade-priority" v-if="isGlobal">
        <f7-label style="padding-left: 8px;">Trade Priority</f7-label>
        <div class="trade-priority--btn">
          <button
            @click="(e) => setTradePriority(e)"
            v-for="priority in tradePriorityList"
            :class="[
              'button', 
              currentTradePriority === priority ? `select-priority-${priority.toLowerCase().replace('.','')}` : '',
            ]"
          >
            {{ priority }}
          </button>
        </div>
      </div>
      <section v-if="isGlobal && selectedStrategy.toBps !== undefined">
        <f7-list inset class="catalyst-select">
          <f7-block-title>
            Trade Catalyst & Rationale -&nbsp;<span style="color: black;">{{ this.selectedStrategy.shortName }}</span>
          </f7-block-title>
          <div class="driver-select">
            <button 
              v-for="driver in tradeDrivers" 
              :value="driver.code" 
              @click="selectTradeDriver(driver.code)"
              :class="[
                (
                  selectedStrategy.hasOwnProperty('tradeDriverCode') &&
                  selectedStrategy.tradeDriverCode === driver.code
                )? 'selected-driver' : ''
              ]"
            >
              {{ driver.name }}
            </button>
          </div>
        </f7-list>
      </section>
      <section v-if="isCatalystDisplayed">
        <f7-list inset class="catalyst-select">
          <f7-block-title>Catalyst (select one):</f7-block-title>
          <div class="trade-select">
            <select
              @change="selectCatalyst($event.target.value)"
            >
              <option value="" v-if="!selectedStrategy.hasOwnProperty('tradeCatalystCode')">Select catalyst</option>
              <option
                v-for="catalyst in availableCatalysts" 
                :value="catalyst.code" 
                :selected="selectedTradeCatalystCode === catalyst.code"
              >
                {{catalyst.name}}
              </option>
            </select>
            <i class="f7-icons arrow-icon" @click="handleArrowClick">arrow_down</i>
          </div>
        </f7-list>
      </section>
      <section v-if="isRationaleDisplayed">
        <f7-list inset class="catalyst-select">
          <f7-block-title>Rationales (select one):</f7-block-title>
          <div class="trade-select">
            <select
              @change="selectRationale($event.target.value)"
            >
              <option value="" v-if="!selectedStrategy.hasOwnProperty('tradeRationaleCode')">Select rationale</option>
              <option 
                v-for="rationale in rationales" 
                :value="rationale.code" 
                :selected="selectedTradeRationaleCode === rationale.code"
              >
                {{ rationale.name }}
              </option>
            </select>
            <i class="f7-icons arrow-icon" @click="handleArrowClick">arrow_down</i>
          </div>
        </f7-list>  
      </section>
      <f7-list inset v-if="isGlobal" style="margin-bottom: 10px;">
        <f7-block-title>Rationale comment:</f7-block-title>
        <f7-list-item>
          <f7-input
            type="textarea"
            :value="selectedStrategy.rationaleComment"
            @input="selectedStrategy.rationaleComment=$event.target.value"
            resizable
            @input:clear="clearRationaleInput"
            clear-button
            @blur="validateGlobalTrades"
            input-style="font-size:14px"
          />
        </f7-list-item>
      </f7-list>
      <!--global trade form end-->
      <f7-list inset v-if="!isGlobal">
        <f7-list-item :title="rationalesLabel" group-title />
        <f7-list-item
          class="rationale-checkbox"
          ref="chkRationales"
          v-for="r in selectedStrategy.rationales"
          :key="r.value"
          checkbox
          @change="changeRationale($event, r)"
          :checked="r.checked"
          :title="r.label"
          :value="r.value"
        />
      </f7-list>
      <f7-list inset>
        <f7-list-item>
          <f7-label>Earnings Date: {{ getDateStr(issuerData.earningsDate) }}</f7-label>
        </f7-list-item>
        <f7-list-item>
          <div v-if="!needInstructions">
            <f7-link @click="needInstructions=true">{{ instructionsLabel }}</f7-link>
            <f7-link class="instructions-required" v-show="isEarningsDateWithin5Days">(* required)</f7-link>
          </div>
          <f7-input
            v-else
            type="textarea"
            :value="selectedStrategy.instructions"
            @input="selectedStrategy.instructions=$event.target.value"
            resizable
            :info="instructionsLabel"
            clear-button
            @input:clear="setNeedsInstructionsFalse"
            @blur="checkForInstructions"
            input-style="font-size:14px"
          />
        </f7-list-item>
      </f7-list>
      <f7-list inset style="margin-top: 10px;">
        <f7-list-item>
          <f7-link v-if="!needPriceLimit" @click="needPriceLimit=true">{{ priceLimitLabel }}</f7-link>
          <f7-input
            v-else
            type="number"
            validate
            step=".01"
            pattern="[0-9]*\.?\[0-9]*"
            :value="selectedStrategy.priceLimit"
            @input="selectedStrategy.priceLimit=$event.target.value"
            :info="priceLimitLabel"
            clear-button
            @input:clear="needPriceLimit=false"
            input-style="font-size:14px"
          />
        </f7-list-item>
      </f7-list>
      <f7-actions ref="actionsConfirm">
        <f7-actions-group>
          <f7-actions-label style="font-size: 18px" v-html="confirmLabel" />
          <f7-actions-button bold @click="submitTradeOrder" class="trade-confirm">
            <f7-button popup-close no-fast-click class="trade-confirm-btn">Confirm</f7-button>
          </f7-actions-button>
        </f7-actions-group>
        <f7-actions-group>
          <f7-actions-button no-fast-click color="red">Cancel</f7-actions-button>
        </f7-actions-group>
      </f7-actions>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import api from '../apis/trade'
import search from '../apis/search'
import util from '../util'

export default {
  data() {
    return {
      onEligList: true,
      showGlobalTradeSubmit: false,
      tradePriorityList: ['High', 'Med.', 'Low'],
      currentTradePriority: 'Med.',
      submitTradeDisabled: false,
      submitTradeInProgress: false,
      needPriceLimit: false,
      needInstructions: false,
      toBps: 0,
      selectedStrategy: {},
      selectedTradeDriver: null,
      showCatalystSection: false,
      showRationaleSection: false,
      selectedSecurity: {},
      availableCatalysts: [],
      selectedCatalyst: null, 
      catalystKey: 0,
      tradeDrivers: [
        { code: 'RESEARCH_DRIVEN', name: 'Research Driven' },
        { code: 'PORTFOLIO_DRIVEN', name: 'Portfolio Driven' }
      ],
      catalysts: [
        { code: 'UPGRADE', name: 'Upgrade' },
        { code: 'DOWNGRADE', name: 'Downgrade' },
        {
          code: 'CHANGE_EXP_RETURN',
          name: 'Change in expected Return/Confidence/Certainty'
        },
        {
          code: 'PORT_EXP',
          name: 'Portfolio Exposure: Factor/Region/Sector/Size'
        },
        { code: 'PORT_GUIDELINES', name: 'Portfolio Guidelines' }
      ],
      rationales: [
        { code: 'VALUATION_TR', name: 'Valuation' },
        { code: 'COUNTRY_TR', name: 'Country / Regulatory' },
        { code: 'COMPETITIVE_TR', name: 'Competitive / End Market' },
        { code: 'ESG_TR', name: 'ESG' },
        { code: 'COMP_EXEC_STRAT_TR', name: 'Company Execution or Strategy' }
      ],
    }
  },
  async mounted() {
    if (this.$ua) {
      this.$ua.trackView(`Trading Page for Issuer: ${this.issuerId}`)
    }
    try {
      this.selectedStrategy = this.strategies[0]
      this.selectedSecurity = this.securities.find((d) => d.primary)
    } catch (e) {
      const message = 'Can not load data for the issuer to trade, please try again later.'
      this.$f7router.navigate('/error', { props: { message } })
    }
    await this.getIssuerDetails()
  },
  computed: {
    ...mapState('trade', ['issuerData', 'securities', 'strategies']),
    ...mapState('user', ['viewType']),
    isCatalystDisplayed() {
      return this.showCatalystSection
    },

    isRationaleDisplayed() {
      return this.showRationaleSection
    },
    selectedTradeDriverCode() {
      if (
        this.selectedStrategy.hasOwnProperty('tradeDriverCode') && 
        this.selectedStrategy.tradeDriverCode !== undefined
      ) {
        return this.selectedStrategy.tradeDriverCode
      }
      return null
    },
    selectedTradeCatalystCode() {
      if (
        this.selectedStrategy.hasOwnProperty('tradeCatalystCode') && 
        this.selectedStrategy.tradeCatalystCode !== undefined
      ) {
        return this.selectedStrategy.tradeCatalystCode
      }
      return null
    },
    selectedTradeRationaleCode() {
      if (
        this.selectedStrategy.hasOwnProperty('tradeRationaleCode') && 
        this.selectedStrategy.tradeRationaleCode !== undefined
      ) {
        return this.selectedStrategy.tradeRationaleCode
      }
      return null
    },
    isGlobal() {
      return this.viewType.isGlobal
    },
    selectedIssuerId() {
      return this.issuerId
    },
    canTradeGlobal() {
      return this.showGlobalTradeSubmit && this.onEligList
    },  
    canTradeDomestic() {
      let trade = true
      // if there are no trades entered, return false
      let goodTradeFound = false
      this.strategies.forEach((s) => {
        if (s.toBps !== undefined && !Number.isNaN(s.toBps) && s.toBps >= 0) {
          goodTradeFound = true
        }
      })
      if (!goodTradeFound) {
        return false
      }
      //if any of the trades is bad (e.g. negative toBps), return false
      this.strategies.forEach((s) => {
        if (
          (s.toBps !== undefined && Number.isNaN(s.toBps)) || // if toBps is defined but not a number
          s.toBps < 0 || // if toBps is negative
          ((s.fromBps === 0 || s.fromBps === undefined) && s.toBps === 0)
        ) {
          // if fromBps has no value and toBps is 0 (0 -> 0)
          trade = false
        }
      })
      // if any strategy's trade does not have instructions when the earnings date is within 5 days of today, retrun false
      // or if there is not at least one rationale selected for a strategy's trade, return false
      this.strategies.forEach((strategy) => {
        if (
          strategy.toBps !== undefined &&
          strategy.toBps !== null &&
          !Number.isNaN(strategy.toBps)
        ) {
          if (
            this.isEarningsDateWithin5Days &&
            (!strategy.instructions || strategy.instructions.trim().length < 2)
          ) {
            trade = false
          }
          if (strategy.rationales.filter((r) => r.checked).length === 0) {
            trade = false
          }
        }
      })
      return trade
    },
    isEarningsDateWithin5Days() {
      if (!this.issuerData.earningsDate) return false
      const curDate = new Date()
      const earningsDate = new Date(this.issuerData.earningsDate)
      const isEarningsDateValid = earningsDate.setHours(1, 0, 0) >= curDate.setHours(0, 0, 0)
      return isEarningsDateValid && util.daysBetween(curDate, earningsDate) <= 5
    },
    confirmLabel() {
      let confirm = ''
      let self = this
      this.strategies.forEach((s) => {
        if (s.toBps === undefined || s.toBps === null || Number.isNaN(s.toBps)) {
          return
        }
        const { code, fromBps, toBps, priceLimit } = s
        const issuerName = this.issuerData.issuerName
        let label = `${self.tradeDirection(s)} ${issuerName} from ${fromBps || 0} to ${toBps}`
        if (priceLimit) {
          label += ' w/ price limit ' + priceLimit
        }
        confirm += `${code}:  ${label}<br/>`
      })
      return confirm
    },
    rationalesLabel() {
      if (this.selectedStrategy) {
        return `Rationales for ${this.selectedStrategy.code}`
      } else {
        return 'Rationales'
      }
    },
    instructionsLabel() {
      if (this.selectedStrategy) {
        return `Add Instructions for ${this.selectedStrategy.shortName}`
      } else {
        return 'Add Instructions'
      }
    },
    priceLimitLabel() {
      if (this.selectedStrategy) {
        return `Add Price Limit for ${this.selectedStrategy.shortName}`
      } else {
        return 'Add Price Limit'
      }
    }
  },
  methods: {
    ...mapMutations(['setTitle']),
    ...mapActions('trade', ['loadIssuer']),
    ...mapMutations('search', ['showTradeSearch']),

    async getIssuerDetails() {
      let res = await search.searchByIssuerId(this.issuerData.issueId)
      this.onEligList = res.data.onEligibilityList
    },

    handleArrowClick(e) {
      e.target.previousElementSibling.focus()
    },  

    validateBpsEntries(strategy) {
      let toBps = parseInt(strategy.toBps)
      let fromBps = parseInt(strategy.fromBps === null ? 0 : strategy.fromBps)
      return !Number.isNaN(toBps) && !Number.isNaN(fromBps) && toBps !== fromBps && toBps > 0
    },
    validateTradeCatalystAndRationale(strategy) {
      let catalystCode = 'CHANGE_EXP_RETURN'
     
      let keys = [
        'tradeDriverCode',
        'tradeCatalystCode',
        'tradeRationaleCode'
      ]

      if (strategy.catalystCode === catalystCode) {
        return keys.every(key => {
          return (
            strategy[key] !== null && 
            strategy[key] !== undefined && 
            strategy[key] !== ''
          )
        })
      } else {
        return keys.slice(0, -1).every(key => {
          return (
            strategy[key] !== null && 
            strategy[key] !== undefined && 
            strategy[key] !== ''
          )
        })
      }
    },
    validateTradeInstruction(strategy) {
      if (!strategy.instructions) {
        return false
      }
      return (strategy.instructions.trim().length > 0)
    },
    setTradePriority(e) {
      this.currentTradePriority = e.target.innerText
    },
    selectTradeDriver(tradeDriverCode) {
      this.selectedStrategy.tradeDriverCode = tradeDriverCode
      let catalysts = null
      if (tradeDriverCode === 'RESEARCH_DRIVEN') {
        catalysts = this.catalysts.slice(2, 3)
      } else {
        catalysts = this.catalysts.slice(2)
      }
      if (this.selectedStrategy.toBps < this.selectedStrategy.fromBps) {
        this.availableCatalysts = [this.catalysts[1], ...catalysts]
      } else {
        this.availableCatalysts = [this.catalysts[0], ...catalysts]
      }
      this.showCatalystSection = true
      this.showRationaleSection = this.selectedStrategy.tradeCatalystCode === 'CHANGE_EXP_RETURN'
      this.validateGlobalTrades()
      this.$forceUpdate()
    },
    setCatalystSectionVisibility(show) {
      const el = document.getElementById('catalyst')
      if (el) {
        el.style.display = show ? el.style.display = 'block' : 'none'
      }
    },
    selectCatalyst(catalyst) {
      this.selectedStrategy.tradeCatalystCode = catalyst
      this.showRationaleSection = this.selectedStrategy.tradeCatalystCode === 'CHANGE_EXP_RETURN'
      if (!this.showRationaleSection && this.selectedStrategy.tradeRationaleCode) {
        delete this.selectedStrategy['tradeRationaleCode']
      }
      this.validateGlobalTrades()
    },
    selectRationale(rationale) {
      this.selectedStrategy.tradeRationaleCode = rationale.code
    },
    tradeDirection(strategy) {
      const from = Number(strategy.fromBps || 0)
      if (from === 0) return 'Buy'
      const change = Number(strategy.toBps) - from
      if (change > 0) return 'Add'
      if (change < 0 && Number(strategy.toBps) == 0) return 'Sell'
      if (change < 0) return 'Trim'
    },
    setNeedsInstructionsFalse() {
      this.needInstructions = false
    },
    changeRationale(event, rationale) {
      // if they just unchecked something even if nothing else is checked, just set it and return
      if (!event.target.checked) {
        rationale.checked = event.target.checked
        return
      }

      // if the checked a rationale, we need to look through all the strategies to see if a rationale has been selected
      // for any of them, if not, we need to check this rationale for all strategies
      let foundIt = false
      this.strategies.forEach((s) => {
        s.rationales.forEach((rationale) => {
          if (rationale.checked) {
            foundIt = true
          }
        })
      })

      if (!foundIt) {
        // no strategies have a rationale selected so make this one the default for all strategies
        this.strategies.forEach((x) => {
          let rat = x.rationales.find((r) => r.value === rationale.value)
          if (rat) {
            rat.checked = true
          }
        })
      } else {
        // at least one strategy had a rationale seleced so just check this one
        rationale.checked = event.target.checked
      }
    },

    clearRationaleInput() {
      this.selectedStrategy.rationaleComment = ''
      this.validateGlobalTrades()
    },

    validateGlobalTrades() {
      let trades = this.strategies.filter(strat => this.validateBpsEntries(strat))
      let isValidTrade = trades.every(trade => this.validateTradeCatalystAndRationale(trade))
      let hasRationaleComment = trades.every(trade => (
        trade.rationaleComment &&
        trade.rationaleComment !== undefined &&
        trade.rationaleComment !== null &&
        trade.rationaleComment !== ""
      ))

      if (this.isEarningsDateWithin5Days && hasRationaleComment) {
        isValidTrade = trades.every(trade => this.validateTradeInstruction(trade))
      }
      
      if (trades.length > 0 && isValidTrade && hasRationaleComment) {
        this.showGlobalTradeSubmit = true
      } else {
        this.showGlobalTradeSubmit = false
      }
      
    },

    changeStrategy(event, strategy) {
      this.selectedStrategy = strategy
      if (this.selectedStrategy.tradeDriverCode) {
        this.selectTradeDriver(this.selectedStrategy.tradeDriverCode)
      } else {
        this.showCatalystSection = false
        this.setCatalystSectionVisibility(false)
        this.catalystKey++
        this.showRationaleSection = false
      }
      this.checkForInstructions()
    },

    changeToBps(event, strategy) {
      this.selectedStrategy = strategy
      const toBps = event.target.value.trim().length > 0 ? Number(event.target.value) : undefined
      strategy.toBps = toBps
    },

    changeSecurity(event) {
      const id = event.target.value
      this.selectedSecurity = this.securities.find((s) => String(s.id) === id)
    },

    getDateStr(dateStr) {
      try {
        const dateFormatOpts = { month: 'short', day: '2-digit', year: 'numeric' }
        return new Intl.DateTimeFormat('en-US', dateFormatOpts).format(Date.parse(dateStr))
      } catch (e) {
        return 'invalid date'
      }
    },

    resetPopUp() {
      this.needPriceLimit = false
      this.needInstructions = false
      this.selectedSecurity = {}
      this.selectedStrategy = {}
      this.issuerId = null
    },

    submitTradeOrder() {
      if (this.tradeSubmitInProgress === true) {
        return
      }
      this.tradeSubmitInProgress = true
      this.submitTradeDisabled = true
      let payload = []
      const vm = this
      const selectRef = this.$refs['selSecurity'].$el.querySelector('.smart-select')
      const securityId = this.$f7.smartSelect.get(selectRef).selectEl.value
      if (this.isGlobal) {
        this.strategies.forEach(strategy => {
          if (this.validateBpsEntries(strategy) && this.validateTradeCatalystAndRationale(strategy)) {
            if (this.isEarningsDateWithin5Days) {
              if (this.validateTradeInstruction(strategy)) {
                let trade = {
                  issuerId: vm.issuerData.issueId,
                  securityId: securityId,
                  toBps: parseInt(strategy.toBps),
                  fromBps: strategy.fromBps || 0,
                  tradeCancelled: strategy.tradeCancelled || false,
                  strategyId: strategy.id,
                  rationale: [
                    strategy.tradeRationaleCode,
                    strategy.tradeCatalystCode
                  ],
                  rationaleComment: strategy.rationaleComment,
                  tradeDriverCode: strategy.tradeDriverCode,
                  tradeCatalystCode: strategy.tradeCatalystCode,
                  tradeRationaleCode: strategy.tradeRationaleCode,
                  instructions: strategy.instructions,
                  priceLimit: strategy.priceLimit,
                  priority: this.currentTradePriority
                }
                payload.push(trade)
              }
            } else {
              let trade = {
                issuerId: vm.issuerData.issueId,
                securityId: securityId,
                toBps: parseInt(strategy.toBps),
                fromBps: strategy.fromBps,
                tradeCancelled: strategy.tradeCancelled || false,
                strategyId: strategy.id,
                rationale: [
                  strategy.tradeRationaleCode,
                  strategy.tradeCatalystCode
                ],
                rationaleComment: strategy.rationaleComment,
                tradeDriverCode: strategy.tradeDriverCode,
                tradeCatalystCode: strategy.tradeCatalystCode,
                tradeRationaleCode: strategy.tradeRationaleCode,
                instructions: strategy.instructions,
                priceLimit: strategy.priceLimit,
                priority: this.currentTradePriority
              }
              payload.push(trade)
            }
          }
        })
      } else {
        this.strategies.forEach((strategy) => {
        if (
          strategy.toBps != null &&
          !Number.isNaN(strategy.toBps) &&
          strategy.toBps >= 0 &&
          !(
            (strategy.fromBps === undefined || strategy.fromBps === 0) &&
            (strategy.toBps === undefined || strategy.toBps === 0)
          )
        ) {
          payload.push({
            issuerId: vm.issuerData.issueId,
            securityId: securityId,
            strategyId: strategy.id,
            fromBps: strategy.fromBps || 0,
            toBps: strategy.toBps,
            priceLimit: Number(strategy.priceLimit),
            rationale: strategy.rationales
              .filter((r) => r.checked)
              .map((d) => d.value.toUpperCase()),
            instructions: strategy.instructions
          })
        }
        })
      }
      api.postTradeOrder(payload).then(
        () => {
          // success
          const options = {
            text: 'Trade order has been posted successfully',
            closeTimeout: 2000,
            destroyOnClose: true,
            icon: '<i class="f7-icons">check_round</i>',
            on: {
              close() {
                vm.resetPopUp()
              }
            }
          }
          this.$f7.toast.show(options)
          this.refreshPage()
          this.tradeSubmitInProgress = false
        },
        () => {
          this.tradeSubmitInProgress = false
          // error
          const message =
            'unexpected error occur while submitting trade order. The trade has been canceled. Please try again later'
          this.$f7router.navigate('/error', { props: { message } })
        }
      )
    },

    refreshPage() {
      this.$f7router.refreshPage()
    },
    checkForInstructions() {
      if (this.isGlobal && this.isEarningsDateWithin5Days) {
        this.validateGlobalTrades()
      }

      !this.selectedStrategy.instructions
        ? (this.needInstructions = false)
        : (this.needInstructions = true)
    }
  },
  watch: {
    securities() {
      const selectRef = this.$refs['selSecurity'].$el.querySelector('.smart-select')
      const securitySelect = this.$f7.smartSelect.get(selectRef)
      this.$nextTick(() => {
        securitySelect.setValue()
        const rationales = this.$refs.chkRationales
        rationales.forEach((x) => (x.checked = false))
      })
    }
  }
}
</script>

<style lang="scss">
.page.trade-init-page {
  .rationale-comment-wrapper {
    margin-bottom: 10px;
  }
  .warnings-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      text-align: center;
      color: red;
    }
  }
  .driver-select {
    display: inline-flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    gap: 3px;
    button {
      border:1px solid gray;
      border-radius: 5px;
      height: 30px;
      font-size: 14px;
    }
    .selected-driver {
      background:#004165;
      color: white;
    }
  }
  .trade-select {
    display: flex;
    justify-content: space-around;    
    align-items: center;
    border-radius: 5px;
    border: 1px solid gray;
    height: 30px;
    width: calc(100% - 22px);
    select {
      padding-left: 10px;
      height: 30px;
      font-size: medium;
    }
    i {
      margin-right: 10px;
      font-size: 15px;
    }
  }
  .catalyst-select {
    padding-left: 8px;
    width: 100%;
  }
  .item-input-wrap {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // input {
    //   text-align: center;
    // }
  }
  .row {
    display: flex;
    justify-content: space-between;
    .col-20 {
      display: flex;
      justify-content: center;
    }
    .col-20:first-child {
      justify-content: center;
      .trades-label {
        text-align: left;
        width: 40%;
      }
    }
  }
  .trade-priority {
    border-bottom: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 3px 0 3px;
    .item-label {
      width: 30%;
      font-size: 16px;
    }
    .trade-priority--btn {
      display: flex;
      width: 90%;
      button {
        border: 1px solid gray;
      }
    }
  }
  .rationaly-catalyst-btn {
    margin: 0 3px 0 3px;
    display: flex;
    justify-content: space-around;
    gap: 1px;
    button {
      width: 50%
    }
  }
  .select-priority-high,
  .select-priority-low,
  .select-priority-med {
    color:black;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .select-priority-high {
    background-color: red;
  }
  .select-priority-low {
    background-color: #f3d311;
  }
  .select-priority-med {
    background-color: orange;
  }
  .block-title {
    padding-left: 8px;
    display: flex;
    align-items: center;
    color: rgb(93, 92, 92);
  }
  .rationale-input {
    padding: 8px;
    background-color: white;
  }
  .trade-rationales,
  .trade-drivers,
  .trade-catalysts {
    background-color: white;
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      align-items: center;
    }
  }
  .trades-header {
    font-size: 13px;
    color: #666;
    text-align: center;
  }
  .instructions-required {
    color: red;
    font-size: 0.9rem;
    margin-left: 15px;
  }
  .trades-label {
    font-size: 16px;
    line-height: 3;
    color: #666;
    text-align: center;
  }
  .trades-label-selected {
    color: #007aff;
    font-weight: bold;
  }
  .h-center {
    display: flex;
    justify-content: center;
  }
  .v-center {
    display: flex;
    align-items: center;
  }
  .title-reactive-fix {
    position: relative;
    left: 50% !important;
    transform: translateX(-50%);
  }
  .list {
    margin: 0;
  }
  .card-footer {
    min-height: 32px;
    padding: 4px 15px;
    font-size: 12px;
  }
  .info-title {
    font-size: 13px;
    color: #999;
  }
  .info-date {
    color: #333;
  }
  .info-data {
    font-size: 20px;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info-data__strong {
    font-size: 16px;
  }
  .info-data__secondary {
    font-size: 12px;
    color: #999;
  }
  .info-data-asof {
    font-size: 12px;
    color: #999;
  }
  .rationale-checkbox {
    margin-left: 15px;
  }
}
.trade-confirm {
  align-items: center;

  .trade-confirm-btn {
    font-size: 20px;
    border: 0;
  }
}
</style>
