<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          v-if="isAdmin"
          icon-only
          :icon-ios="isOnBehalfOf ? 'f7:person_fill' : 'f7:person'"
          icon-md="material:person"
          @click="switchUser()"
        />
      </f7-nav-left>
      <f7-nav-title>{{ navTitle }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          @click="openSearch('mainSearch')"
          icon-ios="f7:search_strong"
          icon-md="material:search"
        />
        <f7-link v-if="listViewActive" @click="showListFilterButtons">
          <span class="fa fa-lg fa-filter" />
        </f7-link>
      </f7-nav-right>
      <SearchBar ref="search" />
    </f7-navbar>

    <template v-if="searchIsActive">
      <SearchResults />
    </template>
    <template v-else>
      <f7-tabs routable>
        <f7-tab id="tabEvents" />
        <f7-tab id="tabLists" />
        <f7-tab id="tabTrade" />
        <f7-tab id="tabMenu" />
      </f7-tabs>
      <f7-toolbar tabbar labels>
        <f7-link
          tab-link
          route-tab-id="tabEvents"
          href="/events/0"
          text="Events"
          icon-ios="f7:drawer_fill"
          icon-md="material:email"
        />
        <f7-link
          tab-link
          v-if="canTrade"
          text="Trade"
          @click="openSearch('tradeSearch')"
          icon-ios="material:swap_horiz"
          icon-md="material:swap_horiz"
        />
        <f7-link
          tab-link
          route-tab-id="tabLists"
          v-if="!canTrade"
          @click="goToDefaultlist()"
          text="Lists"
          icon-ios="f7:list"
          icon-md="material:today"
        />
        <f7-link
          tab-link
          route-tab-id="tabMenu"
          href="/menu/"
          text="Menu"
          icon-ios="f7:menu"
          icon-md="material:menu"
        />
      </f7-toolbar>
    </template>
  </f7-page>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import SearchBar from '../components/search/searchBar.vue'
import SearchResults from '../components/search/searchResults.vue'
//import auth from '../auth'

export default {
  components: {
    SearchBar,
    SearchResults
  },
  data() {
    return {
      popupOpened: false,
      initialized: false
    }
  },
  computed: {
    ...mapState(['navTitle']),
    ...mapState('search', ['searchIsActive']),
    ...mapState('user', ['currentUser']),
    ...mapState('trade', ['issuerDimId']),
    ...mapGetters('lists', ['defaultList']),
    ...mapGetters('user', ['currentOrOnBehalfOfUser', 'canTrade', 'isAdmin']),
    ...mapState('user', ['isOnBehalfOf', 'viewType']),
    /*isAuthenticated() {
      return auth.isAuthenticated()
    },*/
    listViewActive() {
      return this.$f7route.url.indexOf('lists') !== -1
    }
  },
  methods: {
    // ...mapMutations('user', ['toggleViewType']),
    ...mapMutations('search', ['showTradeSearch', 'setPreviousLocation']),
    ...mapMutations('lists', ['setMostRecentList', 'showListFilterButtons']),
    logout() {
      /*if (auth.isAuthenticated()) {
        auth.signOut()
      } else {
        auth.signIn()
      }*/
    },
    goToDefaultlist() {
      const { shortName, route } = this.defaultList
      this.$f7router.navigate(route, { reloadAll: true })
      this.setMostRecentList(shortName)
    },
    switchUser() {
      this.$f7router.navigate('/user')
    },
    openSearch(location) {
      this.setPreviousLocation(location)
      location === 'tradeSearch' ? this.showTradeSearch(true) : this.showTradeSearch(false)
      this.$f7.searchbar.enable()
    }
  },
  mounted() {
    this.$forceUpdate()
  }
}
</script>

<style lang="scss">
.unfiltered {
  color: #2f4451 !important;
}
.view-type-popup {
  .prompt {
    font-size: 20px;
  }
  .btn-grp {
    display: flex;
    justify-content: space-between;

    button {
      width: 160px;
      color: white;
      background: #0a1e47;
    }
  }
}
.view-type-popup > div {
  margin-top: 10px;
}
</style>
