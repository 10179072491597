<template>
  <div class="portfolio-list-wrapper">
    <div>
      <div v-if="!valuesAvailable">
        <f7-block>No results match the applied filters, please try again.</f7-block>
      </div>
      <!-- List Wrapper -->
      <div v-if="valuesAvailable">
        <!-- List Header -->
        <f7-list style="background: #efeff4; color: #8e8e93; font-weight: 600; line-height: 14px; font-size: 14px; height: 60px;border-bottom:1px solid black;">
          <f7-list-item-row style="background: #efeff4;height: 60px;">
            <f7-list-item-cell class="name-cell" style="padding-left: 10px;">
              <div @click="sortList('name')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('name')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('name')" color="blue" />
                <span>COMPANY</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('portfolioPercentage')" class="data-cell-header">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('portfolioPercentage')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('portfolioPercentage')" color="blue" />
                <span>WT</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('benchmarkWeight')" class="data-cell-header">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('benchmarkWeight')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('benchmarkWeight')" color="blue" />
                <span>BMK</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('compositeScore')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('compositeScore')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('compositeScore')" color="blue" />
                <span>C</span>
              </div>
            </f7-list-item-cell> 
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('qualityScore')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('qualityScore')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('qualityScore')" color="blue" />
                <span>Q</span>
              </div>
            </f7-list-item-cell>
            <!--<f7-list-item-cell class="item-cell">
              <div @click="sortList('valueScore')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('valueScore')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('valueScore')" color="blue" />
                <span>V</span>
              </div>
            </f7-list-item-cell>-->
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('etrendScore')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('etrendScore')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('etrendScore')" color="blue" />
                <span>E</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('momentumScore')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('momentumScore')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('momentumScore')" color="blue" />
                <span>M</span>
              </div>
            </f7-list-item-cell>
          </f7-list-item-row>
        </f7-list>
        <!-- List Data -->
        <div :style="listDataStyles">
          <f7-list virtual-list 
                   v-for="(issuers, groupBy) in sortedListItems" 
                   :key="groupBy" style="font-size: 14px;margin-bottom:0;font-weight:600;">
            <f7-list-item-row style="background: #efeff4;height:60px;font-size:14px;height: 50px;" v-if="groupBy !== 'allItems'">
              <f7-list-item-cell class="name-cell" style="padding-left:10px;">{{ groupBy | shortName }} ({{ issuers.length }})</f7-list-item-cell>
              <f7-list-item-cell>{{ portfolioWeightAggregates[groupBy] | percentage(2) }}</f7-list-item-cell>
              <f7-list-item-cell>{{ benchmarkWeightAggregates[groupBy] }}</f7-list-item-cell>
              <f7-list-item-cell>{{ portfolioCompositeAggregates[groupBy] | round(0) }}</f7-list-item-cell>
              <f7-list-item-cell>{{ portfolioQualityAggregates[groupBy] | round(0) }}</f7-list-item-cell>
              <!--<f7-list-item-cell>{{ portfolioValuationAggregates[groupBy] | round(0) }}</f7-list-item-cell>-->
              <f7-list-item-cell>{{ portfolioEtrendAggregates[groupBy] | round(0) }}</f7-list-item-cell>
              <f7-list-item-cell>{{ portfolioMomentumAggregates[groupBy] | round(0) }}</f7-list-item-cell>
            </f7-list-item-row>
            <ul>
              <f7-list-item-row
                v-for="(issuer, index) in issuers"
                :key="index"
                style="border-bottom: 1px solid #efeff4">
                <f7-list-item-cell class="name-data-cell"><a :href="`/company/${issuer.issuerId}/info`">{{ issuer.name || issuer.securityName | lowerCase }}</a></f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.portfolioPercentage | percentage(2) }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.benchmarkWeight | percentage(2) }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.compositeScore }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.qualityScore }}</f7-list-item-cell>
                <!--<f7-list-item-cell>{{ issuer.valueScore }}</f7-list-item-cell>-->
                <f7-list-item-cell>{{ issuer.etrendScore }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.momentumScore }}</f7-list-item-cell>
              </f7-list-item-row>
            </ul>
          </f7-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listMixin from '../../../mixins/listMixin'
import { mapState } from 'vuex'

export default {
  name: 'Portfolio',
  mixins: [listMixin],
  data() {
    return {
      sortedColumn: 'compositeScore',
      sortDirectionIsAscending: true
    }
  },
  computed: {
    ...mapState('lists', ['benchmarkWeights']),
    portfolioWeightAggregates() {
      const allWeights = {}
      for (let key in this.listItems) {
        const groupedIssuers = this.listItems[key]
        allWeights[key] = groupedIssuers.reduce((total, { portfolioPercentage }) => {
          return total + portfolioPercentage
        }, 0)
      }
      return allWeights
    },
    benchmarkWeightAggregates() {
      return this.benchmarkWeights
    },
    portfolioCompositeAggregates() {
      return this.aggregateScoresByType('compositeScore')
    },
    portfolioQualityAggregates() {
      return this.aggregateScoresByType('qualityScore')
    },
    /*portfolioValuationAggregates() {
      return this.aggregateScoresByType('valueScore')
    }*/
    portfolioEtrendAggregates() {
      return this.aggregateScoresByType('etrendScore')
    },
    portfolioMomentumAggregates() {
      return this.aggregateScoresByType('momentumScore')
    }
  },
  methods: {
    aggregateScoresByType(property) {
      const aggregatedScores = {}

      for (let key in this.listItems) {
        const groupedIssuers = this.listItems[key]
        const totalWeight = this.portfolioWeightAggregates[key]
        const weightedTotal = groupedIssuers.reduce((total, issuer) => {
          const { portfolioPercentage = 0 } = issuer
          const propertyValue = issuer[property] || 0
          return total + (portfolioPercentage / totalWeight) * propertyValue
        }, 0)

        aggregatedScores[key] = weightedTotal
      }

      return aggregatedScores
    }
  }
}
</script>

<style lang="scss">
.portfolio-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .data-cell-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .list .item-row {
    height: 45px;
    width: 98vw;
  }
  .item-cell {
    text-align: center;
    max-width: 17vw;
    min-width: 17vw;
    &.name-cell,
    &.name-data-cell {
      text-align: left;
      max-width: 28vw;
      min-width: 28vw;
    }
    &.name-data-cell {
      text-transform: capitalize;
      color: #1a86fe;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 10px;
    }
  }
}
</style>
