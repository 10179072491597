<template>
  <f7-page class="search-list">
    <f7-preloader :size="42" v-if="!activeSearchComplete" style="margin-top: 10vh;" />
    <f7-list v-if="activeSearchComplete && !searchIsTrade">
      <f7-list-item
        style="background:#fff;"
        v-for="result in formattedSearchResults"
        v-if="
          formattedSearchResults.length && searchRequestComplete && !searchIsTrade && !errorInSearch
        "
        :key="result.objectId"
        :external="true"
      >
        <div
          v-if="!canTrade || !result.onEligibilityList"
          slot="header"
          style="width: 94vw;margin-left:15px;"
        >
          <a :href="`/company/${result.id}/info`" @click="clearAndCloseSearch(result.id)">{{
            result.symbol
          }}</a>
        </div>
        <div
          v-if="!canTrade || !result.onEligibilityList"
          slot="title"
          class="text-truncate company-name"
        >
          <a :href="`/company/${result.id}/info`" @click="clearAndCloseSearch(result.id)">{{
            result.name
          }}</a>
        </div>
        <div
          v-if="canTrade && result.onEligibilityList"
          slot="header"
          style="width: 90vw;margin-left:15px;"
        >
          <a :href="`/company/${result.id}/info`" @click="clearAndCloseSearch(result.id)">{{
            result.symbol
          }}</a>
        </div>
        <div
          v-if="canTrade && result.onEligibilityList"
          slot="title"
          style="margin-left:15px;"
          class="text-truncate company-name"
        >
          <a :href="`/company/${result.id}/info`" @click="clearAndCloseSearch(result.id)">{{
            result.name
          }}</a>
        </div>
        <div
          v-if="canTrade && (viewType.isGlobal === false || result.isOnEligListInAnyEnv === true)"
          slot="after-title"
          style="width: 6vw;margin-right:15px;"
          @click="openTradeInit(result)"
        >
          <f7-link no-link-class><span class="fa fa-lg fa-exchange init-trade"/></f7-link>
        </div>
      </f7-list-item>
      <f7-list-item
        v-if="!formattedSearchResults.length && searchRequestComplete && !errorInSearch"
        title="No search results found"
      />
      <f7-list-item v-if="errorInSearch" slot="title" style="width: 94vw;">{{
        result.message
      }}</f7-list-item>
    </f7-list>
    <f7-list v-if="activeSearchComplete && searchIsTrade" style="width: 98vw;">
      <f7-list-button
        v-if="formattedSearchResults.length && searchRequestComplete && searchIsTrade"
        style="margin-left:15px;"
        title="Open trade initiation for:"
        color="black"
      />
    </f7-list>
    <f7-list
      v-if="activeSearchComplete && searchIsTrade"
      style="height: 90vh;overflow-y: auto;width: 98vw;"
    >
      <f7-list-button
        style="background:#fff;"
        class="text-truncate company-name"
        v-for="result in formattedSearchResultsEligibleForTrade"
        v-if="!errorInSearch && formattedSearchResultsEligibleForTrade.length"
        :key="result.objectId"
        :title="result.name"
        @click="openTradeInit(result)"
      />
      <f7-list-item
        v-if="
          !formattedSearchResultsEligibleForTrade.length && !errorInSearch && searchRequestComplete
        "
        title="No search results found"
      />
      <f7-list-item v-if="errorInSearch" slot="title" style="width: 94vw;">{{
        result.message
      }}</f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'SearchResults',
  computed: {
    ...mapState('search', [
      'searchIsActive',
      'searchRequestComplete',
      'searchIsTrade',
      'previousLocation'
    ]),
    ...mapState('user', ['viewType']),
    ...mapGetters('search', ['formattedSearchResults']),
    ...mapGetters('user', ['canTrade']),
    searchBar() {
      return this.$parent.$parent.$parent.$refs.search
    },
    activeSearchComplete() {
      return this.searchBar.queryStringEntered ? this.searchRequestComplete : true
    },
    errorInSearch() {
      const results = this.searchIsTrade
        ? this.formattedSearchResultsEligibleForTrade
        : this.formattedSearchResults
      return this.searchRequestComplete && results.length === 1 && results[0].error
    },
    formattedSearchResultsEligibleForTrade() {
      return this.formattedSearchResults
      // the onEligibilityList prop was not present on the search items so
      // removing this filter until the prop can be added to the results from the API
      /*return this.formattedSearchResults.filter(
        ({ onEligibilityList, error = null }) => onEligibilityList || error
      )*/
    }
  },
  methods: {
    ...mapActions('trade', ['loadIssuer']),
    ...mapMutations('search', {
      clearSearchResults: 'saveSearchResults',
      showSearchWindow: 'showSearchWindow',
      completeSearchRequest: 'completeSearchRequest',
      setSelectedItemId: 'setSelectedItemId'
    }),
    openTradeInit({ id }) {
      this.clearAndCloseSearch(id)
      this.loadIssuer(id).then(() => this.$f7router.navigate(`/trade/${id}`))
    },
    clearAndCloseSearch(id) {
      this.setSelectedItemId(id)
      this.$parent.$f7.searchbar.disable()
    }
  }
}
</script>

<style lang="scss">
.search-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-truncate {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .company-name {
    text-transform: capitalize;
    color: #1a86fe;
    margin-left: 15px;
  }
  .init-trade {
    transform: rotate(-45deg);
  }
}
</style>
