import Home from './views/Home.vue'
import EventPage from './views/HomeTabs/Events.vue'
import ListsPage from './views/HomeTabs/Lists.vue'
import Trade from './views/Trade.vue'
import User from './views/User.vue'
import ErrorPage from './views/Error.vue'
import MenuPage from './views/HomeTabs/Menu.vue'
import CompanyPage from './views/Company.vue'
import CompanyInfo from './views/CompanyTabs/CompanyInfo.vue'
//import CompanyMarketData from './views/CompanyTabs/CompanyMarketData.vue'
import CompanyRecReport from './views/CompanyTabs/CompanyRecReport.vue'
import DbfScorecardComment from './views/CompanyTabs/DbfScorecardComment.vue'
import CompanyEvents from './views/CompanyTabs/CompanyEvents.vue'
import ListFilterMenu from './components/lists/ListFilterMenu.vue'

export default [
  {
    path: '/',
    component: Home,
    tabs: [
      { path: '/events/:category', id: 'tabEvents', component: EventPage },
      { path: '/lists/:listType/:listId', id: 'tabLists', component: ListsPage },
      { path: '/menu', id: 'tabMenu', component: MenuPage }
    ],
    options: {
      animate: false
    }
  },
  {
    path: '/company/:issuerId/',
    component: CompanyPage,
    tabs: [
      { path: '/info', id: 'companyInfo', component: CompanyInfo },
      {
        path: '/recReport',
        id: 'companyRecReport',
        component: CompanyRecReport,
        options: {
          props: {}
        }
      },
      { path: '/events', id: 'companyEvents', component: CompanyEvents }
    ]
  },
  { path: '/trade/:issuerId', popup: { component: Trade } },
  { path: '/user', popup: { component: User } },
  { path: '/dbfscorecard/comment/:component/:comment', popup: { component: DbfScorecardComment } },
  { path: '/error', component: ErrorPage },
  // Right Panel
  {
    path: '/listFilter',
    component: ListFilterMenu
  }
]
