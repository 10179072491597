<template>
  <f7-page class="menu-page" tab>
    <f7-block-title>Settings</f7-block-title>
    <f7-list>
      <f7-list-item @click="switchViewType">Switch to {{ viewTypeToggleDisplayName }} view</f7-list-item>
    </f7-list>
    <f7-block-title>Events</f7-block-title>
    <f7-list v-if="isPmOrPmAnalyst">
      <f7-list-item @click="goToEvent('all')">All Activity</f7-list-item>
      <f7-list-item @click="goToEvent('my-portfolios')">My Portfolios</f7-list-item>
      <f7-list-item @click="goToEvent('trading-desk')">Trading Desk Commentary</f7-list-item>
      <f7-list-item @click="goToEvent('rec-updates')">All Recommendation Updates</f7-list-item>
      <f7-list-item @click="goToEvent('trade-init')">All Trade Initations</f7-list-item>
      <f7-list-item @click="showAllEventsTypes">More...</f7-list-item>
    </f7-list>
    <f7-list v-if="!isPmOrPmAnalyst">
      <f7-list-item @click="goToEvent('all')">All Activity</f7-list-item>
      <f7-list-item @click="goToEvent('trading-desk')">Trading Desk Commentary</f7-list-item>
      <f7-list-item @click="goToEvent('rec-updates')">All Recommendation Updates</f7-list-item>
      <f7-list-item @click="goToEvent('trade-init')">All Trade Initations</f7-list-item>
      <f7-list-item @click="showAllEventsTypes">More...</f7-list-item>
    </f7-list>
    <f7-block-title>List</f7-block-title>
    <f7-list>
      <f7-list-item
        v-if="userRole === 'analyst' || userRole === 'pm_analyst'"
        @click="goTolist('eligibility', 9, 'My Elig List')"
      >My Eligibility List</f7-list-item>
      <f7-list-item @click="goTolist('eligibility', 3, 'Elig List')">Eligibility List</f7-list-item>
      <f7-list-item @click="goTolist('agenda', 14, 'Research Agenda')">Research Agenda</f7-list-item>
    </f7-list>
    <f7-block-title>Portfolios</f7-block-title>
    <f7-list>
      <f7-list-item
        v-for="portfolio in expandedPortfoliosList"
        :key="portfolio.id"
        @click="goTolist('portfolio', portfolio.id, portfolio.shortName)"
      >{{ portfolio.description }}</f7-list-item>
      <f7-list-item
        v-if="!expandPortfoliosList"
        @click="expandPortfoliosList = !expandPortfoliosList"
      >More...</f7-list-item>
    </f7-list>
    <f7-block />
    <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false">
      <f7-page>
        <f7-navbar title="All Events">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list v-if="isPmOrPmAnalyst">
          <f7-list-item popup-close link @click="goToEvent('all')">All Activity</f7-list-item>
          <f7-list-item popup-close link @click="goToEvent('my-portfolios')">My Portfolios</f7-list-item>
          <f7-list-item popup-close link @click="goToEvent('trading-desk')">Trading Desk Commentary</f7-list-item>
          <f7-list-item
            popup-close
            link
            @click="goToEvent('rec-updates')"
          >All Recommendation Updates</f7-list-item>
          <f7-list-item popup-close link @click="goToEvent('trade-init')">All Trade Initations</f7-list-item>
          <f7-list-item
            popup-close
            link
            @click="goToEvent('quant')"
          >Quant, Econ &amp; Strategy Commentary</f7-list-item>
        </f7-list>
        <f7-list v-if="!isPmOrPmAnalyst">
          <f7-list-item popup-close link @click="goToEvent('all')">All Activity</f7-list-item>
          <f7-list-item popup-close link @click="goToEvent('trading-desk')">Trading Desk Commentary</f7-list-item>
          <f7-list-item
            popup-close
            link
            @click="goToEvent('rec-updates')"
          >All Recommendation Updates</f7-list-item>
          <f7-list-item popup-close link @click="goToEvent('trade-init')">All Trade Initations</f7-list-item>
          <f7-list-item
            popup-close
            link
            @click="goToEvent('quant')"
          >Quant, Econ &amp; Strategy Commentary</f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import get from 'lodash/get'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {
      popupOpened: false,
      expandPortfoliosList: false
    }
  },
  created() {
    this.setTitle('Menu')
  },
  computed: {
    ...mapState('user', ['viewType']),
    ...mapGetters('user', [
      'currentOrOnBehalfOfUser',
      'canTrade',
      'viewTypeToggleButtonDisplayName'
    ]),
    ...mapGetters('lists', ['userPortfolios']),
    userRole() {
      const currentUserRole = get(this.currentOrOnBehalfOfUser, 'userRole.code', '')
      return currentUserRole.toLowerCase()
    },
    expandedPortfoliosList() {
      return this.expandPortfoliosList ? this.userPortfolios : this.userPortfolios.slice(0, 5)
    },
    isPmOrPmAnalyst() {
      return get(this.currentOrOnBehalfOfUser, 'isPmOrPmAnalyst', false)
    },
    viewTypeToggleDisplayName() {
      return this.viewType && !this.viewType.isGlobal ? 'Global' : 'Domestic'
    }
  },
  methods: {
    ...mapMutations(['setTitle']),
    ...mapMutations('lists', ['setMostRecentList', 'setEligListItems']),
    ...mapActions('lists', ['getAllLists', 'getEligibilityList']),
    ...mapActions('user', ['resetOBU']),
    ...mapMutations('user', ['toggleViewType']),
    ...mapMutations('events', ['setActiveEventsTab']),
    showAllEventsTypes() {
      this.popupOpened = true
    },
    async switchViewType() {
      this.toggleViewType()
      this.resetOBU()
      await this.getAllLists()
      // Resetting the elig list items to prevent the user from
      // seeing list data for the opposite environment before
      // data is returned for the current environment
      this.setEligListItems([])
      this.getEligibilityList()
      this.$f7router.navigate(`/events/0`)
    },
    goToEvent(category) {
      this.setActiveEventsTab(category)
      this.$f7router.navigate(`/events/${category}`)
    },
    goTolist(listType, listId, title) {
      this.$f7router.navigate(`/lists/${listType}/${listId}`)
      this.setMostRecentList(title)
    }
  }
}
</script>

<style lang="scss">
.page.menu-page {
  .block-title {
    margin-top: 20px;
  }
  .list {
    font-size: 16px;
    margin: 8px 0 18px 0;
  }
  .item-content,
  .item-content .item-inner {
    min-height: 36px;
  }
}
</style>
