<template>
  <f7-page class="list-view">
    <f7-preloader :size="42" v-if="!listDataReady" style="margin-top: 10vh;"/>
    <div v-if="listDataReady" class="list-wrapper">
      <EligList v-if="isEligibility"/>
      <Portfolio v-if="isPortfolio"/>
      <Agenda v-if="isAgenda"/>
      <MyEligList v-if="isMyEligibility"/>
    </div>
    <div :style="listFilterMenuStyles">
      <f7-fab position="left-bottom" slot="fixed" color="blue" text="Filters" @click="popupOpened = true">
        <f7-icon size="14" ios="f7:add" md="material:add"/>
      </f7-fab>
      <f7-fab position="right-bottom" slot="fixed" color="blue" text="Groups">
        <f7-icon size="14" ios="f7:add" md="material:add"/>
        <f7-icon ios="f7:close" md="material:close"/>
        <f7-fab-buttons position="top">
          <f7-fab-button fab-close :style="fabButtonStyles(null)" @click="setGroupBy(null)">None</f7-fab-button>
          <f7-fab-button fab-close v-if="listType === 'agenda'" :style="fabButtonStyles('agendaType')" @click="setGroupBy('agendaType')">Agenda</f7-fab-button>
          <f7-fab-button fab-close :style="fabButtonStyles('sector')" @click="setGroupBy('sector')">Sector</f7-fab-button>
          <f7-fab-button fab-close :style="fabButtonStyles('region')" @click="setGroupBy('region')">Region</f7-fab-button>
          <f7-fab-button fab-close :style="fabButtonStyles('industryGroup')" @click="setGroupBy('industryGroup')">Ind. Group</f7-fab-button>
          <f7-fab-button fab-close :style="fabButtonStyles('industry')" @click="setGroupBy('industry')">Industry</f7-fab-button>
          <f7-fab-button fab-close :style="fabButtonStyles('country')" @click="setGroupBy('country')">Country</f7-fab-button>
        </f7-fab-buttons>
      </f7-fab>
    </div>
    <f7-popup class="demo-popup" :opened="popupOpened">
      <f7-page>
        <f7-navbar title="List Filters">
          <f7-nav-right>
            <f7-link @click="popupOpened = false">Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <ListFilterMenu/>
      </f7-page>
    </f7-popup>
  </f7-page>  
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import EligList from '../../components/lists/listTypes/EligList.vue'
import Portfolio from '../../components/lists/listTypes/Portfolio.vue'
import Agenda from '../../components/lists/listTypes/Agenda.vue'
import MyEligList from '../../components/lists/listTypes/MyEligList.vue'
import ListFilterMenu from '../../components/lists/ListFilterMenu.vue'

export default {
  props: {
    listId: { type: [String, Number], required: true },
    listType: { type: [String], required: true }
  },
  components: {
    EligList,
    Portfolio,
    Agenda,
    MyEligList,
    ListFilterMenu
  },
  data() {
    return {
      popupOpened: false
    }
  },
  computed: {
    ...mapState('lists', [
      'listRequestComplete',
      'eligListSelected',
      'mostRecentList',
      'eligListItems',
      'groupBy',
      'listFilterButtonsVisible'
    ]),
    listDataReady() {
      return this.eligListSelected ? this.eligListItems.length > 0 : this.listRequestComplete
    },
    isAgenda() {
      return this.listType === 'agenda'
    },
    isPortfolio() {
      return this.listType === 'portfolio'
    },
    isEligibility() {
      return this.listType === 'eligibility' && this.listId === '3'
    },
    isMyEligibility() {
      return this.listType === 'eligibility' && this.listId === '9'
    },
    listFilterMenuStyles() {
      const style = {
        opacity: '0',
        '-webkit-animation': 'fadein 3s' /* Safari, Chrome and Opera > 12.1 */,
        '-moz-animation': 'fadein 3s' /* Firefox < 16 */,
        '-ms-animation': 'fadein 3s' /* Internet Explorer */,
        '-o-animation': 'fadein 3s' /* Opera < 12.1 */,
        animation: 'fadein 3s',
        'animation-delay': '5s',
        'animation-duration': '6s'
      }

      if (this.listFilterButtonsVisible) {
        style.opacity = '1'
      }

      return style
    }
  },
  methods: {
    ...mapMutations(['setTitle']),
    ...mapMutations('lists', {
      clearIndustryFilters: 'setIndustryFilters',
      clearRegionFilters: 'setRegionFilters',
      clearSectorFilters: 'setSectorFilters',
      clearListItems: 'setListItems',
      selectEligList: 'selectEligList',
      setGroupBy: 'setGroupBy',
      hideListFilterButtons: 'showListFilterButtons'
    }),
    ...mapActions('lists', ['getListItems', 'getEligibilityList']),
    async fetchEligListItems() {
      try {
        await this.getEligibilityList()
      } catch (e) {
        const message = 'There was an error fetching list data, please try again.'
        this.$f7router.navigate('/error', { props: { message } })
      }
    },
    async fetchListItems() {
      // Fetch list data if we're not loading the elig list
      // Using non-strict equivalence on listId because the router converts it to a string sometimes
      if (this.listType !== 'eligibility' || this.listId != 3) {
        this.selectEligList(false)
        try {
          await this.getListItems({ listType: this.listType, listId: this.listId })
        } catch (e) {
          const message = 'There was an error fetching list data, please try again.'
          this.$f7router.navigate('/error', { props: { message } })
        }
        return
      }
      this.selectEligList(true)
      // If the call to fetch elig list data failed on app mount, make sure to fetch it now
      if (!this.eligListItems.length) {
        await this.fetchEligListItems()
      }
    },
    fabButtonStyles(group) {
      const styles = {
        'font-size': '11px',
        'text-align': 'center'
      }
      if (group === this.groupBy) {
        styles.background = '#00d5ff'
      }
      return styles
    },
    resetFilters() {
      this.clearIndustryFilters([])
      this.clearRegionFilters([])
      this.clearSectorFilters([])
    }
  },
  beforeDestroy() {
    this.resetFilters()
    this.clearListItems([])
    if (this.listFilterButtonsVisible) {
      this.hideListFilterButtons()
    }
  },
  async beforeMount() {
    this.setTitle(this.mostRecentList)
    await this.fetchListItems()
    this.$f7router.clearPreviousHistory()
  }
}
</script>

<style>
.list-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-view .fab div.fab-buttons a {
  width: 100px;
}

.list-view .fab-buttons-top {
  margin-left: -50px;
}
</style>
