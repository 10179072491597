<template>
  <f7-page tab :page-content="false">
    <f7-subnavbar>
      <f7-toolbar style="position:absolute; top:44px" class="tabbar tabbar-scrollable">
        <f7-swiper pagination :params="{ slidesPerView: 1, watchSlidesProgress: true, watchSlidesVisibility: true }">
          <f7-swiper-slide class="first">
            <f7-link :key="cat.id" 
                     v-for="(cat, index) in visibleCategories"
                     v-show="index < 4"  
                     :tab-link="'#' + cat.name" 
                     :tab-link-active="activeEventsTab === cat.name" 
                     :no-fast-click="true"
                     @click="setActiveEventsTab(cat.name)">
              {{ cat.display }}
            </f7-link>  
          </f7-swiper-slide>
          <f7-swiper-slide class="second">
            <f7-link :key="cat.id" 
                     v-for="(cat, index) in visibleCategories" 
                     v-show="index >= 4" 
                     :tab-link="'#' + cat.name" 
                     :tab-link-active="activeEventsTab === cat.name" 
                     :no-fast-click="true"
                     @click="setActiveEventsTab(cat.name)">
              {{ cat.display }}
            </f7-link>    
          </f7-swiper-slide>
        </f7-swiper>
      </f7-toolbar>
    </f7-subnavbar>
    <f7-tabs>
      <f7-tab :id="cat.name" 
              :tab-active="cat.name === activeEventsTab" 
              v-for="cat in visibleCategories" 
              :key="cat.id">
        <event-list-template :category="cat"/>
      </f7-tab>  
    </f7-tabs>
  </f7-page>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import EventListTemplate from '../../components/events/EventListTemplate.vue'

export default {
  props: {
    category: { type: [String], required: true }
  },
  components: {
    EventListTemplate
  },
  created() {
    this.setTitle('Events')
  },
  methods: {
    ...mapMutations(['setTitle']),
    ...mapMutations('events', ['setActiveEventsTab'])
  },
  mounted() {
    if (this.$ua) {
      this.$ua.trackView('Events Page')
    }
  },
  computed: {
    ...mapState('events', ['activeEventsTab']),
    ...mapGetters('events', ['mainCategories', 'showMyPortfolios']),
    ...mapGetters('user', ['currentOrOnBehalfOfUser']),
    visibleCategories() {
      if (this.showMyPortfolios) {
        return this.mainCategories
      }
      return this.mainCategories.filter(({ name }) => name !== 'my-portfolios')
    },
    activeTabIndex() {
      return this.visibleCategories.findIndex(({ name }) => name === this.activeEventsTab)
    }
  },
  watch: {
    currentOrOnBehalfOfUser() {
      this.setActiveEventsTab('all')
      this.$f7.tab.show('#all')
    },
    activeTabIndex(newValue, oldValue = 0) {
      // Tracks the event slider menu pagination with the selected tab
      const swiper = this.$f7.swiper.get('.swiper-container')

      if (newValue >= 4 && oldValue < 4) {
        swiper.slideNext()
        swiper.update()
      } else if (oldValue >= 4 && newValue < 4) {
        swiper.slideReset()
      }
    }
  }
}
</script>

<style lang="scss">
.swiper-container {
  width: 100%;
}
.swiper-slide {
  display: inline-flex;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 23px;
  height: 0;
}
.first {
  justify-content: space-between;
}
.second {
  justify-content: flex-start;
}
</style>
