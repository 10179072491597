<template>
  <div>
    <div style="display:inline-flex">
      <div class="event-symbol">
        <span class="fa fa-lg fa-warning" />
      </div>
      <div>
        <div>
          <span v-if="model.entities[0].rating.code === 'OUTPERFORM'" class="event-indicator"
            >O</span
          >
          <span v-if="model.entities[0].onLeadersList" class="fa fa-trophy trophy" />
          <span @click="onClickCompany(model.entities[0])" class="lnk">{{
            model.entities[0].name
          }}</span>
        </div>
        <div>
          <span class="title">{{ model.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
  name: 'ListMembershipStale',
  mixins: [eventPopupMixin],
  components: {
    EventPopup
  },
  data() {
    return {}
  },
  computed: {
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.model.createdBy.fullName,
        date: this.model.eventDate
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
