<template>
  <f7-searchbar class="searchbar" expandable :form="true" :custom-search="true" @searchbar:enable="showSearchWindow(true)" @searchbar:disable="closeSearch()" @blur="checkIfSearching" placeholder="Search issuers" @searchbar:search="onSearch" @searchbar:clear="onClear"/>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'SearchBar',
  data() {
    return {
      queryString: '',
      searchCanceled: true
    }
  },
  computed: {
    ...mapState('search', ['searchIsActive', 'selectedItemId']),
    queryStringEntered() {
      return this.queryString.length > 0
    }
  },
  methods: {
    ...mapActions('search', { searchIssuers: 'searchIssuers' }),
    ...mapMutations('search', {
      clearSearchResults: 'saveSearchResults',
      showSearchWindow: 'showSearchWindow',
      completeSearchRequest: 'completeSearchRequest',
      showTradeSearch: 'showTradeSearch',
      setSelectedItemId: 'setSelectedItemId'
    }),
    checkIfSearching() {
      this.showSearchWindow(this.queryStringEntered)
    },
    onSearch: debounce(function(event, inputText) {
      if (inputText.length) {
        this.queryString = inputText
        this.searchIssuers({ searchText: inputText, entityTypes: ['ISSUER'] })
      }
    }, 500),
    onClear() {
      this.queryString = ''
      this.clearSearchResults([])
      this.completeSearchRequest(false)
    },
    closeSearch() {
      this.onClear()
      this.showSearchWindow(false)
      this.showTradeSearch(false)

      if (!this.selectedItemId) {
        this.$f7router.refreshPage()
      }
      this.setSelectedItemId(null)
    }
  }
}
</script>
