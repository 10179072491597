<template>
  <div style="display:flex;flex-direction:column;height:88vh;">
    <f7-preloader :size="42" v-if="showSpinner" style="align-self:center;margin-top: 10vh;height:3vh;"/>
    <div style="width: 90%;align-self:center;height:3vh;padding:10px;" v-if="!showSpinner">
      <f7-segmented raised round v-if="recReportPdfURL">
        <f7-button :active="!showRecReportPDF" :disabled="showRecReportPDF && !pdfLoaded" round outline @click="toggleRecReportPDFView(false)" style="width: 50%">Investment Thesis</f7-button>
        <f7-button :active="showRecReportPDF" outline round @click="loadPDF" style="width: 50%">Full Report</f7-button>
      </f7-segmented>
    </div>
    <div style="padding: 10px;padding-bottom:50px;overflow-y:auto;height:80vh;">
      <div v-if="!showRecReportPDF">
        <p class="rec-report-section-sub-title" v-if="thesisHasText">{{ lastModified }}</p>
        <p class="rec-report-section-text" v-if="thesisHasText" v-html="formattedInvestmentThesis"/>
        <p class="rec-report-section-text" v-if="recReportRequestComplete && !thesisHasText">There is no Investment Thesis associated with this issuer.</p>
      </div>
      <div v-if="showRecReportPDF && recReportPdfURL" style="padding: 10px;padding-bottom:50px;overflow-y:auto;height:80vh;">
        <pdf :src="recReportPdfURL" 
             @num-pages="getNumberOfPages($event)" 
             v-for="i in numberOfPDFPages"
             :key="i"
             :page="i"
             @loaded="pdfLoaded = i === numberOfPDFPages"/>
      </div>
    </div>
    <div v-if="recReportRequestComplete && showNoRecReportAvailable" class="rec-report-no-report">
      <h4>There is no Recommendation Report associated with this issuer.</h4>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import get from 'lodash/get'
import pdf from 'vue-pdf'
import util from '../../util'
import RecReportSection from './RecReportSection.vue'
import PeerGroupSection from './PeerGroupSection.vue'
import EstimateSummarySection from './EstimateSummarySection.vue'
import DbfScorecardSection from './DBFScorecardSection.vue'
import RecReportApi from '../../apis/companyRecReport'
import CompanyInfoApi from '../../apis/companyInfo'
import $http from '../../apis/apiClient'

export default {
  components: {
    RecReportSection,
    PeerGroupSection,
    EstimateSummarySection,
    DbfScorecardSection,
    pdf
  },
  props: {
    issuerId: {
      type: [String, Number],
      required: true
    },
    eventEnvironment: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      errorState: false,
      recReportURL: null,
      recReportBlob: null,
      globalInvestmentThesis: '',
      globalInvestmentThesisDate: '',
      numberOfPDFPages: 1,
      pdfLoaded: false
    }
  },
  async beforeMount() {
    this.setRecReportRequestStatus(false)
    const id = this.companyInfo.issuerDimId || this.issuerId

    if (this.eventEnvironment && this.eventEnvironment === 'domestic') {
      $http.addSingleRequestCustomHeaderSet(`/issuers/${id}/researchreports/latest`, {
        'X-Summit-ViewType': 'DOMESTIC'
      })
    } else if (this.eventEnvironment && this.eventEnvironment === 'global') {
      $http.addSingleRequestCustomHeaderSet(`/issuers/${id}/researchreports/latest`, {
        'X-Summit-ViewType': 'GLOBAL'
      })
    }

    // If we're in the domestic environment but viewing a global rec report event,
    // we need to grab the global investment thesis from the company page data endpoint and
    // make sure we're getting the global rec report pdf
    await this.getInvestmentThesis(id)

    try {
      const { data = null } = await RecReportApi.getCompanyRecReportPDF(id)
      this.createRecReportPDFURL(data)
    } catch (err) {
      try {
        await this.getCompanyRecReport(id)
      } catch (err) {
        this.errorState = true
      }
    } finally {
      this.setRecReportRequestStatus(true)
    }

    if (this.$ua) {
      this.$ua.trackView(`Company Rec Report Page for Issuer: ${this.issuerId}`)
    }
  },
  computed: {
    ...mapState('companyPage', [
      'companyInfo',
      'companyRecReport',
      'recReportPdfURL',
      'recReportRequestComplete',
      'showRecReportPDF'
    ]),
    ...mapState('user', ['viewType']),
    reportAvailable() {
      return this.formattedInvestmentThesis.length > 0 || !!this.recReportPdfURL
    },
    showSpinner() {
      return !this.recReportRequestComplete
    },
    showGlobalReport() {
      return this.eventEnvironment
        ? this.eventEnvironment === 'global' && this.reportAvailable
        : this.viewType.isGlobal && this.reportAvailable
    },
    showDomesticReport() {
      const reportAvailable = this.domesticReportAvailable
      return this.eventEnvironment
        ? this.eventEnvironment === 'domestic' && reportAvailable
        : !this.viewType.isGlobal && reportAvailable
    },
    showNoInvestmentThesisAvailable() {
      const thesisNotAvailable = this.recReportRequestComplete && !this.formattedInvestmentThesis
      return this.eventEnvironment
        ? this.eventEnvironment === 'global' && thesisNotAvailable
        : this.viewType.isGlobal && thesisNotAvailable
    },
    showNoRecReportAvailable() {
      if (!this.recReportRequestComplete || this.companyRecReport == null) {
        return false
      }

      return !this.reportAvailable
    },
    formattedInvestmentThesis() {
      const companyInfoThesis = get(this, 'companyInfo.status.investmentThesis', '')
      if (!companyInfoThesis && !this.globalInvestmentThesis) {
        return ''
      }

      const thesis = companyInfoThesis || this.globalInvestmentThesis
      // Removes page break text that may be added from the new Rec Report
      const pageBreaks = new RegExp('-+ page break -+', 'gm')
      return thesis.replace(pageBreaks, '')
    },
    thesisHasText() {
      const pTags = new RegExp('</?p>', 'gm')
      const thesisWithoutPTags = this.formattedInvestmentThesis.replace(pTags, '')
      return thesisWithoutPTags.length > 0
    },
    lastModified() {
      const companyInfoThesisDate = get(this, 'companyInfo.status.investmentThesisDate', '')
      if (!companyInfoThesisDate && !this.globalInvestmentThesisDate) {
        return ''
      }

      const asOfData = companyInfoThesisDate || this.globalInvestmentThesisDate
      return `As of ${util.toDateTime(asOfData)}`
    }
  },
  methods: {
    ...mapActions('companyPage', ['getCompanyRecReport']),
    ...mapMutations('companyPage', {
      showRecReportFABButton: 'showRecReportFABButton',
      setRecReportPdfData: 'setRecReportPdfData',
      setRecReportRequestStatus: 'setRecReportRequestStatus',
      toggleRecReportPDFView: 'toggleRecReportPDFView',
      clearCompanyRecReport: 'setCompanyRecReport'
    }),
    async getInvestmentThesis(id) {
      const { data = null } = await CompanyInfoApi.getIssuerCompanyPage(id)
      this.globalInvestmentThesis = get(data, 'status.investmentThesis', '')
      this.globalInvestmentThesisDate = get(data, 'status.investmentThesisDate', '')
    },
    getLastModified(section) {
      if (section.onBehalfOf) {
        return `Last Modified by ${section.onBehalfOf.fullName} on ${util.toDateTime(
          section.lastModificationDate
        )}`
      } else {
        return `Last Modified by ${section.lastModifiedBy.fullName} on ${util.toDateTime(
          section.lastModificationDate
        )}`
      }
    },
    createRecReportPDFURL(blob) {
      if (blob) {
        const url = window.URL.createObjectURL(blob)
        this.setRecReportPdfData({ url, blob })
      }
    },
    getNumberOfPages(pages) {
      if (!pages) {
        return
      }
      this.numberOfPDFPages = pages
    },
    loadPDF() {
      this.pdfLoaded = true
      this.toggleRecReportPDFView(true)
    }
  },
  destroyed() {
    this.showRecReportFABButton(false)
    this.toggleRecReportPDFView(false)
    if (this.recReportPdfURL) {
      // Revoking the pdf object url when closing the page for memory/performance
      window.URL.revokeObjectURL(this.recReportPdfURL)
      this.setRecReportPdfData({ url: '', blob: null })
    } else {
      this.clearCompanyRecReport(null)
    }
  }
}
</script>

<style>
.rec-report-section-score-title {
  font-size: 0.75rem;
  color: #999;
}
.rec-report-no-report {
  font-weight: 600;
  margin-left: 8px;
  margin-top: 25px;
}
.rec-report-section {
  padding: 15px;
  margin-left: 5px;
  border-bottom: 1px solid #777777;
}
.rec-report-section-title {
  font-size: 1rem;
  margin-top: 8px;
}
.rec-report-section-sub-title {
  font-size: 0.6rem;
  margin-top: 2px;
  color: #888888;
}
</style>
