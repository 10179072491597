<template>
  <div>
    <div style="display: inline-flex">
      <div class="event-symbol">
        <span class="fa fa-lg fa-plus-square" />
      </div>
      <div>
        <div>
          <span
            v-if="model.entities[0].rating && model.entities[0].rating.code === 'OUTPERFORM'"
            class="event-indicator"
            >O</span
          >
          <span v-if="model.entities[0].onLeadersList" class="fa fa-trophy trophy" />
          <span @click="onClickCompany(model.entities[0])" class="lnk">{{
            model.entities[0].name
          }}</span>
        </div>
        <div>
          <span class="title">{{ title }}</span>
        </div>
        <table>
          <thead>
            <th>SYM</th>
            <th>COMPANY</th>
          </thead>
          <tbody>
            <tr v-for="(peer, index) in model.peers" :key="index">
              <td @click="onClickCompany(peer.issuerId)" class="lnk" style="width: 20%">
                {{ peer.ticker }}
              </td>
              <td
                @click="onClickCompany(peer.issuerId)"
                class="lnk"
                style="width: 80%; white-space: nowrap"
              >
                {{ peer.companyName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PeerGroup',
  props: {
    model: { type: Object, required: true }
  },
  computed: {
    title() {
      if (!this.model) {
        return ''
      }

      if (this.model.subEventType && this.model.subEventType.name) {
        return this.model.subEventType.name
      }

      return this.model.title || ''
    },
    issuer() {
      if (!this.model.entities || !this.model.entities.length) {
        return {}
      }

      return this.model.entities.find(({ entityType }) => entityType === 'ISSUER') || {}
    }
  },
  methods: {
    onClickCompany(id) {
      this.$f7router.navigate(`/company/${id}/info`)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-membership-popup {
  .body {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .indent {
    padding-left: 15px;
  }

  .header {
    font-weight: bold;
  }
}

.list-membership-popup > div {
  margin-bottom: 15px;
}
</style>
