import axios from 'axios'
import util from '../util'
import { getTokenRedirect } from '../assets/js/auth/authRedirect'

const $http = axios.create({
  baseURL: getRootUrl(),
  customHeaderSets: [],
  withCredentials: true, //util.getEnvironment() === 'localhost',
  timeout: 1200000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-Summit-OnBehalf': null,
    'X-Summit-ViewType': 'GLOBAL'
  }
})

$http.updateHeader = (key, value) => {
  $http.defaults.headers[key] = value
}

$http.addSingleRequestCustomHeaderSet = (urlKey, header) => {
  const customHeaderSet = {
    urlKey,
    timestamp: new Date(),
    headers: { ...$http.defaults.headers, ...header }
  }
  $http.defaults.customHeaderSets.push(customHeaderSet)
}

function getRootUrl() {
  if (util.getEnvironment() === 'localhost') {
    return 'https://summitqa.williamblair.com/api'
  } else {
    return `https://${location.host}/api`
  }
}

function applyCustomHeaderSet(config) {
  let custHdrSet = $http.defaults.customHeaderSets

  let customHeaderSet = custHdrSet.find((x) => {
    return config.url.indexOf(x.urlKey) > -1
  })
  if (customHeaderSet) {
    config.headers = customHeaderSet.headers
    $http.defaults.customHeaderSets = custHdrSet = custHdrSet.filter((x) => {
      return config.url.indexOf(x.urlKey) === -1
    })
  }
  $http.defaults.customHeaderSets = custHdrSet.filter((x) => x.timestamp > util.addMinutesToNow(-1))
  return config
}

$http.interceptors.request.use((config) => {
  return getTokenRedirect()
    .then((token) => {
      const customConfig = applyCustomHeaderSet(config)
      customConfig.headers.Authorization = `Bearer ${token.idToken}`
      return Promise.resolve(customConfig)
    }).catch((error) => {
      return Promise.reject(error)
    })
})
export default $http
