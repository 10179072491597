<template>
  <div class="estimate-container rec-report-section">
    <span class="rec-report-section-title">{{ title }}</span>
    <p class="rec-report-section-sub-title">
      {{ lastModified }}
    </p>

    <table class="estimate-table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>FY-1</th>
          <th>FYA</th>
          <th>FY1</th>
          <th>FY2</th>
        </tr>
      </thead>
      <tbody v-for="(item, index) in reportItems" :key="index">
        <tr><td class="estimate-table-row-header" colspan="5">{{ item.title }}</td></tr>
        <tr>
          <td class="estimate-table-row-sub-header">Street (Current)</td>
          <td v-if="!item.isPercent" v-for="period in periods" :key="period.cur">{{ section.streetEstimate[item.abbrv+period.cur] | number(item.precsion) || '-' }}</td>
          <td v-if="item.isPercent" v-for="period in periods" :key="period.cur">{{ getPercentChange(section.streetEstimate[item.abbrv+period.prior], section.streetEstimate[item.abbrv+period.cur], 1) }}</td>
        </tr>
        <tr>
          <td class="estimate-table-row-sub-header">Street (Published)</td>
          <td v-if="!item.isPercent" v-for="period in periods" :key="period.cur">{{ section.publishedStreetEstimate[item.abbrv+period.cur] || '-' }}</td>
          <td v-if="item.isPercent" v-for="period in periods" :key="period.cur">{{ getPercentChange(section.publishedStreetEstimate[item.abbrv+period.prior], section.publishedStreetEstimate[item.abbrv+period.cur], 1) }}</td>
        </tr>
        <tr v-if="!viewType.isGlobal">
          <td class="estimate-table-row-sub-header">Author (Published)</td>
          <td v-if="!item.isPercent" v-for="period in periods" :key="period.cur">{{ section.authorEstimate[item.abbrv+period.cur] || '-' }}</td>
          <td v-if="item.isPercent" v-for="period in periods" :key="period.cur">{{ getPercentChange(section.authorEstimate[item.abbrv+period.prior], section.authorEstimate[item.abbrv+period.cur], 1) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data grid-msg-overlay" v-show="!section || !section.streetEstimate">
      <div class="msg msg-large">
        <span style="margin-left: 5px;">
          'No peer groups have been defined'
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      required: true
    },
    viewType: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    lastModified: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      reportItems: [
        { title: 'REV ($MM)', abbrv: 'rev', isPercent: false, precsion: 0 },
        { title: 'REV GR (%)', abbrv: 'rev', isPercent: true, precsion: 1 },
        { title: 'OPM', abbrv: 'opm', isPercent: false, precsion: 1 },
        { title: 'EPS', abbrv: 'eps', isPercent: false, precsion: 2 },
        { title: 'EPS GR (%)', abbrv: 'eps', isPercent: true, precsion: 1 },
        { title: 'CFROIC', abbrv: 'cfroic', isPercent: false, precsion: 1 },
        { title: 'ROE', abbrv: 'roe', isPercent: false, precsion: 1 }
      ],
      periods: [
        { cur: 'Py1', prior: 'Py2' },
        { cur: 'Fya', prior: 'Py1' },
        { cur: 'Fy1', prior: 'Fya' },
        { cur: 'Fy2', prior: 'Fy1' }
      ]
    }
  },
  methods: {
    getPercentChange(bv, ev, precision) {
      if (ev == null || bv == null || bv === 0) {
        return '-'
      }
      let result = ((ev - bv) / bv) * 100
      return result.toFixed(precision)
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-container {
  padding-right: 15px;
  padding-bottom: 15px;

  .estimate-table {
    overflow: auto;
    width: 100%;
    border-spacing: 0px;
    font-size: 0.7rem;

    th {
      text-align: right;
      padding: 10px;
      font-size: 0.7rem;
      border-bottom: 1px solid #555555;
      width: 40px;

      p {
        margin: 0px !important;
      }
    }

    th:first-child {
      text-align: center;
      width: 75px;
      max-width: 70px;
      overflow: hidden;
    }

    th:last-child,
    td:last-child {
      border-right: unset;
    }

    td {
      padding: 5px;
      text-align: right;
      width: 40px;
    }

    td:first-child {
      width: 70px;
      max-width: 70px;
      overflow: hidden;
    }

    .estimate-table-row-header {
      padding-left: 5px;
      font-weight: bold;
      font-size: 0.7rem;
      text-align: left;
      background: #f5f5f5;
      border-bottom: 1px solid #999;
    }

    .estimate-table-row-sub-header {
      padding-right: 2px;
      font-size: 0.7rem;
      font-weight: normal;
      text-align: right;
    }

    .estimate-table-row-item {
      text-align: left;
      padding-left: 5px;
    }
  }
}
</style>
