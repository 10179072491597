<template>
  <div>
    <div class="ratings">
      <div class="event-symbol">
        <span
          class="fa fa-lg"
          :class="{
            'fa-expand fa-rotate-45 neutral-change': model.changeType === 'CHANGE',
            'fa fa-arrow-up upward-change': model.changeType === 'UPGRADE',
            'fa-arrow-down downward-change': model.changeType === 'DOWNGRADE'
          }"
        />
      </div>
      <div>
        <div>
          <span v-if="model.entities[0].rating.code === 'OUTPERFORM'" class="event-indicator"
            >O</span
          >
          <span v-if="model.entities[0].onLeadersList" class="fa fa-trophy trophy" />
          <span @click.stop="onClickCompany(model.entities[0])" class="lnk">{{
            model.entities[0].name
          }}</span>
        </div>
        <div>
          <span class="title" @click="openRecReport(model)" v-if="ratingIsUpgrade"
            >{{ summary
            }}<a style="text-decoration:underline;">{{ model.changedToRating.name }}</a></span
          >
          <span class="title" @click="openCommentModal(model)" v-if="!ratingIsUpgrade"
            >{{ summary
            }}<a style="text-decoration:underline;">{{ model.changedToRating.name }}</a></span
          >
        </div>
      </div>
    </div>
    <component :info="popupInfo" :opened="popupOpened" :is="'event-popup'">
      <div class="rating-change-popup">
        <div class="emphasis">
          <!-- Example of how line below should read: Workspace Group PLC rating change to PM Outperform from Neutral -->
          <span>{{
            `${model.entities[0].name} rating ${model.changeType.toLowerCase()} to ${
              model.changedToRating.name
            } from ${model.changedFromRating.name}`
          }}</span>
        </div>
        <div class="emphasis" v-if="model.changedToRating.name.toLowerCase() === 'pm outperform'">
          Investment Thesis
        </div>
        <div class="emphasis" v-else>
          Reason for {{ model.changeType.toLowerCase() }}:
          <span v-if="model.body" v-html="model.body" style="font-weight:normal;" />
        </div>

        <div v-if="model.investmentHypothesis">
          <span>Prior Investment Hypothesis</span>
          <span style="font-size:.9em">
            <span style="margin-left:20px;">Last Modified</span>
            <span>{{ model.investmentHypothesisDate | date }}</span>
          </span>
          <div v-html="model.investmentHypothesis" />
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
  name: 'RatingChange',
  mixins: [eventPopupMixin],
  components: {
    EventPopup
  },
  data() {
    return {}
  },
  computed: {
    popupInfo() {
      return {
        title: this.popupTitle,
        fullName: this.model.createdBy.fullName,
        date: this.model.eventDate
      }
    },
    summary() {
      const summary = ''
      if (this.model.changeType === 'UPGRADE') {
        return `Rating upgraded to `
      }

      if (this.model.changeType === 'DOWNGRADE') {
        return `Rating downgraded to `
      }

      if (this.model.changeType === 'CHANGE' && this.model.changedToRating) {
        return `Rating changed to `
      }

      return summary
    },
    ratingIsUpgrade() {
      return this.model.changedToRating && this.model.changeType === 'UPGRADE'
    }
  },
  methods: {
    ...mapMutations('events', ['setPopupFlag']),
    openRecReport(event) {
      // Uses the menu version of the openRecReport function to decide whether to show
      // Domestic or Global report
      this.$parent.$parent.$parent.$parent.$parent.$parent.openRecReport(event.entities[0], event)
    },
    openCommentModal() {
      this.setPopupFlag({ name: 'rec-updates', eventId: this.model.objectId })
    }
  }
}
</script>

<style lang="scss" scoped>
.ratings {
  display: inline-flex;
}
.upward-change {
  color: #068064;
}
.downward-change {
  color: #912536;
}
.neutral-change {
  color: #d9c923;
}
.rating-change-popup > div {
  margin-bottom: 15px;
}
.rating-change-popup {
  .emphasis {
    font-weight: bold;
  }
}
</style>
