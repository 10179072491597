<template>
  <div class="company-page">
  
    <div class="comp-info-row">
      <div class="comp-info-yes-no-block" style="width:35%">
        <div class="comp-info-value comp-info-yes-no" :class="{'comp-info-good': companyInfo.status.isOnEligList, 'comp-info-bad': !companyInfo.status.isOnEligList}">{{ companyInfo.status.isOnEligList ? 'Yes': 'No' }}</div>
        <div class="comp-info-sub-title">Elig List</div>
      </div>

      <div class="comp-info-yes-no-block" style="width:41%">
        <div class="comp-info-value comp-info-yes-no" 
          :class="{'comp-info-good': (companyInfo.status.onBuyResearchAgenda || companyInfo.status.onSellResearchAgenda), 'comp-info-bad': (!companyInfo.status.onBuyResearchAgenda && !companyInfo.status.onSellResearchAgenda)}">
          {{ companyInfo.status.onBuyResearchAgenda ? 'Buy' : (companyInfo.status.onSellResearchAgenda ? 'Sell' : 'No') }}
        </div>
        <div class="comp-info-sub-title">Research Agenda</div>
      </div>

      <div class="comp-info-yes-no-block" style="width:24%">
        <div class="comp-info-value comp-info-yes-no" :class="{'comp-info-good': companyInfo.status.isLeader, 'comp-info-bad': !companyInfo.status.isLeader}">{{ companyInfo.status.isLeader ? 'Yes': 'No' }}</div>
        <div style="width:30%" v-if="viewType && !viewType.isGlobal" class="comp-info-sub-title">S.A.</div>
        <div style="width:30%" v-if="!viewType || viewType.isGlobal" class="comp-info-sub-title">Leader</div>
      </div>
    </div>

    <div class="comp-info-row">
      <div style="width:40%">
        <div class="comp-info-value">{{ companyInfo.status.analystFirstName ? companyInfo.status.analystFirstName + ' ' + companyInfo.status.analystLastName : 'No Analyst' }}</div>
        <div class="comp-info-sub-title">Analyst</div>
      </div>
      <div style="width:30%">
        <div class="comp-info-value" :class="{'comp-info-good': companyInfo.status.ratingName==='Outperform'}">{{ companyInfo.status.ratingName ? companyInfo.status.ratingName: 'No Rating Assigned' }}</div>
        <div class="comp-info-sub-title">Rating</div>
      </div>

      <!-- Corp Lifecylce - GLOBAL only -->
      <div style="width:30%" v-if="!viewType || viewType.isGlobal">
        <div class="comp-info-value">{{ companyInfo.status.corporateLifecycleName ? companyInfo.status.corporateLifecycleName : '-' }}</div>
        <div class="comp-info-sub-title">Corporate Life Cycle</div>
      </div>
      
      <!-- LC Sustain EL - DOMESTIC only -->
      <div style="width:30%" v-if="viewType && !viewType.isGlobal">
        <div 
          class="comp-info-value comp-info-yes-no" 
          :class="{'comp-info-good': companyInfo.status.isOnLcSustainElig, 'comp-info-bad': !companyInfo.status.isOnLcSustainElig}">
          {{ companyInfo.status.isOnLcSustainElig ? 'Yes' : 'No' }}
        </div>
        <div class="comp-info-sub-title">LC Sustain EL</div>
      </div>
      
    </div>

    <div class="comp-info-row">
      <div style="width:30%">
        <div class="comp-info-value">{{ companyInfo.marketData.price | number(2) }}&nbsp;<span style="font-size:.8rem;color:#777;">({{ companyInfo.info.tradingCurrencyCode }})</span></div>
        <div class="comp-info-sub-title">Price</div>
      </div>

      <div style="width:23%">
        <div class="comp-info-value">{{ companyInfo.marketData.marketCap/1000000 | number(0) }}</div>
        <div class="comp-info-sub-title">Mkt Cap</div>
      </div>
      
      <div style="width:32%">
        <div class="comp-info-value">{{ companyInfo.marketData.marketCapFloatAdjUSDAmt/1000000 | number(0) }}</div>
        <div class="comp-info-sub-title">Flt Adj Mkt Cap</div>
      </div>

      <div style="width:15%">
        <div class="comp-info-value">{{ companyInfo.marketData.adtv3MonthUSD/1000000 | number(1) }}</div>
        <div class="comp-info-sub-title">ADTV</div>
      </div>
    </div>

    <div class="comp-info-row">
      <div style="width:40%">
        <div class="comp-info-value">{{ new Date(companyInfo.info.earningsDate).toDateString() }}</div>
        <div class="comp-info-sub-title">Earnings Date</div>
      </div>

      <div style="width:60%">
        <div class="comp-info-value">{{ companyInfo.info.country }}</div>
        <div class="comp-info-sub-title">Country</div>
      </div>
    </div>

    <div class="comp-info-row">
      <div style="width:40%">
        <div class="comp-info-value">{{ companyInfo.info.sector }}</div>
        <div class="comp-info-sub-title">Sector</div>
      </div>

      <div style="width:60%">
        <div class="comp-info-value">{{ companyInfo.info.industryShortName }}</div>
        <div class="comp-info-sub-title">Industry</div>
      </div>
    </div>

    <div class="comp-info-row" style="width:100%;padding-left:0px;padding-right:0px;margin-top:0px;">
      <company-market-data :issuer-id="issuerId"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: { CompanyMarketData: () => import('./CompanyMarketData.vue') },
  props: {
    issuerId: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapState('companyPage', ['companyInfo']),
    ...mapState('user', ['viewType']),
    ...mapGetters('user', ['currentOrOnBehalfOfUser']),
    environmentalScore() {
      if (this.companyInfo.wbEsgRating && this.companyInfo.wbEsgRating.environmentalScore) {
        return this.companyInfo.wbEsgRating.environmentalScore
      } else {
        return '-'
      }
    },
    socialScore() {
      if (this.companyInfo.wbEsgRating && this.companyInfo.wbEsgRating.socialScore) {
        return this.companyInfo.wbEsgRating.socialScore
      } else {
        return '-'
      }
    },
    governanceScore() {
      if (this.companyInfo.wbEsgRating && this.companyInfo.wbEsgRating.governanceScore) {
        return this.companyInfo.wbEsgRating.governanceScore
      } else {
        return '-'
      }
    }
  },
  mounted() {
    if (this.$ua) {
      this.$ua.trackView(`Company Info Page for Issuer: ${this.issuerId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.f7-page {
  background: white;
  height: 100vh;
}
.list {
  ul {
    border: 0px !important;
  }
}
.comp-info-row {
  display: inline-flex;
  align-items: center;
  min-height: 60px;
  max-height: 60px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #e0e0e0;
}
.comp-info-row.security {
  padding-left: 0px;
  margin-left: 0px;
}
.comp-info-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0a1e47;
}
.comp-info-price {
  margin-left: 0px;
  font-size: 1rem;
  font-weight: 500;
}
.comp-info-yes-no-block {
  justify-content: center;
  display: block;
}
.comp-info-yes-no {
  font-size: 1rem;
}
.comp-info-on-agenda {
  color: #e5c100;
}
.comp-info-good {
  color: green;
}
.comp-info-bad {
  color: #990000;
}
.comp-info-value {
  font-weight: 500;
}
.comp-info-sub-value {
  color: #222;
  font-weight: 600;
  font-size: 1.1rem;
}
.comp-info-sub-title {
  color: #999;
  font-size: 0.7rem;
}
.comp-info-holdings {
  width: 95%;
  tr {
    height: 45px;
    td:first-child {
      color: #0a1e47;
      font-weight: bold;
      width: 40%;
    }
    td:nth-child(2) {
      width: 30%;
    }
    td:last-child {
      width: 20%;
    }
  }
}
.comp-info-trade-btn {
  border-color: #777;
  background: #f5f5f5;
  color: #222 !important;
  width: 70px;
  font-size: 1rem;
}
.v-center {
  display: flex;
  align-items: center;
}
.security-slider {
  background: white;
}
</style>
