import $http from './apiClient'

export default {
  async getIssuerCompanyPage(issuerId) {
    const response = await $http.get(`/issuers/${issuerId}/companypagedata/lite`)
    return response.data
  },

  async getSecurityCompanyPage(issuerId, securityId) {
    const response = await $http.get(`/issuers/${issuerId}/securities/${securityId}/companypagedata/lite`)
    return response.data
  }
}
