import userApi from '../../apis/user'
import $http from '../../apis/apiClient'
//import auth from '../../auth'

const state = {
  currentUser: { userRole: { code: '' } },
  onBehalfOfUser: null,
  isOnBehalfOf: false,
  viewType: {
    isGlobal: true,
    desc: 'GLOBAL',
    inActive: 'DOMESTIC',
    id: 0
  },
  idToken: null,
  accessToken: null,
  refreshToken: null
}

const mutations = {
  currentUser: (state, payload) => (state.currentUser = payload),
  onBehalfOfUser: (state, payload) => (state.onBehalfOfUser = payload),
  setViewType(state) {
    const currentOrOnBehalfOfUser = state.onBehalfOfUser ? state.onBehalfOfUser : state.currentUser
    const vt = currentOrOnBehalfOfUser.primaryViewType
    state.viewType.id = vt.id
    state.viewType.isGlobal = vt.id === 1
    state.viewType.desc = vt.code
    state.viewType.inActive = state.viewType.isGlobal ? 'DOMESTIC' : 'GLOBAL'
    $http.updateHeader('X-Summit-ViewType', state.viewType.desc)
  },
  toggleViewType(state) {
    state.viewType.id = state.viewType.id === 1 ? 2 : 1
    state.viewType.isGlobal = !state.viewType.isGlobal
    state.viewType.inActive = state.viewType.desc
    state.viewType.desc = state.viewType.isGlobal ? 'GLOBAL' : 'DOMESTIC'
    $http.updateHeader('X-Summit-ViewType', state.viewType.desc)
  }
}

const actions = {
  async resetOBU({ dispatch }) {
    const obu = JSON.parse(localStorage.getItem('summit-mobile-onbehalfofuser'))
    if (obu) {
      dispatch('setOnBehalfOfUser', { onBehalfOfUser: obu })
    } else {
      dispatch('setOnBehalfOfUser', { onBehalfOfUser: null })
      state.isOnBehalfOf = false
      $http.updateHeader('X-Summit-OnBehalf', null)
    }
  },
  async setCurrentUser({ commit, dispatch }) {
    await dispatch('resetOBU')
    
    const user = await userApi.getCurrentUser()
    
    // TODO: temp code to weed out dupes in OnBehalfOfUsers collection until the database can be updated
    let oboUsers = []
    if (user.onBehalfOfUsers) {
      user.onBeHalfOfUsers.forEach((x) => {
        if (!oboUsers.find((y) => y.principal.toUpperCase() === x.principal.toUpperCase())) {
          oboUsers.push(x)
        }
      })
      user.onBeHalfOfUsers.length = 0
      user.onBeHalfOfUsers = [...oboUsers]
    }

    commit('currentUser', user)
    commit('setViewType')
  },

  async setOnBehalfOfUser({ commit }, { onBehalfOfUser }) {
    if (onBehalfOfUser === null) {
      commit('onBehalfOfUser', null)
      state.isOnBehalfOf = false
      $http.updateHeader('X-Summit-OnBehalf', null)
      localStorage.setItem('summit-mobile-onbehalfofuser', null)
    } else if (state.currentUser.principal !== onBehalfOfUser.principal) {
      localStorage.setItem('summit-mobile-onbehalfofuser', JSON.stringify(onBehalfOfUser))
      $http.updateHeader('X-Summit-OnBehalf', onBehalfOfUser.principal)
      // need to get full user object for the onBehalfUser here as the objects in the currentUser
      // onBehalfOfUser's collection does not contain the user permissions
      const user = await userApi.getUser(onBehalfOfUser.id)
      commit('onBehalfOfUser', user)
      state.isOnBehalfOf = true
    }
  }
}

const getters = {
  /*isAuthenticated: () => {
    return auth.authenticationContext && auth.isAuthenticated()
  },*/
  isAdmin: (state) => {
    if (!state.currentUser || !state.currentUser.userRoleViewMap) {
      return false
    }
    const role = state.currentUser.userRoleViewMap.find((x) => x.viewTypeId === state.viewType.id)
    if (role.userRole.code === 'SUPER_ADMIN') {
      return true
    }
    return false
  },
  currentOrOnBehalfOfUser: (state) => {
    return state.onBehalfOfUser ? state.onBehalfOfUser : state.currentUser
  },
  canTrade(state, { currentOrOnBehalfOfUser }) {
    const user = currentOrOnBehalfOfUser

    if (!user.userRoleViewMap) {
      return false
    }

    let userRoleMap = user.userRoleViewMap.find((x) => x.viewTypeId === state.viewType.id)

    if (
      !userRoleMap ||
      !userRoleMap.userRole ||
      !userRoleMap.userRole.userRoleUserResourcePermissionMap
    )
      return false

    const t = userRoleMap.userRole.userRoleUserResourcePermissionMap.find(
      ({ userResource, permission }) =>
        userResource.code === 'TRADE_INIT' && permission.code === 'U'
    )
    return t != undefined && userRoleMap && userRoleMap.user && userRoleMap.user.isPmOrPmAnalyst
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
