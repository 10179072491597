import { mapGetters, mapState } from 'vuex'
import orderBy from 'lodash/orderBy'

export default {
  computed: {
    ...mapGetters('lists', ['listItems', 'listIsFiltered', 'filteredListItems']),
    ...mapState('lists', ['listFilterButtonsVisible']),
    valuesAvailable() {
      return !this.listIsFiltered || (this.listIsFiltered && this.filteredListItems.length > 0)
    },
    sortDirection() {
      return this.sortDirectionIsAscending ? 'asc' : 'desc'
    },
    sortedListItems() {
      const listItems = this.listItems

      const sortParams = {
        analyst: this.sortAnalysts,
        name: this.sortIssuerNames,
        default: this.sortedColumn
      }

      const sortParam = sortParams[this.sortedColumn] || sortParams.default
      for (let key in listItems) {
        listItems[key] = orderBy(listItems[key], [sortParam], [this.sortDirection])
      }

      return listItems
    },
    listDataStyles() {
      const style = {
        height: '78vh',
        'overflow-y': 'auto',
        '-webkit-transition': 'height 0.5s',
        transition: 'height 0.5s'
      }

      if (this.listFilterButtonsVisible) {
        style.height = '72vh'
      }

      return style
    }
  },
  methods: {
    goToCompanyPage({ issuerId }) {
      this.$f7router.navigate(`/company/${issuerId}/info`)
    },
    columnSortedAscending(colName) {
      return this.sortedColumn === colName && this.sortDirectionIsAscending
    },
    columnSortedDescending(colName) {
      return this.sortedColumn === colName && !this.sortDirectionIsAscending
    },
    sortAnalysts(value) {
      const name = value.analyst || value.coverageAnalyst
      return name
    },
    sortIssuerNames(value) {
      const name = value.name || value.securityName
      return name.toLowerCase()
    },
    sortList(columnName) {
      if (columnName !== this.sortedColumn) {
        this.sortedColumn = columnName
        this.sortDirectionIsAscending = true
        return
      }

      this.sortDirectionIsAscending = !this.sortDirectionIsAscending
    }
  },
  filters: {
    shortName(value) {
      const shortNameMap = {
        'Communication Services': 'Comm Svs',
        'Consumer Discretionary': 'Cons Disc',
        'Consumer Staples': 'Staples',
        Energy: 'Energy',
        Financials: 'Financials',
        'Health Care': 'Health Care',
        'Health Care Equipment & Services': 'Health Care Equip & Svcs',
        Industrials: 'Industrials',
        'Information Technology': 'IT',
        Materials: 'Materials',
        'Real Estate': 'Real Estate',
        Utilities: 'Utilities',
        'Diversified Telecommunication Services': 'Div Tel Svs',
        Entertainment: 'Entertainment',
        'Interactive Media & Services': 'Interactive Media',
        Media: 'Media',
        'Wireless Telecommunication Services': 'Wire Tel Svs',
        'Auto Components': 'Auto Comp',
        Automobiles: 'Auto',
        Distributors: 'Distr',
        'Diversified Consumer Services': 'Div Con Svs',
        'Hotels, Restaurants & Leisure': 'Hotel Rest',
        'Household Durables': 'House Dur',
        'Consumer Durables & Apparel': 'Consumer Dur',
        'Internet & Catalog Retail': 'Int Cat Ret',
        'Leisure Products': 'Leis Prod',
        'Multiline Retail': 'Multi Ret',
        'Specialty Retail': 'Spec Ret',
        'Textiles, Apparel & Luxury Goods': 'Text App LuxG',
        Beverages: 'Beverages',
        'Food & Staples Retailing': 'Food Stpl Ret',
        'Food Products': 'Food Prod',
        'Household Products': 'HH Prod',
        'Personal Products': 'Pers Prod',
        'Household & Personal Products': 'HH & Pers Prod',
        Tobacco: 'Tobacco',
        'Energy Equipment & Services': 'En Equ Svs',
        'Oil, Gas & Consumable Fuels': 'Oil Gas Fuel',
        Banks: 'Com Banks',
        'Capital Markets': 'Cap Mkt',
        'Consumer Finance': 'Cons Fin',
        'Diversified Financial Services': 'Diversfd',
        Insurance: 'Insurance',
        'Mortgage Real Estate Investment Trusts (REITS)': 'Mortgage REITS',
        'Thrifts & Mortgage Finance': 'Mort',
        Biotechnology: 'Biotech',
        'Health Care Equipment & Supplies': 'HC Eq Sup',
        'Health Care Providers & Services': 'HC Prov Svs',
        'Health Care Technology': 'HC Tech',
        'Life Sciences Tools & Services': 'Life Sci Tool Svs',
        Pharmaceuticals: 'Pharma',
        'Pharmaceuticals, Biotechnology & Life Sciences': 'Pharma, Biotech & Life Sci',
        'Aerospace & Defense': 'Aero Def',
        'Air Freight & Logistics': 'Air Freight',
        Airlines: 'Airlines',
        'Building Products': 'Build Prod',
        'Commercial Services & Supplies': 'Com Svs',
        'Construction & Engineering': 'Const Eng',
        'Electrical Equipment': 'Elec Eq',
        'Industrial Conglomerates': 'Cong',
        Machinery: 'Machinery',
        Marine: 'Marine',
        'Professional Services': 'Prof Svs',
        'Commercial & Professional Services': 'Com & Prof Svs',
        'Road & Rail': 'Road Rail',
        'Trading Companies & Distributors': 'Trad Co Dis',
        'Transportation Infrastructure': 'Trans Infr',
        'Communications Equipment': 'Com Eq',
        'Electronic Equipment, Instruments & Components': 'El Eq Instr Compt',
        'IT Services': 'IT Svs',
        'Office Electronics': 'Office Elec',
        'Semiconductor Equipment & Products': 'Semi Eq Prod',
        'Semiconductors & Semiconductor Equipment': 'Semis Semi Eq',
        Software: 'Software',
        'Technology Hardware, Storage & Peripherals': 'Comp Perip',
        'Technology Hardware & Equipment': 'Tech Hardware & Equip',
        Chemicals: 'Chemicals',
        'Construction Materials': 'Const Mat',
        'Containers & Packaging': 'Const Pack',
        'Metals & Mining': 'Met Min',
        'Paper & Forest Products': 'Pap For Prod',
        'Equity Real Estate Investment Trusts (REITS)': 'Equity REITS',
        'Real Estate Management & Development': 'Real Estate Mgt & Dev',
        'Electric Utilities': 'Elec Ut',
        'Gas Utilities': 'Gas Ut',
        'Independent Power & Renewable Elec Producers': 'Indp Pwr Tdrs',
        'Multi-Utilities': 'Multi Ut',
        'Water Utilities': 'Water Util '
      }

      return shortNameMap[value] || value
    },
    lowerCase(value) {
      if (!value) {
        return ''
      }
      return value.toLowerCase() === 'usa' ? value : value.toLowerCase()
    },
    percentage(value, places) {
      if (!value) {
        return 0
      }
      const num = value * 100
      return num.toFixed(places)
    },
    round(value, places) {
      if (!value) {
        return 0
      }
      return value.toFixed(places)
    },
    marketCap(value) {
      if (!value) {
        return null
      }
      return Math.round(value / 1000000).toLocaleString()
    },
    marketValue(value) {
      if (!value) {
        return '0.0'
      }
      const num = value / 1000000
      return num.toFixed(1)
    },
    rating(value) {
      if (!value) {
        return ''
      }

      const ratingMap = {
        'No Opinion': 'NO',
        Neutral: 'N',
        Outperform: 'O',
        Underperform: 'U',
        SS: 'SS'
      }

      return ratingMap[value]
    }
  }
}
