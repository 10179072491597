import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import some from 'lodash/some'
import listApi from '../../apis/lists'

const checkCompanyFilter = (filterList, filterValue) => {
  return (
    filterList.length === 0 ||
    filterList.includes(filterValue) ||
    some(filterList, (appliedFilter) => appliedFilter.industry === filterValue)
  )
}

const state = {
  allLists: [],
  listItems: [],
  eligListItems: [],
  eligListSelected: false,
  appliedAgendaFilters: [],
  appliedIndustryFilters: [],
  appliedRegionFilters: [],
  appliedSectorFilters: [],
  listRequestComplete: false,
  mostRecentList: '',
  groupBy: 'sector',
  listFilterButtonsVisible: false,
  benchmarkWeights: {
    "Communication Services": null,
    "Consumer Discretionary": null,
    "Consumer Staples": null,
    "Energy": null,
    "Financials": null,
    "Health Care": null,
    "Industrials": null,
    "Information Technology": null,
    "Materials": null,
    "Real Estate": null,
    "Utilities": null
  }
}

const mutations = {
  setAllLists: (state, payload) => (state.allLists = payload),
  setListItems: (state, payload) => (state.listItems = payload),
  setEligListItems: (state, payload) => (state.eligListItems = payload),
  selectEligList: (state, payload) => (state.eligListSelected = payload),
  setAgendaFilters: (state, payload) => (state.appliedAgendaFilters = payload),
  setIndustryFilters: (state, payload) => (state.appliedIndustryFilters = payload),
  setRegionFilters: (state, payload) => (state.appliedRegionFilters = payload),
  setSectorFilters: (state, payload) => (state.appliedSectorFilters = payload),
  setListRequestStatus: (state, payload) => (state.listRequestComplete = payload),
  setMostRecentList: (state, payload) => (state.mostRecentList = payload),
  setGroupBy: (state, payload) => (state.groupBy = payload),
  setBenchmarkWeights: (state, payload) => (state.benchmarkWeights = payload),
  showListFilterButtons: (state) => (state.listFilterButtonsVisible = !state.listFilterButtonsVisible)
}

const actions = {
  async getListItems({ commit }, { listType, listId }) {
    commit('setListRequestStatus', false)
    const listTypeIds = {
      portfolio: 3,
      agenda: 4,
      eligibility: 1
    }
    if (listType === "portfolio") {
      const portfolioLists = state.allLists.find(({ code }) => code === 'PORTFOLIO')
      const portfolio = portfolioLists.lists.find(({ id }) => id == listId)
      let bmkWeights = await listApi.getBenchmarkWeights('sector')
      bmkWeights = bmkWeights[0].filter(x => x.benchmark_code == portfolio.benchmark.code)
      let weights = {}
      bmkWeights.forEach(x => {
        weights[x.sector] = x.benchmark_weight_bps / 100
      })
      commit('setBenchmarkWeights', weights)
    }

    const listTypeId = listTypeIds[listType]
    if (!listTypeId) {
      throw new Error('Invalid list type')
    }
    const listItems = await listApi.getListById(listTypeId, listId)

    commit('setListItems', listItems)
    commit('setListRequestStatus', true)
  },
  async getEligibilityList({ commit }) {
    const eligListItems = await listApi.getListById(1, 3)

    commit('setEligListItems', eligListItems)
  },
  async getAllLists({ commit }) {
    const allLists = await listApi.getAllLists()
    commit('setAllLists', allLists)
  }
}

const getters = {
  allListItems(state) {
    return state.listItems.slice(0)
  },
  allEligListItems(state) {
    return state.eligListItems.slice(0)
  },
  userPortfolios(state) {
    const allPortfolios = state.allLists.find(({ code }) => code === 'PORTFOLIO')
    return allPortfolios ? allPortfolios.lists : []
  },
  defaultList(state, getters, rootState, rootGetters) {
    const currentUser = rootGetters['user/currentOrOnBehalfOfUser']
    const currentUserRole = get(currentUser, 'userRole.code', '').toLowerCase()

    const roleHandlers = {
      pm() {
        const defaultPortfolio = state.allLists
          .find(({ code }) => code === 'PORTFOLIO')
          .lists.find(({ userDefault }) => userDefault)
        return defaultPortfolio
          ? {
              shortName: defaultPortfolio.shortName,
              route: `/lists/portfolio/${defaultPortfolio.id}`
            }
          : {
              shortName: 'Elig List',
              route: '/lists/eligibility/3'
            }
      },
      defaultRole() {
        return {
          shortName: 'Elig List',
          route: `/lists/eligibility/${
            currentUserRole === 'analyst' || currentUserRole === 'pm_analyst' ? 9 : 3
          }`
        }
      }
    }

    const roleHandler = roleHandlers[currentUserRole] || roleHandlers.defaultRole
    return roleHandler()
  },
  possibleSectorFilters({ eligListSelected }, { allListItems, allEligListItems }) {
    const listItems = eligListSelected ? allEligListItems : allListItems
    const sectors = listItems
      .map(({ sector }) => sector)
      .filter((sector) => sector && sector.toLowerCase() !== 'undefined')
    return uniq(sectors).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
  },
  possibleIndustryFilters({ eligListSelected }, { allListItems, allEligListItems }) {
    const listItems = eligListSelected ? allEligListItems : allListItems
    const industries = listItems
      .map(({ industry, sector }) => ({ industry, sector }))
      .filter(({ industry }) => industry && industry.toLowerCase() !== 'undefined')
    const uniqueIndustries = uniqBy(industries, 'industry').sort((a, b) =>
      a.industry.localeCompare(b.industry, 'en', { sensitivity: 'base' })
    )
    const groupedIndustries = groupBy(uniqueIndustries, 'sector')
    return groupedIndustries
  },
  possibleRegionFilters({ eligListSelected }, { allListItems, allEligListItems }) {
    const listItems = eligListSelected ? allEligListItems : allListItems
    const regions = listItems
      .map(({ region }) => region)
      .filter((region) => region && region.toLowerCase() !== 'undefined')
    return uniq(regions).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
  },
  listIsFiltered({
    appliedAgendaFilters,
    appliedIndustryFilters,
    appliedRegionFilters,
    appliedSectorFilters
  }) {
    return (
      appliedAgendaFilters.length > 0 ||
      appliedIndustryFilters.length > 0 ||
      appliedRegionFilters.length > 0 ||
      appliedSectorFilters.length > 0
    )
  },
  filteredListItems(state, { allListItems }) {
    const filteredItems = allListItems.filter(({ agendaType = null, sector, industry, region }) => {
      const {
        appliedAgendaFilters,
        appliedIndustryFilters,
        appliedRegionFilters,
        appliedSectorFilters
      } = state

      const agendaFlag = checkCompanyFilter(appliedAgendaFilters, agendaType)
      const sectorFlag = checkCompanyFilter(appliedSectorFilters, sector)
      const industryFlag = checkCompanyFilter(appliedIndustryFilters, industry)
      const regionFlag = checkCompanyFilter(appliedRegionFilters, region)

      return agendaFlag && sectorFlag && industryFlag && regionFlag
    })

    return filteredItems
  },
  listItems(state, { listIsFiltered, allListItems, filteredListItems }) {
    const listItems = listIsFiltered ? filteredListItems : allListItems

    if (!state.groupBy) {
      return {
        allItems: listItems
      }
    }

    const items = groupBy(listItems, state.groupBy)
    const sortedGroupByKeys = Object.keys(items).sort((a, b) =>
      a.localeCompare(b, 'en', { sensitivity: 'base' })
    )
    const sortedItems = {}

    sortedGroupByKeys.forEach((key) => {
      sortedItems[key] = items[key]
    })

    return sortedItems
  },
  filteredEligListItems(state, { allEligListItems }) {
    const filteredItems = allEligListItems.filter(({ sector, industry, region }) => {
      const { appliedIndustryFilters, appliedRegionFilters, appliedSectorFilters } = state

      const sectorFlag = checkCompanyFilter(appliedSectorFilters, sector)
      const industryFlag = checkCompanyFilter(appliedIndustryFilters, industry)
      const regionFlag = checkCompanyFilter(appliedRegionFilters, region)

      return sectorFlag && industryFlag && regionFlag
    })

    return filteredItems
  },
  eligListItems(state, { listIsFiltered, allEligListItems, filteredEligListItems }) {
    const listItems = listIsFiltered ? filteredEligListItems : allEligListItems

    if (!state.groupBy) {
      return {
        allItems: listItems
      }
    }

    const items = groupBy(listItems, state.groupBy)
    const sortedGroupByKeys = Object.keys(items).sort((a, b) =>
      a.localeCompare(b, 'en', { sensitivity: 'base' })
    )
    const sortedItems = {}

    sortedGroupByKeys.forEach((key) => {
      sortedItems[key] = items[key]
    })

    return sortedItems
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
