import $http from './apiClient'

export default {
  async getListById(listTypeId, listId) {
    let listItems

    try {
      const listData = await $http.get(`/lists/${listTypeId}/${listId}/items`)
      listItems = listData.data.rows
    } catch (err) {
      listItems = []
      if (listId !== 3) {
        throw new Error('There was an error fetching list items')
      }
    }

    return Object.freeze(listItems)
  },
  async getAllLists() {
    let allLists

    try {
      const listData = await $http.get(`/listTypes/lists/all`)
      allLists = listData.data
    } catch (err) {
      allLists = []
    }

    return Object.freeze(allLists)
  },
  async getBenchmarkWeights(aggregationField) {
    const response = await $http.post(
      `/benchmarks/aggregatedWeights/leveled/${aggregationField}`
    )
    return response.data
  }
}
