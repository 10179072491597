<template>
  <div class="rec-report-section" v-if="section.type.isActive===true">
    <div class="rec-report-section-detail">
      <div>
        <span class="rec-report-section-title">{{ section.type.description }}</span>
        <p class="rec-report-section-sub-title">
          {{ lastModified }}
        </p>

        <div class="rec-report-section-scores" v-if="section.type.code==='GMI_ESG'" style="margin-right:0px;">
          <div v-if="Object.keys(section.currentMetrics).length">
            <div class="rec-report-section-score-left rec-report-section-esg" v-for="(key, index) in Object.keys(section.currentMetrics)" :key="index">
              <span class="rec-report-section-score-value">{{ section.currentMetrics[key] }}</span><br>
              <span class="rec-report-section-score-title">{{ key.toUpperCase().replace('SCORE', '').replace('MENTAL', '').replace('ANCE', '') }}</span>
            </div>
          </div>
        </div>
        <div class="rec-report-section-scores" v-if="section.type.code === 'ENVIRONMENTAL' || section.type.code === 'SOCIAL' || section.type.code === 'GOVERNANCE'" style="margin-right:0px;">
          <div v-if="Object.keys(section.currentMetrics).length">
            <div class="rec-report-section-score-left rec-report-section-esg">
              <span class="rec-report-section-score-value">{{ section.currentMetrics.wbRating || '-' }}</span><br>
              <span class="rec-report-section-score-title">WB {{ section.type.code }} SCORE</span>
            </div>
          </div>
        </div>
        <div class="rec-report-section-scores" v-else>
          <div class="rec-report-section-score-left" v-if="Object.keys(section.pointInTimeMetrics).length">
            <span class="rec-report-section-score-value">{{ Object.values(section.pointInTimeMetrics)[0] }}</span><br>
            <span class="rec-report-section-score-title">{{ Object.keys(section.pointInTimeMetrics)[0].toUpperCase() }} MODEL SCORE</span>
          </div>
          <div class="rec-report-section-score-right" v-if="Object.keys(section.currentMetrics).length">
            <span class="rec-report-section-score-value">{{ Object.values(section.currentMetrics)[0] }}</span><br>
            <span class="rec-report-section-score-title">CURRENT</span>
          </div>
        </div>
      </div>
      <p class="rec-report-section-text" v-html="section.body"/>
    </div>
  </div>
</template>

<script>
import util from '../../util'

export default {
  props: {
    section: { type: Object, required: true }
  },
  computed: {
    lastModified() {
      if (this.section.onBehalfOf) {
        return `Last Modified by ${this.section.onBehalfOf.fullName} on ${util.toDateTime(
          this.section.lastModificationDate
        )}`
      } else {
        return `Last Modified by ${this.section.lastModifiedBy.fullName} on ${util.toDateTime(
          this.section.lastModificationDate
        )}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rec-report-section {
  padding: 0 15px 15px 0px;
  margin-left: 15px;
  border-bottom: 1px solid #777777;
  font-family: 'Roboto Slab', Roboto, serif;

  .rec-report-section-detail {
    padding-left: 0px !important;

    .rec-report-section-scores {
      display: flex;

      .rec-report-section-score-title {
        font-size: 0.75rem;
        color: #999;
      }
      .rec-report-section-score-value {
        font-size: 1.1rem;
      }
      .rec-report-section-esg {
        font-weight: bold;
        margin-right: 40px;
      }
      .rec-report-section-esg:last-child {
        font-weight: bold;
        margin-right: 15px;
      }
      .rec-report-section-score-left {
        font-weight: bold;
        margin-right: 30px;
      }
      .rec-report-section-score-right {
        font-weight: bold;
      }
    }

    .rec-report-section-text {
      color: #222;
      overflow: auto;
      line-height: 24px;
      white-space: normal;
      text-align: justify;
      font-size: 0.9rem;
      padding-right: 10px;
    }
  }
}
</style>
