<template>
  <f7-page
    infinite
    :infinite-distance="35"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
    class="events"
  >
    <f7-list class="events-list" virtual-list>
      <f7-list-item v-for="event in issuerEvents.data" :key="event.objectId">
        <div slot="inner-start" class="event">
          <div style="display: flex">
            <component
              :id="event.objectId"
              :category-name="'company'"
              :is="mapEventTypeToTemplate(event.eventType)"
              :model="event"
              :on-click-company="goToCompanyPage"
              class="event-content"
            />
            <div
              v-if="showNavArrow(event.eventType)"
              class="right-arrow"
              @click="
                event.eventType === 'RESEARCH_INITIATIVE_CHANGE'
                  ? openRecReport(event.issuer, event)
                  : onClickPopupEvent(event.objectId, event)
              "
            >
              <span class="fa fa-2x fa-angle-right" />
            </div>
          </div>
          <div class="event-footer">
            <span class="text-trunc">{{ formatFooter(event) }}</span>
          </div>
          <div v-if="event.replies" class="reply-section">
            <div>{{ formatReplyCount(event.replies) }}</div>
            <f7-list>
              <f7-list-item
                v-for="reply in event.replies"
                :key="reply.objectId"
                class="reply-list-item"
              >
                <div slot="inner-start" class="event">
                  <div v-if="reply.eventType !== 'TRADE_INSTRUCTION'">
                    <span class="comment-text" v-if="reply.title"> {{ reply.title }}<br /> </span>
                    <span class="comment-text" v-html="reply.body" />
                  </div>
                  <div v-if="reply.eventType === 'TRADE_INSTRUCTION'">
                    <div>
                      <span>Trade Amendment</span>
                      <span v-if="reply.tradeStatus === 'TRADE_EDITED'">- AMENDED</span>
                      <span v-if="reply.tradeStatus === 'TRADE_CANCELLED'">- CANCELLED</span>
                    </div>
                    <trade-instruction-list :model="reply" />
                  </div>
                  <div class="event-footer trade-footer">
                    <span class="text-trunc">{{ formatFooter(reply) }}</span>
                  </div>
                </div>
              </f7-list-item>
            </f7-list>
          </div>
        </div>
      </f7-list-item>
      <f7-list-item v-if="endOfEventsMsg" :title="endOfEventsMsg" />
    </f7-list>
  </f7-page>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import RatingChange from './RatingChange.vue'
import ESGRating from './ESGRating.vue'
import TradeInstruction from './TradeInstruction.vue'
import TradeInstructionList from './TradeInstructionList.vue'
import Comment from './Comment.vue'
import ListMembership from './ListMembership.vue'
import ResearchInitiativeChange from './ResearchInitiativeChange.vue'
import CompanyClassification from './CompanyClassification.vue'
import ListMembershipStale from './ListMembershipStale.vue'
import PriceTarget from './PriceTarget.vue'
import PeerGroup from './PeerGroup.vue'
import util from '../../util'
import $http from '../../apis/apiClient'

export default {
  components: {
    RatingChange,
    ESGRating,
    TradeInstruction,
    TradeInstructionList,
    Comment,
    ListMembership,
    ResearchInitiativeChange,
    ListMembershipStale,
    CompanyClassification,
    PriceTarget,
    PeerGroup
  },
  name: 'ListTemplate',
  props: {
    issuerId: { type: [Number, String], required: true }
  },
  data() {
    return {
      page: 1,
      allowInfinite: true,
      eventTypeToTemplateMap: {
        TRADE_INSTRUCTION: TradeInstruction,
        RATING_CHANGE: RatingChange,
        ESG_RATING_CHANGE: ESGRating,
        COMMENT: 'comment',
        LIST_MEMBERSHIP: ListMembership,
        RESEARCH_INITIATIVE_CHANGE: ResearchInitiativeChange,
        LIST_MEMBERSHIP_STALE: ListMembershipStale,
        CLASSIFICATION: CompanyClassification,
        PEER_GROUP_ALERT: PeerGroup
      },
      companyId: { params: { issuerId: this.issuerId } }
    }
  },
  computed: {
    ...mapState('user', ['viewType']),
    ...mapState('events', ['events', 'searchRequestComplete', 'issuerEvents']),
    ...mapGetters('events', ['eventsByCategory', 'event', 'endOfData', 'eventsByActiveTab']),
    ...mapGetters('user', ['currentOrOnBehalfOfUser']),
    companyCategoryInfo() {
      return { ...this.issuerEvents, ...this.companyId }
    },
    showPreloader() {
      return !this.searchRequestComplete
    },
    endOfEventsMsg() {
      if (this.issuerEvents.endOfData) {
        return this.issuerEvents.data.length ? 'End of results' : 'No search results found'
      }
      return ''
    }
  },
  watch: {
    async currentOrOnBehalfOfUser() {
      await this.getIssuerEvents()
    }
  },
  methods: {
    ...mapActions('events', ['getEvents', 'getPointInTime', 'getPriceTarget']),
    ...mapMutations('events', ['setIssuerPopupFlag', 'clearIssuerEvents']),
    loadMore() {
      if (!this.searchRequestComplete || this.issuerEvents.endOfData) return

      this.page++

      const category = {
        name: 'company',
        endOfData: false,
        data: [],
        params: { issuerId: this.issuerId }
      }

      this.getEvents({
        page: this.page,
        category,
        api: 'getEventsForIssuer'
      })
    },
    mapEventTypeToTemplate(eventType) {
      if (eventType && eventType.toLowerCase().includes('price_target')) {
        return PriceTarget
      }

      const template = this.eventTypeToTemplateMap[eventType]
      if (template) {
        return template
      }
    },
    formatFooter(event) {
      if (event.eventType === 'LIST_MEMBERSHIP_STALE') {
        return `Created on ${util.toDateTime(event.eventDate)}`
      } else if (event.onBehalfOf) {
        return `By ${event.onBehalfOf.fullName} via ${
          event.createdBy.fullName
        } on ${util.toDateTime(event.eventDate)}`
      } else {
        return `By ${event.createdBy.fullName} on ${util.toDateTime(event.eventDate)}`
      }
    },
    formatReplyCount(replies) {
      const replyTxt = replies.length > 1 ? 'Replies' : 'Reply'
      return `${replies.length} ${replyTxt}`
    },
    async onClickPopupEvent(eventId, event) {
      if (event.eventType.includes('PRICE_TARGET')) {
        const issuer = event.entities.find(({ entityType }) => entityType === 'ISSUER')
        if (issuer && issuer.id) {
          await this.getPriceTarget(issuer.id)
        }
      }

      this.setIssuerPopupFlag({ eventId })
      this.getPointInTime(eventId)
    },
    async getIssuerEvents() {
      const category = {
        name: 'company',
        endOfData: false,
        data: [],
        params: { issuerId: this.issuerId }
      }

      await this.getEvents({
        page: 1,
        category,
        api: 'getEventsForIssuer'
      })
    },
    goToCompanyPage({ id }) {
      this.$f7router.navigate(`/company/${id}/info`)
    },
    showNavArrow(eventType) {
      return (
        eventType !== 'LIST_MEMBERSHIP_STALE' &&
        eventType !== 'TRADE_INSTRUCTION' &&
        eventType !== 'PEER_GROUP_ALERT'
      )
    },
    checkForDifferentEventEnvironment(event) {
      return (
        (this.viewType.isGlobal && event.viewType === 'DOMESTIC') ||
        (!this.viewType.isGlobal && event.viewType === 'GLOBAL')
      )
    },
    openRecReport(issuer, event) {
      const differentEnvironments = this.checkForDifferentEventEnvironment(event)
      if (differentEnvironments) {
        $http.addSingleRequestCustomHeaderSet('researchreports/latest', {
          'X-Summit-ViewType': event.viewType
        })
      }

      const eventEnvironment = event.viewType.toLowerCase()
      differentEnvironments
        ? this.$f7router.navigate(`/company/${issuer.id}/recReport`, {
            props: {
              eventEnvironment
            }
          })
        : this.$f7router.navigate(`/company/${issuer.id}/recReport`)
    }
  },
  async beforeMount() {
    await this.getIssuerEvents()
  },
  beforeDestroy() {
    this.clearIssuerEvents()
  }
}
</script>

<style lang="scss" scoped>
.ios .list {
  margin-top: 0;
}
.ios .list .item-content {
  padding-left: 0;
}
.ios .list .item-inner {
  padding: 8px;
}
.events {
  .page-content {
    padding-top: 0 !important;
    margin-top: 88px;
    height: calc(100% - 120px);
  }
}
.event {
  width: 100%;
}
.event-content {
  padding-bottom: 8px;
  width: 100%;
}
.right-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 75px;
  padding-right: 10px;
}
.event-symbol {
  padding-right: 5px;
}
.event-indicator {
  font-size: 0.7em;
  padding-right: 2px;
  padding-left: 2px;
  border: solid 1px gray;
}
.event-footer {
  font-size: 10px;
}
.trophy {
  color: darkgoldenrod;
}
.lnk {
  color: #007aff;
  text-decoration: underline;
}
.reply-section {
  margin-top: 7px;
}
.reply-list-item {
  background-color: #e5f1ff;
}
.comment-text {
  font-size: 13px;
}
</style>
