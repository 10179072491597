//a few core components has been include by default see https://bit.ly/2OghglK

//see https://webpack.js.org/guides/dependency-management/
const coreRequire = require.context(
  'framework7/components',
  true,
  /[-\w]+\/(actions|block|button|icon|list|checkbox|infinite-scroll|input|accordion[-\w]*|smart-select|sheet|toast|toolbar|popup|link|searchbar|tabs|swiper|panel|popover|toolbar|fab|fab-buttons?)\.js/
)

export default coreRequire.keys().map((f) => coreRequire(f).default)
