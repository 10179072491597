var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"inline-flex"}},[_c('div',{staticClass:"event-symbol"},[_c('span',{staticClass:"fa fa-lg",class:{
          'fa-plus-circle': _vm.model.type.code === 'ADD_TO_LIST',
          'fa-minus-circle': _vm.model.type.code === 'REMOVE_FROM_LIST'
        }})]),_c('div',[_c('div',[(_vm.model.entities[0].rating && _vm.model.entities[0].rating.code === 'OUTPERFORM')?_c('span',{staticClass:"event-indicator"},[_vm._v("O")]):_vm._e(),(_vm.model.entities[0].onLeadersList)?_c('span',{staticClass:"fa fa-trophy trophy"}):_vm._e(),_c('span',{staticClass:"lnk",on:{"click":function($event){return _vm.onClickCompany(_vm.model.entities[0])}}},[_vm._v(_vm._s(_vm.model.entities[0].name))])]),_c('div',[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))])])])]),_c('event-popup',{tag:"component",attrs:{"info":_vm.popupInfo,"opened":_vm.popupOpened}},[_c('div',{staticClass:"list-membership-popup"},[_c('div',[_c('span',{staticClass:"header"},[_vm._v("Title:")]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.model.title))])])]),(_vm.model.rationale)?_c('div',[_c('span',{staticClass:"header"},[_vm._v("Rationale:")]),_c('span',[_vm._v(_vm._s(_vm.model.rationale.name))])]):_vm._e(),_c('div',[_c('div',{staticClass:"header"},[_vm._v("Tags:")]),_vm._l((_vm.model.entities),function(evt,index){return _c('div',{key:index,staticClass:"indent"},[(evt.entityType === 'THEMATIC')?_c('span',{staticClass:"fa fa-stack-exchange"}):_vm._e(),(evt.entityType === 'RMS_USER')?_c('span',{staticClass:"fa fa-user"}):_vm._e(),(evt.entityType === 'REGION' || evt.entityType === 'COUNTRY')?_c('span',{staticClass:"fa fa-globe"}):_vm._e(),(
              evt.entityType !== 'RMS_USER' &&
                evt.entityType !== 'REGION' &&
                evt.entityType !== 'COUNTRY' &&
                evt.entityType !== 'THEMATIC'
            )?_c('span',{staticClass:"fa fa-bar-chart-o"}):_vm._e(),_vm._v(" "+_vm._s(evt.name)+" ")])})],2),(_vm.pointInTime)?_c('div',[_c('div',{staticClass:"header"},[_vm._v("Market Data ("+_vm._s(_vm._f("toDate")(_vm.model.eventDate))+"):")]),(_vm.pointInTime.priceLocal)?_c('div',{staticClass:"indent"},[_vm._v(" Price ("+_vm._s(_vm.pointInTime.tradingCurrency)+") "+_vm._s(_vm.pointInTime.priceLocal.toFixed(2))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"body"},[(_vm.model.body)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.model.body)}}):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }