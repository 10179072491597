import Vue from 'vue'
import searchApi from '../../apis/search'

const state = {
  searchIsActive: false,
  searchResults: [],
  searchRequestComplete: false,
  searchIsTrade: false,
  previousLocation: '',
  selectedItemId: null
}

const mutations = {
  showSearchWindow: (state, payload) => (state.searchIsActive = payload),
  saveSearchResults: (state, payload) => (state.searchResults = payload),
  completeSearchRequest: (state, payload) => (state.searchRequestComplete = payload),
  showTradeSearch: (state, payload) => (state.searchIsTrade = payload),
  setPreviousLocation: (state, payload) => (state.previousLocation = payload),
  setSelectedItemId(state, id) {
    Vue.set(state, 'selectedItemId', id)
  }
}

const actions = {
  async searchIssuers({ commit }, { searchText, limit = null, entityTypes = null }) {
    commit('completeSearchRequest', false)
    const data = {
      match: searchText.trim().toLowerCase()
    }
    if (limit) {
      data.limit = limit
    }
    if (entityTypes) {
      data.entityType = entityTypes
    }

    const searchResults = await searchApi.searchIssuers(data)

    commit('saveSearchResults', searchResults)
    commit('completeSearchRequest', true)
  }
}

const getters = {
  formattedSearchResults: (state) => {
    // Lowercases issuer names since some come back in all caps
    return state.searchResults.slice(0).map((result) => {
      if (!result.error) {
        result.name = result.name.toLowerCase()
      }
      return result
    })  
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
