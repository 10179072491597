export default {
  getNow() {
    return new Date()
  },

  addYearsToNow(years) {
    let date = this.getNow()
    return new Date(date.setFullYear(date.getFullYear() + years))
  },

  addMinutesToNow(minutes) {
    let date = this.getNow()
    return new Date(date.setMinutes(date.getMinutes() + minutes))
  },

  addDaysToNow(days) {
    let date = new Date()
    return new Date(date.setDate(date.getDate() + days))
  },

  toDateTime(date) {
    let [d, t] = new Date(date).toLocaleString('en-US').split(', ')
    return `${d} ${t}`
  },

  daysBetween(date1, date2) {
    //Get 1 day in milliseconds
    const one_day = 1000 * 60 * 60 * 24 // Convert both dates to milliseconds
    const date1_ms = date1.getTime()
    const date2_ms = date2.getTime() // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms // Convert back to days and return
    return Math.round(difference_ms / one_day)
  },

  //when Object.fromEntries was accepted, this function could be replaced with Object.fromEntries
  fromPairs(pairs) {
    var index = -1,
      length = pairs == null ? 0 : pairs.length,
      result = {}

    while (++index < length) {
      var pair = pairs[index]
      result[pair[0]] = pair[1]
    }
    return result
  },

  getEnvironment() {
    return window.location.hostname
  }
}
