import $http from './apiClient'

function keyFinancialMetricsDataTransform(raw) {
  const categories = [
    {
      categoryName: 'Rev',
      fieldPrefix: 'rev',
      precision: 0,
      values: [],
      subCategories: [
        {
          categoryName: 'Rev gr(%)',
          precision: 1,
          isPercentage: true,
          fieldPrefix: 'revgrw',
          values: []
        }
      ]
    },
    { categoryName: 'OPM', precision: 1, fieldPrefix: 'opm', values: [] },
    {
      categoryName: 'EPS',
      fieldPrefix: 'eps',
      precision: 2,
      values: [],
      subCategories: [
        {
          categoryName: 'EPS gr(%)',
          precision: 1,
          isPercentage: true,
          fieldPrefix: 'epsgrw',
          values: []
        }
      ]
    },
    { categoryName: 'CFROIC', precision: 1, fieldPrefix: 'cfroic', values: [] },
    { categoryName: 'ROE', precision: 1, fieldPrefix: 'roe', values: [] },
    { categoryName: 'P/E', precision: 1, fieldPrefix: 'pe', values: [] }
  ]

  function patch(categories) {
    categories.forEach((category) => {
      const prefix = category.fieldPrefix
      category.values = ['Py2', 'Py1', 'Fya', 'Fy1', 'Fy2'].map((f) => {
        return raw.streetEstimates[prefix + f]
      })

      if (category.subCategories) {
        patch(category.subCategories)
      }
    })
  }

  patch(categories)
  return categories
}

export default {
  async getCompanyMarketData(issuerId) {
    const { data } = await $http.get(`/issuers/${issuerId}/companymarketdata/lite`)
    return data
  },
  async getPriceChartData(issuerId, startDate) {
    const url = `/issuers/${issuerId}/securities/market/pricemetrics/lite?startDate=${startDate}`
    const { data } = await $http.get(url)
    return data.map(({ dateMilliseconds, price }) => [dateMilliseconds, price])
  },
  async getKeyFinancialMetrics(issuerId) {
    const { data } = await $http.get('/issuers/' + issuerId + '/keyfinancialmetrics')
    return keyFinancialMetricsDataTransform(data)
  }
}
