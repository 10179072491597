<template>
  <div>
    <CompanyEventList :issuer-id="issuerId" />
    <div v-if="showFilter">
      <f7-fab
        position="right-bottom"
        slot="fixed"
        color="blue"
        :text="selectedType"
        class="company-events"
      >
        <f7-icon ios="f7:add" md="material:add" />
        <f7-icon ios="f7:close" md="material:close" />
        <f7-fab-buttons position="top" class="custom-fab-btns">
          <f7-fab-button
            v-for="(type, index) in eventTypes"
            :key="index"
            fab-close
            @click="filter(type.filter.categoryCode, type.display)"
            >{{ type.display }}</f7-fab-button
          >
        </f7-fab-buttons>
      </f7-fab>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CompanyEventList from '../../components/events/CompanyEventList.vue'

export default {
  props: {
    issuerId: { type: [String, Number], required: true }
  },
  components: {
    CompanyEventList
  },
  mounted() {
    if (this.$ua) {
      this.$ua.trackView(`Company Events Page for Issuer: ${this.issuerId}`)
    }
  },
  data() {
    return {
      companyId: { params: { issuerId: this.issuerId } },
      eventTypes: [
        {
          display: 'ESG Rating Activity',
          filter: { categoryCode: 'ESG_RATING_ACTIVITY', codes: ['ESG_RATING_ACTIVITY'] }
        },
        {
          display: 'Eligibility List Activity',
          filter: { categoryCode: 'ELIG_ACTIVITY', codes: ['ELIGIBILITY_LIST'] }
        },
        {
          display: 'Research Agenda Activity',
          filter: { categoryCode: 'AGENDA_ACTIVITY', codes: ['AGENDA_ACTIVITY'] }
        },
        {
          display: 'Trade Initiations',
          filter: { categoryCode: 'TRADE_INSTRUCTIONS', codes: ['TRADE_INSTRUCTIONS'] }
        },
        { display: 'Commentary', filter: { categoryCode: 'COMMENTARY', codes: ['COMMENTARY'] } },
        {
          display: 'Recommendation Updates',
          filter: { categoryCode: 'RECOMMENDATION_UPDATES', codes: ['RECOMMENDATION_UPDATES'] }
        },
        { display: 'All Activity', filter: { categoryCode: 'ALL' } }
      ],
      selectedFilter: 'All Activity'
    }
  },
  computed: {
    ...mapGetters('events', ['companyCategory', 'showCompanyEventsFilter']),
    selectedType() {
      return this.selectedFilter
    },
    showFilter() {
      return this.showCompanyEventsFilter
    }
  },
  methods: {
    ...mapMutations('events', ['clearEvents']),
    ...mapActions('events', ['getEvents']),
    filter(type, display) {
      this.selectedFilter = display
      let category = { name: 'company' }
      let filter = this.eventTypes.find((x) => x.filter.categoryCode === type).filter
      category.params = { ...filter, ...{ issuerId: this.issuerId } }

      this.getEvents({ page: 1, category, api: 'getEventsForIssuer' })
    }
  },
  beforeDestroy() {
    this.clearEvents({ name: 'company' })
  }
}
</script>

<style lang="scss">
.company-events {
  .fab-text {
    text-transform: unset;
  }

  .fab-buttons.fab-buttons-top.custom-fab-btns {
    align-items: center;

    .fab-close {
      width: 145px;
      font-size: 12px;
    }
  }
}
</style>
