<template>
  <div class="my-elig-list-wrapper">
    <div>
      <div v-if="!valuesAvailable">
        <f7-block>No results match the applied filters, please try again.</f7-block>
      </div>
      <!-- List Wrapper -->
      <div v-if="valuesAvailable">
        <!-- List Header -->
        <f7-list style="background: #efeff4; color: #8e8e93; font-weight: 600; line-height: 14px; font-size: 14px; height: 60px;border-bottom:1px solid black;">
          <f7-list-item-row style="background: #efeff4;height: 60px;">
            <f7-list-item-cell class="item-cell name-cell" style="padding-left: 10px;">
              <div @click="sortList('name')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('name')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('name')" color="blue" />
                <span>COMPANY</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('marketCap')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('marketCap')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('marketCap')" color="blue" />
                <span>MC</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('imIssuerMarketValue')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('imIssuerMarketValue')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('imIssuerMarketValue')" color="blue" />
                <span>IM MV</span>
              </div>  
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('rating')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('rating')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('rating')" color="blue" />
                <span>RTG</span>
              </div>  
            </f7-list-item-cell> 
          </f7-list-item-row>
        </f7-list>
        <!-- List Data -->
        <div :style="listDataStyles">
          <f7-list virtual-list  
                   v-for="(issuers, groupBy) in sortedListItems" 
                   :key="groupBy" style="font-size: 14px;margin-bottom:0;font-weight:600;">
            <f7-list-item :title="`${groupBy} (${issuers.length})`" style="background: #efeff4;" v-if="groupBy !== 'allItems'"/>
            <ul>
              <f7-list-item-row
                v-for="(issuer, index) in issuers"
                :key="index"
                style="border-bottom: 1px solid #efeff4">
                <f7-list-item-cell class="name-data-cell"><a :href="`/company/${issuer.issuerId}/info`">{{ issuer.name || issuer.securityName | lowerCase }}</a></f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.marketCap | marketCap }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.imIssuerMarketValue | marketValue }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.rating | rating }}</f7-list-item-cell>
              </f7-list-item-row>
            </ul>
          </f7-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listMixin from '../../../mixins/listMixin'

export default {
  name: 'MyEligList',
  mixins: [listMixin],
  data() {
    return {
      sortedColumn: 'marketCap',
      sortDirectionIsAscending: false
    }
  }
}
</script>

<style lang="scss">
.my-elig-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ios .list {
    margin: 0 !important;
  }
  .list .item-row {
    height: 45px;
    width: 98vw;
  }
  .item-cell {
    text-align: center;
    max-width: 17vw;
    min-width: 17vw;
    &.name-cell,
    &.name-data-cell {
      text-align: left;
      max-width: 28vw;
      min-width: 28vw;
    }
    &.name-data-cell {
      text-transform: capitalize;
      color: #1a86fe;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 10px;
    }
  }

  .item-cell:first-child {
    margin-left: 1vw;
  }
}
</style>
