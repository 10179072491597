<template>
  <div class="peer-group-container">
    <span class="rec-report-section-title">{{ title }}</span>
    <p class="rec-report-section-sub-title">
      {{ lastModified }}
    </p>
    <br>

    <table class="peer-group-table">
      <thead>
        <tr>
          <th/>
          <th>Rating</th>
          <th>Comp</th>
          <th>1Yr Chg</th>
        </tr>
      </thead>
      <tbody>
        <tr class="peer-group-row" v-for="item in section.listItems.rows" :key="item.symbol">
          <td class="peer-group-col-name"><a href="#" @click="navToCompanyInfo(item.issuerId)">{{ item.name }}</a></td>
          <td class="peer-group-col-data">O</td>
          <td class="peer-group-col-data">{{ item.compositeScore }}</td>
          <td class="peer-group-col-data">{{ item.compositeScore3MoChange }}</td>
        </tr>
      </tbody>
    </table>
    <div class="no-data grid-msg-overlay" v-show="!section || section.listItems.rows.length === 0">
      <div class="msg msg-large">
        <span style="margin-left: 5px;">
          'No peer groups have been defined'
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    lastModified: {
      type: String,
      required: true
    }
  },
  methods: {
    navToCompanyInfo(id) {
      this.$f7router.navigate(`/company/${id}/info`)
    }
  }
}
</script>

<style lang="scss" scoped>
.peer-group-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  .peer-group-table {
    border-spacing: 0px;
    width: 98%;
    tr {
      border-bottom: 1px solid #999;
      height: 25px;
    }
    th {
      font-size: 0.7rem;
      font-weight: bold;
      color: #003366;
      text-align: right;
      border-bottom: 1px solid #444;
    }
    th:first-child {
      text-align: left;
    }
    .peer-group-row {
      font-size: 0.75rem;
      margin-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      width: 100%;
      height: 30px;
    }
    .peer-group-col-name {
      width: 40%;
      text-align: left;
      display: table-cell;
      border-bottom: 1px solid #f5f5f5;
    }
    .peer-group-col-data {
      width: 20%;
      text-align: right;
      display: table-cell;
      border-bottom: 1px solid #f5f5f5 !important;
    }
  }
}
</style>
