<template>
  <div class="agenda-list-wrapper">
    <div>
      <div v-if="!valuesAvailable">
        <f7-block>No results match the applied filters, please try again.</f7-block>
      </div>
      <!-- List Wrapper -->
      <div v-if="valuesAvailable">
        <!-- List Header -->
        <f7-list style="background: #efeff4; color: #8e8e93; font-weight: 600; line-height: 14px; font-size: 14px; height: 60px;border-bottom:1px solid black;">
          <f7-list-item-row style="background: #efeff4;height: 60px;">
            <f7-list-item-cell class="name-cell" style="padding-left: 10px;">
              <div @click="sortList('name')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('name')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('name')" color="blue" />
                <span>COMPANY</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('agendaType')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('agendaType')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('agendaType')" color="blue" />
                <span>TYPE</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('marketCap')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('marketCap')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('marketCap')" color="blue" />
                <span>MC</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('percentageChangeSinceDateAdded')">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('percentageChangeSinceDateAdded')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('percentageChangeSinceDateAdded')" color="blue" />
                <span>% CHG SINCE ADDED</span>
              </div>
            </f7-list-item-cell>
            <f7-list-item-cell class="item-cell">
              <div @click="sortList('analyst')" class="data-cell-header">
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_up" v-if="columnSortedAscending('analyst')" color="blue" />
                <f7-icon size="12" style="margin-right: 2px;" f7="arrow_down" v-if="columnSortedDescending('analyst')" color="blue" />
                <span>ANALYST</span>
              </div>
            </f7-list-item-cell> 
          </f7-list-item-row>
        </f7-list>
        <!-- List Data -->
        <div :style="listDataStyles">
          <f7-list virtual-list 
                   v-for="(issuers, groupBy) in sortedListItems" 
                   :key="groupBy" style="font-size: 14px;margin-bottom:0;font-weight:600;">
            <f7-list-item :title="`${groupBy} (${issuers.length})`" style="background: #efeff4;" v-if="groupBy !== 'allItems'"/>
            <ul>
              <f7-list-item-row
                v-for="(issuer, index) in issuers"
                :key="index"
                style="border-bottom: 1px solid #efeff4">
                <f7-list-item-cell class="name-data-cell"><a :href="`/company/${issuer.issuerId}/info`">{{ issuer.name || issuer.securityName | lowerCase }}</a></f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.agendaType }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.marketCap | marketCap }}</f7-list-item-cell>
                <f7-list-item-cell>{{ issuer.percentageChangeSinceDateAdded | percentage(1) }}</f7-list-item-cell>
                <f7-list-item-cell style="padding-right: 5px;">{{ issuer.analyst || issuer.coverageAnalyst | analystAbbreviation }}</f7-list-item-cell>
              </f7-list-item-row>
            </ul>
          </f7-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import listMixin from '../../../mixins/listMixin'

export default {
  name: 'Agenda',
  mixins: [listMixin],
  data() {
    return {
      sortedColumn: 'marketCap',
      sortDirectionIsAscending: false
    }
  },
  computed: {
    ...mapState('lists', ['groupBy', 'appliedAgendaFilters'])
  },
  methods: {
    ...mapMutations('lists', ['setGroupBy', 'setAgendaFilters'])
  },
  filters: {
    analystAbbreviation(name) {
      // Returns the analyst's full first name and last initial
      if (!name) {
        return ''
      }

      const nameWords = name.split(' ')
      return `${nameWords[0]} ${nameWords[1][0]}.`
    }
  },
  beforeDestroy() {
    // resetting the groupBy and any agenda filters
    // because other list types don't have agendaType
    if (this.groupBy === 'agendaType') {
      this.setGroupBy('sector')
    }

    if (this.appliedAgendaFilters.length) {
      this.setAgendaFilters([])
    }
  }
}
</script>

<style lang="scss">
.agenda-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .data-cell-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ios .list {
    margin: 0 !important;
  }
  .list .item-row {
    height: 45px;
    width: 98vw;
  }
  .item-cell {
    text-align: center;
    max-width: 17vw;
    min-width: 17vw;
    &.name-cell,
    &.name-data-cell {
      text-align: left;
      max-width: 28vw;
      min-width: 28vw;
    }
    &.name-data-cell {
      text-transform: capitalize;
      color: #1a86fe;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 10px;
    }
  }

  .item-cell:first-child {
    margin-left: 1vw;
  }
}
</style>
