import axios from 'axios'
import $http from './apiClient'

// This cancels any pending search requests sent before the user stopped typing
const CancelToken = axios.CancelToken
const source = CancelToken.source()

export default {
  async searchIssuers(requestBody) {
    let searchResults

    try {
      const searchResponse = await $http.post(`/search`, requestBody, {
        cancelToken: source.token
      })
      searchResults = searchResponse.data
    } catch (err) {
      searchResults = [{ message: 'There was an error, please try again.', error: true }]
    }

    return Object.freeze(searchResults)
  },

  async searchByIssuerId(issuerId) {
    try {
      let res = await $http.get(`/search/issuers/${issuerId}`)
      return res
    } catch (e) {
      console.error("An error occurred while searching by issuer id: " + issuerId, e)
			return null
    }
  }
}
