import { mapGetters } from 'vuex'

export default {
  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    onClickCompany: { type: Function, required: true },
    categoryName: { type: String, required: true }
  },
  computed: {
    ...mapGetters('events', ['popupFlag', 'issuerPopupFlag']),
    popupOpened() {
      return this.popupFlag(this.categoryName, this.id) || this.issuerPopupFlag(this.id)
    },
    popupTitle() {
      if (this.model && this.model.type && this.model.type.name) {
        return this.model.type.name
      }

      if (this.model && this.model.subEventType && this.model.subEventType.name) {
        return this.model.subEventType.name
      }
      return this.model.title
    }
  },
  methods: {},
  filters: {}
}
