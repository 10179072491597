<template>
    <div>
        <div style="display:inline-flex">
            <div class="event-symbol">
                <span class="fa fa-lg fa-dollar" />
            </div>
            <div>
                <div>
                    <span v-if="model.entities[0].rating && model.entities[0].rating.code === 'OUTPERFORM'"
                        class="event-indicator">O</span>
                    <span v-if="model.entities[0].onLeadersList" class="fa fa-trophy trophy" />
                    <span @click="onClickCompany(model.entities[0])" class="lnk">{{
                        model.entities[0].name
                    }}</span>
                </div>
                <div>
                    <span class="title">{{ title }}</span>
                </div>
            </div>
        </div>
        <component :info="popupInfo" :opened="popupOpened" :is="'event-popup'">
            <div class="list-membership-popup">
                <div>
                    <span class="header">Title:</span>
                    <span>
                        <b>{{ title }}</b>
                    </span>
                </div>
                <div>
                    <div class="header">Tags:</div>
                    <div class="indent" v-for="(evt, index) in model.entities" :key="index">
                        <span v-if="evt.entityType !== 'RMS_USER' &&
                        evt.entityType !== 'REGION' &&
                        evt.entityType !== 'COUNTRY' &&
                        evt.entityType !== 'THEMATIC'
                        " class="fa fa-bar-chart-o" />
                        {{ evt.name }}
                    </div>
                </div>
                <div v-if="pointInTime">
                    <div class="header">Market Data ({{ model.eventDate | toDate }}):</div>
                    <div class="indent" v-if="pointInTime.priceLocal">
                        Price ({{ pointInTime.tradingCurrency }}) {{ pointInTime.priceLocal.toFixed(2) }}
                    </div>
                </div>
                <div class="body" v-if="priceTargetDataAvailable">
                    <div class="header">Price Target:</div>
                    <f7-block class="company-market-data" style="display:flex;gap: 20px;">
                        <div style="display:flex;gap:2px;" v-if="priceTargetData.currentPrice">
                            <div class="header">Upside:</div>
                            <div>
                                ${{ parseFloat(priceTargetData.currentPrice).toFixed(2) }}
                            </div>
                        </div>
                        <div style="display:flex;gap:2px;" v-if="priceTargetData.currentPriceVsClosePrice">

                            <div class="header">Price Target % Change:</div>
                            <div>
                                {{ parseFloat((priceTargetData.currentPriceVsClosePrice * 100).toFixed(2)) }}%
                            </div>
                        </div>
                        <div style="display:flex;gap:2px;" v-if="priceTargetData.downside">

                            <div class="header">Downside:</div>
                            <div>
                                ${{ parseFloat(priceTargetData.downside).toFixed(2) }}
                            </div>
                        </div>
                    </f7-block>
                    <div v-if="upsideMetricsAvailable">
                        <div class="header" v-if="upsideMetricsAvailable">Upside Metrics</div>
                        <div class="data-table data-table__dense">
                            <table>
                                <thead>
                                    <th>Multiple</th>
                                    <th>Type</th>
                                    <th>Year</th>
                                    <th>Ratio</th>
                                    <th>Metric</th>
                                    <th>Value</th>
                                </thead>
                                <tbody v-for="row in priceTargetData.upsideMetrics">
                                    <tr>
                                        <td>{{ row.multiple }}</td>
                                        <td v-if="row.actual">Actual</td>
                                        <td v-if="row.estimate">Estimate</td>
                                        <td v-if="!row.actual && !row.estimate">&nbsp;</td>
                                        <td>{{ row.metricYear }}</td>
                                        <td><span class="truncate">{{ getSelectedRatio(row.ratioId) }}</span></td>
                                        <td><span class="truncate">{{ getSelectedMetric(row.metricId) }}</span></td>
                                        <td>{{ row.ratioValue }}</td>
                                    </tr>
                                    <tr v-if="row.comment">
                                        <td class="comment" colspan="6" v-html="row.comment"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-if="downsideMetricsAvailable">
                        <div class="header" v-if="downsideMetricsAvailable">Downside Metrics</div>
                        <div class="data-table data-table__dense">
                            <table>
                                <thead>
                                    <th>Multiple</th>
                                    <th>Type</th>
                                    <th>Year</th>
                                    <th>Ratio</th>
                                    <th>Metric</th>
                                    <th>Value</th>
                                </thead>
                                <tbody v-for="row in priceTargetData.upsideMetrics">
                                    <tr>
                                        <td>{{ row.multiple }}</td>
                                        <td v-if="row.actual">Actual</td>
                                        <td v-if="row.estimate">Estimate</td>
                                        <td v-if="!row.actual && !row.estimate">&nbsp;</td>
                                        <td>{{ row.metricYear }}</td>
                                        <td><span class="truncate">{{ getSelectedRatio(row.ratioId) }}</span></td>
                                        <td><span class="truncate">{{ getSelectedMetric(row.metricId) }}</span></td>
                                        <td>{{ row.ratioValue }}</td>
                                    </tr>
                                    <tr v-if="row.comment">
                                        <td class="comment" colspan="6" v-html="row.comment"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="no-data-for-table" v-if="!priceTargetDataAvailable">
                                <i class="f7-icons info-icon">info_fill</i>There are no Price Target metrics
                                available for this
                                security.
                            </p>
                        </div>
                    </div>

                    <f7-block v-if="!priceTargetDataAvailable">
                        <div class="data-table data-table__dense">
                            <p class="no-data-for-table">
                                <i class="f7-icons info-icon">info_fill</i>There are no Price Target metrics
                                available for this
                                security.
                            </p>
                        </div>
                    </f7-block>
                    </f7-block>
                </div>
            </div>
        </component>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import EventPopup from './EventPopup.vue'
import eventPopupMixin from '../../mixins/eventPopupMixin'

export default {
    name: 'PriceTarget',
    mixins: [eventPopupMixin],
    components: {
        EventPopup
    },
    props: {
        model: { type: Object, required: true },
    },
    computed: {
        ...mapState('events', ['priceTargetData', 'priceTargetRatioKeys', 'priceTargetMetricKeys']),
        ...mapGetters('events', ['pointInTime']),
        popupInfo() {
            return {
                title: this.popupTitle,
                fullName: this.model.createdBy.fullName,
                date: this.model.eventDate,
                endorser: this.model.hasOwnProperty('endorser') ? this.model.endorser.fullName : 'pending',
                sponsor: this.model.hasOwnProperty('sponsor') ? this.model.sponsor.fullName : 'pending'
            }
        },
        title() {
            if (!this.model) {
                return ''
            }

            if (this.model.subEventType && this.model.subEventType.name) {
                return this.model.subEventType.name
            }

            return this.model.title || ''


        },
        issuer() {
            if (!this.model.entities || !this.model.entities.length) {
                return {}
            }

            return this.model.entities.find(({ entityType }) => entityType === 'ISSUER') || {}
        },
        priceTargetDataAvailable() {
            if (!Object.keys(this.priceTargetData).length) {
                return false
            }
            return true
        },
        upsideMetricsAvailable() {
            if (!this.priceTargetData.upsideMetrics || !this.priceTargetData.upsideMetrics.length) {
                return false
            }
            return true
        },
        downsideMetricsAvailable() {
            if (!this.priceTargetData.downsideMetrics || !this.priceTargetData.downsideMetrics.length) {
                return false
            }
            return true
        },

    },
    methods: {
        getSelectedRatio(ratioId) {
            return this.priceTargetRatioKeys.find(ratio => ratio.id === ratioId).description
        },
        getSelectedMetric(metricId) {
            return this.priceTargetMetricKeys.find(metric => metric.id === metricId).description
        },
    }
}
</script>

<style lang="scss" scoped>
.list-membership-popup {
    .body {
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .indent {
        padding-left: 15px;
    }

    .header {
        font-weight: bold;
    }
}

.list-membership-popup>div {
    margin-bottom: 15px;
}
</style>