import companyInfoApi from '../../apis/companyInfo'
import companyMarketDataApi from '../../apis/companyMarketData'
import companyRecReportApi from '../../apis/companyRecReport'

const state = {
  companyInfo: {
    info: {},
    marketData: {},
    status: {}
  },
  companyRecReport: null,
  recReportTabActive: false,
  recReportPdfURL: '',
  recReportPdfBlob: null,
  showRecReportPDF: false,
  recReportRequestComplete: true,
  companyOverviewData: {}
}

const mutations = {
  setCompanyInfo: (state, companyInfo) => {
    state.companyInfo = companyInfo
  },
  setCompanyRecReport: (state, companyRecReport) => {
    state.companyRecReport = companyRecReport
  },
  setCompanyOverviewData: (state, companyOverviewData) =>
    (state.companyOverviewData = companyOverviewData),
  showRecReportFABButton: (state, payload) => (state.recReportTabActive = payload),
  setRecReportPdfData: (state, { url = '', blob = null }) => {
    state.recReportPdfURL = url
    state.recReportPdfBlob = blob
  },
  setRecReportRequestStatus: (state, payload) => (state.recReportRequestComplete = payload),
  toggleRecReportPDFView: (state, payload) => {
    payload != undefined
      ? (state.showRecReportPDF = payload)
      : (state.showRecReportPDF = !state.showRecReportPDF)
  }
}

const actions = {
  async setIssuerCompanyInfo({ commit }, issuerId) {
    const info = await companyInfoApi.getIssuerCompanyPage(issuerId)
    commit('setCompanyInfo', info)
  },

  async loadIssuerOverviewData({ commit }, issuerId) {
    const [marketData, keyFinancialMetrics] = await Promise.all([
      companyMarketDataApi.getCompanyMarketData(issuerId),
      companyMarketDataApi.getKeyFinancialMetrics(issuerId)
    ])
    const { quantScores, holdings, esgData } = marketData
    commit('setCompanyOverviewData', { quantScores, holdings, esgData, keyFinancialMetrics })
  },

  async getCompanyRecReport({ commit }, issuerId) {
    companyRecReportApi.getCompanyRecReport(issuerId).then(
      (recReport) => {
        commit('setCompanyRecReport', recReport.data)
      },
      (error) => {
        commit('setCompanyRecReport', { sections: [] })
        console.error('Error while getting Rec Report', error)
      }
    )
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
