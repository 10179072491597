import Vue from 'vue'
import get from 'lodash/get'
import eventsApi from '../../apis/events'
import util from '../../util'
import uniqBy from 'lodash/uniqBy'

const state = {
  pageSize: 15,
  yearsBack: -2,
  lastPolledDate: null,
  activeEventsTab: 'all',
  events: [
    {
      name: 'all',
      id: 0,
      display: 'All Activity',
      params: { categoryCode: 'ALL' },
      endOfData: false,
      data: []
    },
    {
      name: 'my-portfolios',
      id: 1,
      display: 'My Portfolios',
      roles: ['pm', 'pm_analyst'],
      params: {
        categoryCode: 'ALL',
        searchList: { code: 'MY_PORTFOLIOS', listTypeId: 3, id: 1000 }
      },
      endOfData: false,
      data: []
    },
    {
      name: 'trading-desk',
      id: 2,
      display: 'Trading Desk',
      params: {
        categoryCode: 'COMMENTARY',
        codes: ['TRADING_DESK_COMMENTARY']
      },
      endOfData: false,
      data: []
    },
    {
      name: 'rec-updates',
      id: 3,
      display: 'Recommendations',
      params: {
        categoryCode: 'RECOMMENDATION_UPDATES',
        codes: ['RECOMMENDATION_UPDATES']
      },
      endOfData: false,
      data: []
    },
    {
      name: 'trade-init',
      id: 4,
      display: 'Trade Initiate',
      params: {
        categoryCode: 'TRADE_INSTRUCTIONS',
        codes: ['TRADE_INSTRUCTIONS']
      },
      endOfData: false,
      data: []
    },
    {
      name: 'quant',
      id: 5,
      display: 'Quant',
      params: {
        categoryCode: 'COMMENTARY',
        codes: ['QUANT_TEAM_COMMENTARY', 'ECON', 'STRATEGY_COMMENTARY']
      },
      endOfData: false,
      data: []
    },
    {
      name: 'company',
      endOfData: false,
      data: []
    }
  ],
  issuerEvents: {
    name: 'company',
    endOfData: false,
    data: []
  },
  showCompanyFilter: false,
  searchRequestComplete: false,
  pointInTime: {},
  priceTargetData: {},
  priceTargetMetricKeys: [],
  priceTargetRatioKeys: [],
}

const mutations = {
  appendEvents(state, { name, page, data }) {
    // no support for sustain thesis event types yet
    data = data.filter((x) => x.eventType != 'SUSTAIN_THESIS_REPORT')
    const eventsByCategory = state.events.find((x) => x.name === name)
    if (eventsByCategory) {
      let events = page === 1 ? data : [...eventsByCategory.data, ...data]
      events = uniqBy(events, 'objectId')
      Vue.set(eventsByCategory, 'data', events)
    }
  },
  appendIssuerEvents(state, { page, data }) {
    // no support for sustain thesis event types yet
    data = data.filter((x) => x.eventType != 'SUSTAIN_THESIS_REPORT')
    const issuerEvents = state.issuerEvents
    if (issuerEvents) {
      let events = page === 1 ? data : [...issuerEvents.data, ...data]
      events = uniqBy(events, 'objectId')
      Vue.set(issuerEvents, 'data', events)
    }
  },
  setActiveEventsTab: (state, payload) => (state.activeEventsTab = payload),
  updateLastPollDate: (state, payload) => (state.lastPolledDate = payload),
  completeSearchRequest: (state, payload) => (state.searchRequestComplete = payload),
  setPopupFlag(state, { name, eventId }) {
    const eventsByCategory = state.events.find((x) => x.name === name)
    const event = eventsByCategory.data.find((x) => x.objectId === eventId)
    Vue.set(event, 'popup', event.popup ? false : true)
  },
  setIssuerPopupFlag(state, { eventId }) {
    const eventIndex = state.issuerEvents.data.findIndex((x) => x.objectId === eventId)
    Vue.set(state.issuerEvents.data[eventIndex], 'popup', event.popup ? false : true)
  },
  clearPopupFlag(state) {
    state.events.forEach((x) => {
      const event = x.data.find(({ popup }) => popup)
      if (event) {
        event.popup = false
      }
    })

    state.issuerEvents.data.forEach((event) => {
      if (event.popup) {
        event.popup = false
      }
    })
  },
  setPointInTime: (state, payload) => (state.pointInTime = payload),
  clearEvents(state, { name }) {
    const events = state.events.find((x) => x.name === name)
    if (events && events.data) {
      events.data.length = 0
    }
  },
  clearIssuerEvents(state) {
    state.issuerEvents.data.length = 0
    state.issuerEvents.endOfData = false
  },
  setEndOfDataFlag(state, { name, endOfData }) {
    const eventsByCategory = state.events.find((x) => x.name === name)
    if (eventsByCategory) {
      eventsByCategory.endOfData = endOfData
    }
  },
  setEndOfIssuerDataFlag(state, { endOfData }) {
    const issuerEvents = state.issuerEvents
    if (issuerEvents) {
      issuerEvents.endOfData = endOfData
    }
  },
  toggleCompanyEventsFilter(state) {
    Vue.set(state, 'showCompanyFilter', !state.showCompanyFilter)
  },
  setPriceTargetData(state, data) {
    state.priceTargetData = data
  },
  setPriceTargetMetricKeys(state, data) {
    state.priceTargetMetricKeys = data
  },
  setPriceTargetRatioKeys(state, data) {
    state.priceTargetRatioKeys = data
  }
}

const actions = {
  async getEvents({ state, commit }, { page = 1, category = null, api = 'getEvents' }) {
    commit('completeSearchRequest', false)
    const now = util.getNow()
    commit('updateLastPollDate', now)

    let body = {
      page,
      pageSize: state.pageSize,
      fromDate: util.addYearsToNow(state.yearsBack),
      toDate: now,
      lastPolled: state.lastPolledDate
    }

    body = { ...body, ...category.params }
    const data = await eventsApi[api](body)

    api === 'getEventsForIssuer'
      ? commit('setEndOfIssuerDataFlag', {
          name: 'company',
          endOfData: data.length / state.pageSize !== 1
        })
      : commit('setEndOfDataFlag', {
          name: category.name,
          endOfData: data.length / state.pageSize !== 1
        })

    api === 'getEventsForIssuer'
      ? commit('appendIssuerEvents', { name: 'company', page, data })
      : commit('appendEvents', { name: category.name, page, data })

    commit('completeSearchRequest', true)
  },
  async getPointInTime({ commit }, eventId) {
    const pointInTime = await eventsApi.getPointInTime(eventId)
    commit('setPointInTime', pointInTime)
  },
  async getPriceTarget({ commit }, issuerId) {
    const metricKeys = await eventsApi.getPriceTargetAnalystMetrics()
    const ratioKeys = await eventsApi.getPriceTargetAnalystRatioKeys()
    const priceTargetData = await eventsApi.getPriceTargetData(issuerId)
    commit('setPriceTargetMetricKeys', metricKeys)
    commit('setPriceTargetRatioKeys', ratioKeys)
    commit('setPriceTargetData', priceTargetData)
  }
}

const getters = {
  eventsByCategory: (state) => (name) => {
    const events = state.events.find((x) => x.name === name)
    return events ? events.data : null
  },
  event: (state, getters) => (name, id) => {
    const events = getters.eventsByCategory(name)
    return events.find((x) => x.objectId === id)
  },
  eventsByActiveTab(state) {
    const events = state.events.find((x) => x.name === state.activeEventsTab)
    return events ? events.data : null
  },
  popupFlag: (state, getters) => (name, eventId) => {
    const event = getters.event(name, eventId)
    if (event && event.popup) {
      return event.popup
    }
  },
  issuerPopupFlag: (state) => (eventId) => {
    const event = state.issuerEvents.data.find((x) => x.objectId === eventId)
    if (event && event.popup) {
      return event.popup
    }
  },
  pointInTime: (state) => {
    return state.pointInTime
  },
  endOfData: (state) => (name) => {
    const events = state.events.find((x) => x.name === name)
    return events.endOfData
  },
  categoryAllLoaded: (state) => {
    const events = state.events[0]
    return events.data.length > 0
  },
  mainCategories: (state) => {
    return state.events.slice(0).filter((x) => {
      return x.name !== 'company'
    })
  },
  showCompanyEventsFilter: (state) => {
    return state.showCompanyFilter
  },
  companyCategory: (state) => {
    return state.events.find((x) => x.name === 'company')
  },
  showMyPortfolios: (state, getters, rootState, rootGetters) => {
    const currentUser = rootGetters['user/currentOrOnBehalfOfUser']
    const userRole = get(currentUser, 'userRole.code', '').toLowerCase()

    return userRole === 'pm' || userRole === 'pm_analyst'
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
