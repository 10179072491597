<template>
  <f7-popup>
    <f7-page class="user-page">
      <f7-navbar back-link>
        <f7-nav-title>{{ currentOrOnBehalfOfUser.fullName }}</f7-nav-title>
        <f7-nav-right>
          <f7-link v-if="isOnBehalfOfPending" @click="commitChange" popup-close>Set&nbsp;&nbsp;</f7-link>
          <f7-link v-if="!isOnBehalfOfPending && onBehalfOfUser" @click="reset" popup-close>Reset&nbsp;&nbsp;</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list style="margin-bottom:0">
        <f7-list-item title="On Behalf Of Users" smart-select :smart-select-params="{openIn:'sheet', routableModals:false}">
          <select name="on-behalf-of-user" @change="changeUser">
            <option/>
            <option v-for="user in sortedOnBehalves" :key="user.fullName" :value="user.fullName" :selected="user.fullName === selectedUser.fullName">{{ user.fullName }}</option>
          </select>
        </f7-list-item>
      </f7-list>

      <f7-actions>
        <f7-actions-group>
          <f7-actions-button bold>Set</f7-actions-button>
        </f7-actions-group>
        <f7-actions-group>
          <f7-actions-button color="red">Cancel</f7-actions-button>
        </f7-actions-group>
      </f7-actions>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      selectedUser: null,
      isOnBehalfOfPending: false,
      onBehalfOfUserChanged: false
    }
  },
  computed: {
    ...mapState(['navTitle']),
    ...mapState('user', ['currentUser', 'onBehalfOfUser']),
    ...mapGetters('user', ['currentOrOnBehalfOfUser']),
    sortedOnBehalves() {
      return this.currentUser.onBeHalfOfUsers
        .slice()
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    },
    listViewActive() {
      return this.navTitle.toLowerCase().indexOf('list') !== -1
    }
  },
  methods: {
    ...mapActions('user', ['setOnBehalfOfUser']),
    changeUser(event) {
      const name = event.target.value
      const user = this.currentUser.onBeHalfOfUsers.find((x) => String(x.fullName) === name)
      if (user && user.principal !== this.currentUser.principal) {
        this.selectedUser = user
        this.isOnBehalfOfPending = true
      } else {
        this.isOnBehalfOfPending = false
      }
    },
    async commitChange() {
      if (this.currentUser.principal === this.selectedUser.principal) {
        this.setOnBehalfOfUser({ onBehalfOfUser: null })
      } else {
        this.setOnBehalfOfUser({ onBehalfOfUser: this.selectedUser })
      }
      this.onBehalfOfUserChanged = true
    },
    reset() {
      this.setOnBehalfOfUser({ onBehalfOfUser: null })
      this.isOnBehalfOfPending = false
    }
  },
  created() {
    var self = this
    try {
      const user = self.currentUser.onBeHalfOfUsers.find(
        (x) => String(x.fullName) === self.currentOrOnBehalfOfUser.fullName
      )
      if (user) {
        this.selectedUser = user
      } else {
        this.selectedUser = { fullName: '' } //this.currentUser.onBeHalfOfUsers[0]
      }
    } catch (e) {
      const text = 'Data fetch failed. Incident has been logged.'
      this.$ShowErrorBanner(text)
    }
  }
}
</script>

<style lang="scss" scoped>
.h-center {
  display: flex;
  justify-content: center;
}
.v-center {
  display: flex;
  align-items: center;
}
</style>
