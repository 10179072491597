import tradeApi from '../../apis/trade'

export default {
  namespaced: true,
  state: {
    issuerData: null,
    securities: null,
    strategies: null,
    issuerId: null
  },
  mutations: {
    SET_ISSUER_ID(state, issuerId) {
      state.issuerId = issuerId
    },
    SET_TRADE_ISSUER(state, { issuerData, securities, holdingStatus }) {
      holdingStatus.forEach((x) => {
        x.toBps = undefined
        x.priceLimit = undefined
        x.rationales = []
        x.instructions = null
        x.rationales = ['Fundamentals', 'Valuation', 'Strategic'].map((v) => ({
          label: v,
          value: v,
          checked: false
        }))
      })
      state.issuerData = issuerData
      state.securities = securities
      state.strategies = holdingStatus
    }
  },
  actions: {
    async loadIssuer({ commit }, id) {
      const issuerInfo = await tradeApi.getIssuerHeldStrategies(id)
      commit('SET_TRADE_ISSUER', issuerInfo)
    }
  }
}
